<template>
  <div class="page mb-4 pb-4">
    <app-header
      child-header-class="container"
      :child-header-navigation="userSubNavigation"
      is-child-header-left-actions
    >
      <filter-perpage
        @searchFor="setSearchFor"
        @perPage="setPerPage"
      />
      <dropdown
        class-name="filter"
        :filter-on="the_filter.only_active_users"
      >
        <template slot="icon">
          <i
            class="fa fa-filter button button--shadow button--type-icon size-sm d-flex align-items-center"
            style="cursor: pointer;color: rgb(114, 125, 146);"
          />
        </template>
        <template slot="body">
          <div class="d-block p-2">
            <div
              class="d-block"
            >
              <label
                for="filter-only_active_users"
                class="checkbox"
              >
                <input
                  type="checkbox"
                  id="filter-only_active_users"
                  :true-value="true"
                  :false-value="false"
                  v-model="the_filter.only_active_users"
                >
                {{ $t('only_active_users') }}
              </label>
            </div>
          </div>
        </template>
      </dropdown>
    </app-header>
    <nav class="navigation navigation--ternary">
      <div class="container">
        <ul
          class="navigation__list nav"
          id="myRoles"
          role="tablist"
        >
          <template v-for="r in roleTypes">
            <li
              class="navigation__item"
              :key="`${r.value}-${r.name}`"
            >
              <button
                class="navigation__link"
                :class="{ 'active': role === Number(r.value)}"
                @click="role=r.value"
              >
                {{ r.name }}
              </button>
            </li>
          </template>
        </ul>
      </div>
    </nav>
    <div class="container">
      <div class="mb-4 navigation text-capitalize d-flex items-center">
        <router-link
          :to="{
            name: 'ReportJourneysV2',
          }"
        >
          <icon-container
            :width="40"
            :height="18"
            name="Go Back"
            color="#727d92"
          >
            <icon-goback />
          </icon-container>
        </router-link> {{ journeyName }}
      </div>
      <div
        v-if="tableIsLoading"
        class="lds-dual-ring"
      />
      <div class="table-responsive">
        <vue-table
          :class="{ 'table-fade': tableIsLoading }"
          ref="myvuetable"
          :api-url="apiUrl"
          :http-fetch="myFetch"
          :api-mode="true"
          @vuetable:loading="tableIsLoading = true"
          @vuetable:loaded="tableIsLoading = false"
          @vuetable:load-error="vuetableLoadError"
          pagination-path="meta"
          http-method="get"
          :append-params="moreParams"
          :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
          :fields="fields"
          :no-data-template="$t('no_data_info')"
          :css="css.table"
          :per-page="perPage"
          :query-params="makeQueryParams"
          @vuetable:pagination-data="onPaginationData"
        >
          <template
            slot="first_name"
            slot-scope="props"
          >
            {{ props.rowData.first_name }}
          </template>
          <template
            slot="last_name"
            slot-scope="props"
          >
            {{ props.rowData.last_name }}
          </template>

          <template
            slot="assigned_user-slot"
            slot-scope="props"
          >
            <span>{{ props.rowData.assigned_user?.first_name }}
              {{ props.rowData.assigned_user?.last_name }}</span>
            <span v-if="props.rowData?.assigned_user?.employee_no">
              {{ $t('employee_no_hypen', { employee_no: props.rowData.assigned_user?.employee_no }) }}</span>
          </template>
          <template
            slot="activation_date-slot"
            slot-scope="props"
          >
            {{ formatDate(props.rowData.activation_date, false) }}
          </template>
          <template
            slot="first_viewed_at-slot"
            slot-scope="props"
          >
            {{ formatDate(props.rowData.first_viewed_at) }}
          </template>
          <template
            slot="duration"
            slot-scope="props"
          >
            {{ $helpers.convertToTimeString(props.rowData.duration) }}
          </template>
          <template
            slot="last_viewed_at-slot"
            slot-scope="props"
          >
            {{ formatDate(props.rowData.last_viewed_at) }}
          </template>
          <template
            slot="completed_at-slot"
            slot-scope="props"
          >
            {{ formatDate(props.rowData.completed_at) }}
          </template>
          <template
            slot="actions"
            slot-scope="props"
          >
            <button
              class="ui button"
              @click="onAction('user-detail', props.rowData)"
            >
              <icon-container
                :width="20"
                :height="20"
                name="infocircle"
                view-box="0 0 512 512"
                color="#727d92"
                :is-icon-class="false"
              >
                <icon-infocircle />
              </icon-container>
            </button>
          </template>
        </vue-table>
        <div class="pagination__info">
          <vuetable-pagination-info
            :class="{ 'table-fade': tableIsLoading }"
            ref="paginationInfo"
            :info-template="$t('pagination_info')"
            :no-data-template="$t('no_data_info')"
          />
          <div class="pagination__items">
            <vuetable-pagination
              ref="pagination"
              :css="css.pagination"
              @vuetable-pagination:change-page="onChangePage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';
import { directive as onClickaway } from 'vue-clickaway';
import { mapState } from 'vuex';
import IconContainer from '@/components/elements/Icon.vue';
import Dropdown from '@/components/elements/Dropdown.vue';
import IconGoBack from '@/components/icons/GoBack.vue';
import IconInfoCircle from '@/components/icons/InfoCircle.vue';

import AppHeader from '@/components/AppHeader.vue';
import { API_REPORT_JOURNEY_V2_USERS, API_SERVER_TIME_FORMAT } from '@/helpers/config';
import FilterPerpage from '@/components/FilterPerpage.vue';
import myTableMixin from '@/helpers/myTableMixin.vue';
import VuetablePagination from '../../components/CustomVuetable/VuetablePagination.vue';

export default {
  name: 'ReportJourneyV2Users',
  mixins: [myTableMixin],
  data() {
    return {
      moreParams: {
        // customer_id: this.$store.state.auth.customerId,
        journey2_id: this.$route.params.journeyId,
        role: 3,
      },
      the_filter: {
        only_active_users: false,
      },
      journeyName: this.$route.query.journey,
      role: 3,
      roleTypes: [
        { name: 'USER', value: 3 },
        { name: 'HRBP', value: 0 },
        { name: 'BUDDY', value: 1 },
        { name: 'MANAGER', value: 2 },
        { name: 'OTHER', value: 4 },
      ],
    };
  },

  components: {
    'icon-container': IconContainer,
    'icon-infocircle': IconInfoCircle,
    'icon-goback': IconGoBack,
    'app-header': AppHeader,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    Dropdown,
    'filter-perpage': FilterPerpage,
  },

  directives: {
    'on-clickaway': onClickaway,
  },

  watch: {
    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'ReportJourneys') {
        setTimeout(() => {
          this.$refs.myvuetable.refresh();
          this.refreshPage = false;
          this.pageToRefresh = null;
        }, 500);
      }
    },
    the_filter: {
      handler(filter) {
        if (filter.only_active_users) {
          this.$set(this.moreParams, 'only_active_users', 1);
        } else {
          this.$delete(this.moreParams, 'only_active_users');
        }
      },
      immediate: true,
      deep: true,
    },
    role: {
      handler(role) {
        this.$set(this.moreParams, 'role', Number(role));
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapState([
      'modal',
    ]),
    userLang() {
      if (this.$store.state.auth.userInfo) {
        return this.$store.state.auth.userInfo.lang;
      }
      return 'en';
    },
    apiUrl() {
      if (API_REPORT_JOURNEY_V2_USERS) {
        return `${API_REPORT_JOURNEY_V2_USERS}`;
      }
      return null;
    },

    customerId() {
      return this.$store.state.auth.customerId;
    },

    isVibonsAdmin() {
      if (this.$store.state && this.$store.state.auth) {
        return this.$store.state.auth.isVibonsAdmin;
      }
      return false;
    },

    userSubNavigation() {
      return [
        {
          text: this.$t('users'),
          url: `/dashboard/journeys_v2/users/${this.$route.params.journeyId}`,
          query: { journey: this.journeyName },
        },
        {
          text: this.$t('contents_subtitle'),
          url: `/dashboard/journeys_v2/contents/${this.$route.params.journeyId}`,
          query: { journey: this.journeyName },
        },
      ];
    },

    fields() {
      return [
        {
          name: 'first_name',
          title: this.$t('first_name'),
          sortField: 'u.first_name',
          width: '170px',
        },
        {
          name: 'last_name',
          title: this.$t('last_name'),
          sortField: 'u.last_name',
          width: '170px',
        },
        {
          name: 'employee_no',
          title: this.$t('employee_no_'),
          sortField: 'u.employee_no',
          width: '50px',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'assigned_user-slot',
          title: this.$t('assigned_user'),
          width: '100px',
        },
        {
          name: 'active_items_count',
          title: this.$t('active_item_count'),
          sortField: 'active_items_count',
          width: '20px',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'completed_items_count',
          title: this.$t('completed_items_count'),
          sortField: 'completed_items_count',
          width: '20px',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'assigned_items_count',
          title: this.$t('assigned_item_count'),
          sortField: 'assigned_items_count',
          width: '20px',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'duration',
          title: this.$t('duration'),
          sortField: 'duration',
          width: '20px',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'activation_date-slot',
          title: this.$t('assignment_date'),
          sortField: 'activation_date',
          width: '100px',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'first_viewed_at-slot',
          title: this.$t('first_viewed_at'),
          sortField: 'first_viewed_at',
          width: '100px',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'last_viewed_at-slot',
          title: this.$t('last_viewed_at'),
          sortField: 'last_viewed_at',
          width: '100px',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'completed_at-slot',
          title: this.$t('completed_at'),
          width: '100px',
          sortField: 'completed_at',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'actions',
          title: this.$t('actions'),
          width: '90px',
          titleClass: 'text-center',
          dataClass: 'text-center',
        }];
    },
  },
  methods: {
    formatDate(value, showTime = true) {
      if (!value) return '-';
      let fmt = null;
      let date = null;
      if (value) {
        if (this.userLang === 'en') {
          fmt = showTime ? 'MM.DD.YYYY HH:mm' : 'MM.DD.YYYY';
        }
        if (this.userLang === 'tr') {
          fmt = showTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY';
        }
        date = moment(value, API_SERVER_TIME_FORMAT).format(fmt);
      }
      return date;
    },
    makeQueryParams(sortOrder, currentPage, perPage) {
      // console.log('JSON:', JSON.stringify(sortOrder, null, 2));
      if (sortOrder.length > 0) {
        let sortString = sortOrder[0].sortField;
        if (sortString.endsWith('-slot')) {
          sortString = sortString.replace('-slot', '');
        }
        return {
          sort: sortString,
          order: sortOrder[0].direction,
          page: currentPage,
          per_page: perPage,
        };
      }
      return {
        sort: 'last_viewed_at',
        order: 'desc',
        page: currentPage ?? 1,
        per_page: this.perPage,
      };
    },
    onAction(action, data) {
      const userId = data.user_id;
      const assignedUserId = this.role === 3 ? null : data.assigned_user.user_id;
      if (action === 'user-detail') {
        // modalReportJourneyV2UserContents
        if (userId) {
          console.log('User action to do:', assignedUserId);
          const obj = {
            modalType: 'modalReportJourneyV2UserContents',
            user_id: userId,
            user_name: `${data.first_name} ${data.last_name}`,
            assigned_user_id: assignedUserId,
            journey2_id: this.$route.params.journeyId,
          };
          this.$helpers.toggleModal(obj);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .filter-bp__btn {
  border: none;
  padding: 0;
  cursor: pointer;
  }
::v-deep .swicth-checkbox {
  min-width: 200px;
}
::v-deep .swicth-checkbox label {
    color: #727d92;
}
@media (min-width: 1200px) {
  ::v-deep .container {
    max-width: 90%;
    min-width: 1024px;
  }
}
</style>
