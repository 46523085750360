var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal", class: { "is-loading": _vm.formSending } },
      [
        _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("header", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.reportModalTitle
                        ? _vm.reportModalTitle
                        : _vm.$t("create_journey_report")
                    ) +
                    " "
                ),
              ]),
              _c("button", {
                staticClass: "close",
                attrs: { type: "button" },
                domProps: { innerHTML: _vm._s("&times;") },
                on: { click: _vm.closeModal },
              }),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "tab-content" }, [
                _c("div", { staticClass: "tab-pane" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "report_name" },
                      },
                      [_vm._v(_vm._s(_vm.$t("report_name")))]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.reportName,
                          expression: "reportName",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|min:2|max:1000",
                          expression: "'required|min:2|max:1000'",
                        },
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-danger": _vm.errors.has("report.report_name"),
                      },
                      attrs: {
                        type: "text",
                        id: "report_name",
                        placeholder: _vm.$t("add_name_placeholder"),
                        "data-vv-scope": "report",
                        "data-vv-name": "report_name",
                        "data-vv-as": _vm.$t("report_name"),
                      },
                      domProps: { value: _vm.reportName },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.reportName = $event.target.value
                        },
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("report.report_name"),
                            expression: "errors.has('report.report_name')",
                          },
                        ],
                        staticClass: "help is-danger",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("report.report_name")))]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "form mt-2" },
                    [
                      _c(
                        "collapse-transition",
                        { attrs: { duration: 500, dimension: "height" } },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showRestofSettings,
                                  expression: "showRestofSettings",
                                },
                              ],
                              staticClass: "form-group--light",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "form-group w-100" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "form-label",
                                      attrs: { for: "selectuserData" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("user_data_selection"))
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "multi-select",
                                    {
                                      staticClass:
                                        "selectbox selectbox--secondary",
                                      attrs: {
                                        id: "selectuserData",
                                        name: "selectuserData",
                                        "track-by": "value",
                                        label: "name",
                                        "select-label": "",
                                        placeholder: _vm.$t("select_user_data"),
                                        "allow-empty": true,
                                        multiple: true,
                                        max: 3,
                                        searchable: false,
                                        "close-on-select": false,
                                        "internal-search": false,
                                        "show-labels": true,
                                        taggable: true,
                                        "clear-on-select": false,
                                        options: _vm.userData,
                                      },
                                      model: {
                                        value: _vm.selectedUserData,
                                        callback: function ($$v) {
                                          _vm.selectedUserData = $$v
                                        },
                                        expression: "selectedUserData",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "maxElements" }, [
                                        _c(
                                          "span",
                                          { staticClass: "option__desc" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "option__title" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("max_3_options")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-label",
                                    attrs: { for: "demoType" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("user_status")))]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "check-button mr-4",
                                        class: {
                                          active: _vm.userStatus === "active",
                                        },
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.userStatus,
                                              expression: "userStatus",
                                            },
                                          ],
                                          attrs: {
                                            type: "radio",
                                            value: "active",
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.userStatus,
                                              "active"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              _vm.userStatus = "active"
                                            },
                                          },
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("active_users")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "check-button mr-4",
                                        class: {
                                          active: _vm.userStatus === "inactive",
                                        },
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.userStatus,
                                              expression: "userStatus",
                                            },
                                          ],
                                          attrs: {
                                            type: "radio",
                                            value: "inactive",
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.userStatus,
                                              "inactive"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              _vm.userStatus = "inactive"
                                            },
                                          },
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("inactive_users")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "check-button",
                                        class: {
                                          active: _vm.userStatus === "all",
                                        },
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.userStatus,
                                              expression: "userStatus",
                                            },
                                          ],
                                          attrs: {
                                            type: "radio",
                                            value: "all",
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.userStatus,
                                              "all"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              _vm.userStatus = "all"
                                            },
                                          },
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("all_users")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "form-group d-flex" }, [
                                _vm.reportDownloadHasStartDate
                                  ? _c(
                                      "label",
                                      { staticClass: "form-label" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("report_begin_time")) +
                                            " "
                                        ),
                                        _c("date-picker", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value:
                                                "required|max1year:endDate",
                                              expression:
                                                "'required|max1year:endDate'",
                                            },
                                          ],
                                          ref: "beginDate",
                                          class: {
                                            "is-danger":
                                              _vm.errors.has(
                                                "report.beginDate"
                                              ),
                                          },
                                          attrs: {
                                            type: "date",
                                            "value-type": "YYYY-MM-DD 00:00:00",
                                            "data-vv-scope": "report",
                                            "data-vv-name": "beginDate",
                                            "data-vv-as":
                                              _vm.$t("report_begin_time"),
                                            "first-day-of-week":
                                              _vm.userLang === "en" ? 7 : 1,
                                            lang: _vm.userLang,
                                            format:
                                              _vm.userLang === "en"
                                                ? "MM.DD.YYYY"
                                                : "DD.MM.YYYY",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.clearAll()
                                            },
                                          },
                                          model: {
                                            value: _vm.beginActivationDateTime,
                                            callback: function ($$v) {
                                              _vm.beginActivationDateTime = $$v
                                            },
                                            expression:
                                              "beginActivationDateTime",
                                          },
                                        }),
                                        _vm.errors.has("report.beginDate")
                                          ? _c(
                                              "span",
                                              { staticClass: "help is-danger" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.errors.first(
                                                      "report.beginDate"
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.reportDownloadHasEndDate
                                  ? _c(
                                      "label",
                                      { staticClass: "form-label pl-4" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("report_end_time")) +
                                            " "
                                        ),
                                        _c("date-picker", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate.lazy",
                                              value:
                                                "required|max1year:beginDate",
                                              expression:
                                                "'required|max1year:beginDate'",
                                              modifiers: { lazy: true },
                                            },
                                          ],
                                          ref: "endDate",
                                          class: {
                                            "is-danger":
                                              _vm.errors.has("report.endDate"),
                                          },
                                          attrs: {
                                            type: "date",
                                            "value-type": "YYYY-MM-DD 00:00:00",
                                            "data-vv-scope": "report",
                                            "data-vv-name": "endDate",
                                            "data-vv-as":
                                              _vm.$t("report_end_time"),
                                            "first-day-of-week":
                                              _vm.userLang === "en" ? 7 : 1,
                                            lang: _vm.userLang,
                                            format:
                                              _vm.userLang === "en"
                                                ? "MM.DD.YYYY"
                                                : "DD.MM.YYYY",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.clearAll()
                                            },
                                          },
                                          model: {
                                            value: _vm.endActivationDateTime,
                                            callback: function ($$v) {
                                              _vm.endActivationDateTime = $$v
                                            },
                                            expression: "endActivationDateTime",
                                          },
                                        }),
                                        _vm.errors.has("report.endDate")
                                          ? _c(
                                              "span",
                                              { staticClass: "help is-danger" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.errors.first(
                                                      "report.endDate"
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.items.length > 0,
                          expression: "errors.items.length > 0",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "alert alert--card alert--error" },
                        [
                          _c("p", [_vm._v(_vm._s(_vm.$t("error_msg_title")))]),
                          _c(
                            "ul",
                            _vm._l(_vm.errors, function (error, index) {
                              return _c("li", { key: index }, [
                                _vm._v(" " + _vm._s(error.msg) + " "),
                              ])
                            }),
                            0
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "modal-footer" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button button--primary no-wrap",
                        attrs: { type: "button", disabled: _vm.isPending },
                        on: {
                          click: function ($event) {
                            return _vm.downloadReport()
                          },
                        },
                      },
                      [
                        !_vm.isPending
                          ? _c("span", [
                              _vm._v(" " + _vm._s(_vm.$t("create")) + " "),
                            ])
                          : _vm._e(),
                        _vm.isPending
                          ? _c("div", { staticClass: "lds-ellipsis" }, [
                              _c("div"),
                              _c("div"),
                              _c("div"),
                              _c("div"),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }