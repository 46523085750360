var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "top-group" }, [
      _c(
        "div",
        [
          _c("filter-perpage", {
            on: { searchFor: _vm.setSearchFor, perPage: _vm.setPerPage },
          }),
        ],
        1
      ),
      _c("div", [
        _vm.tabPane === "plan_user_assign"
          ? _c(
              "button",
              {
                staticClass:
                  "button button--secondary text-size-xs size-sm button--icon",
                on: {
                  click: function ($event) {
                    return _vm.addJourneyUserModal()
                  },
                },
              },
              [
                _c(
                  "icon-container",
                  {
                    staticClass: "mr-2",
                    attrs: {
                      name: "plusfull",
                      "view-box": "0 0 448 512",
                      width: 15,
                      height: 16,
                      color: "#FFF",
                      "is-icon-class": false,
                    },
                  },
                  [_c("icon-plusfull")],
                  1
                ),
                _vm._v(" " + _vm._s(_vm.$t("add_new_user")) + " "),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "table-responsive" },
        [
          _vm.tableIsLoading
            ? _c("div", { staticClass: "lds-dual-ring" })
            : _vm._e(),
          _c("vue-table", {
            ref: "myvuetable",
            class: { "table-fade": _vm.tableIsLoading },
            attrs: {
              "api-url": _vm.apiUrl,
              "http-fetch": _vm.myFetch,
              "api-mode": true,
              "pagination-path": "meta",
              "http-method": "get",
              "append-params": _vm.moreParams,
              "http-options": {
                headers: {
                  Authorization: `Bearer ${_vm.$store.state.auth.token}`,
                },
              },
              fields: _vm.fields,
              "no-data-template": _vm.$t("no_data_info"),
              css: _vm.css.table,
              "per-page": _vm.perPage,
              "query-params": _vm.makeQueryParams,
            },
            on: {
              "vuetable:loading": function ($event) {
                _vm.tableIsLoading = true
              },
              "vuetable:loaded": function ($event) {
                _vm.tableIsLoading = false
              },
              "vuetable:load-error": _vm.vuetableLoadError,
              "vuetable:pagination-data": _vm.onPaginationData,
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function (props) {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "ui button",
                        on: {
                          click: function ($event) {
                            return _vm.getMobilStatus(
                              props.rowData.id,
                              `ref${props.rowData.id}`
                            )
                          },
                          blur: function ($event) {
                            return _vm.closeTooltip(`ref${props.rowData.id}`)
                          },
                        },
                      },
                      [
                        _c(
                          "icon-container",
                          {
                            class: {
                              disabled:
                                !props.rowData.has_mobile_device_regitered,
                            },
                            attrs: {
                              width: 13,
                              height: 20,
                              name: "cellphone",
                              "view-box": "0 0 320 512",
                              color: props.rowData.has_mobile_device_regitered
                                ? "rgb(8, 206, 25)"
                                : "#727d92",
                            },
                          },
                          [_c("icon-cellphone")],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        ref: `ref${props.rowData.id}`,
                        staticClass: "content-tooltip",
                      },
                      [
                        _c("pre", [
                          _vm._v(
                            _vm._s(_vm.tooltipText ? _vm.tooltipText : "⏳")
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "email",
                fn: function (props) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "d-inline-block text-truncate",
                        staticStyle: { "max-width": "180px" },
                        attrs: { title: props.rowData.email },
                      },
                      [_vm._v(" " + _vm._s(props.rowData.email) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "employee_no",
                fn: function (props) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "d-inline-block text-truncate",
                        staticStyle: { "max-width": "180px" },
                        attrs: { title: props.rowData.employee_no },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              props.rowData.employee_no
                                ? props.rowData.employee_no
                                : "-"
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "activation_date",
                fn: function (props) {
                  return [
                    props.rowData.activation_date
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.formatDate(props.rowData.activation_date)
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "assignment_date",
                fn: function (props) {
                  return [
                    props.rowData.assignment_date
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.formatDate(props.rowData.assignment_date)
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "actions",
                fn: function (props) {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "ui button delete",
                        on: {
                          click: function ($event) {
                            return _vm.onAction(
                              "delete-item",
                              props.rowData,
                              props.rowIndex
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "icon-container",
                          {
                            attrs: {
                              width: 20,
                              height: 20,
                              "view-box": "0 0 24 24",
                              name: "trash",
                              color: "#727d92",
                            },
                          },
                          [_c("icon-trash")],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "div",
            { staticClass: "pagination__info" },
            [
              _c("vuetable-pagination-info", {
                ref: "paginationInfo",
                class: { "table-fade": _vm.tableIsLoading },
                attrs: {
                  "info-template": _vm.$t("pagination_info"),
                  "no-data-template": _vm.$t("no_data_info"),
                },
              }),
              _c(
                "div",
                { staticClass: "pagination__items" },
                [
                  _c("vuetable-pagination", {
                    ref: "pagination",
                    attrs: { css: _vm.css.pagination },
                    on: { "vuetable-pagination:change-page": _vm.onChangePage },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }