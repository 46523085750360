var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      {
        staticClass: "modal",
        class: { "is-loading": _vm.formSending },
        attrs: { tabindex: "-1" },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("header", { staticClass: "modal-header" }, [
              _vm.editMode
                ? _c("h5", { staticClass: "modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("edit_content")) + " "),
                  ])
                : _c("h5", { staticClass: "modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("add_new_content")) + " "),
                  ]),
              _c("button", {
                staticClass: "close",
                attrs: { type: "button" },
                domProps: { innerHTML: _vm._s("&times;") },
                on: { click: _vm.closeModal },
              }),
            ]),
            _c("nav", { staticClass: "navigation navigation--secondary" }, [
              _c("div", { staticClass: "container" }, [
                _c(
                  "ul",
                  {
                    staticClass: "navigation__list nav",
                    attrs: { id: "contentCreateTab", role: "tablist" },
                  },
                  [
                    _c("li", { staticClass: "navigation__item" }, [
                      _c(
                        "button",
                        {
                          staticClass: "navigation__link",
                          class: { active: _vm.tabPane === "mainTab" },
                          on: {
                            click: function ($event) {
                              return _vm.tabNavigation("mainTab")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("main_tab")) + " ")]
                      ),
                    ]),
                    _c(
                      "li",
                      {
                        staticClass: "navigation__item",
                        staticStyle: { display: "none" },
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "navigation__link",
                            class: { active: _vm.tabPane === "addFlowTab" },
                            on: {
                              click: function ($event) {
                                return _vm.tabNavigation("addFlowTab")
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("add_flow")) + " ")]
                        ),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.contentCreateValue === "quiz",
                            expression: "contentCreateValue === 'quiz'",
                          },
                        ],
                        staticClass: "navigation__item",
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "navigation__link",
                            class: {
                              active: _vm.tabPane === "quizSettingsTab",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.tabNavigation("quizSettingsTab")
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("quiz_setting_tab")) + " "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c("li", { staticClass: "navigation__item" }, [
                      _c(
                        "button",
                        {
                          staticClass: "navigation__link",
                          class: {
                            active: _vm.tabPane === "advancedSettingsTab",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.tabNavigation("advancedSettingsTab")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("advanced_tab")) + " ")]
                      ),
                    ]),
                    _vm.isVibonsAdmin
                      ? _c("li", { staticClass: "navigation__item" }, [
                          _c(
                            "button",
                            {
                              staticClass: "navigation__link",
                              class: {
                                active: _vm.tabPane === "adminSettingsTab",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.tabNavigation("adminSettingsTab")
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("admin_settings")) + " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.editMode
                      ? _c("li", { staticClass: "navigation__item" }, [
                          _c(
                            "button",
                            {
                              staticClass: "navigation__link",
                              class: {
                                active: _vm.tabPane === "journeyusedTab",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.tabNavigation("journeyusedTab")
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("journey_used")) + " ")]
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _vm.pageIsLoading
                ? _c("div", { staticClass: "page-loader" }, [
                    _c("div", { staticClass: "page-is-loading" }),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "tab-content" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tabPane === "mainTab",
                        expression: "tabPane === 'mainTab'",
                      },
                    ],
                    staticClass: "tab-pane",
                  },
                  [
                    _vm.$store.state.modal.launchedFrom !== "Contents"
                      ? _c("div", { staticClass: "form-group" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12 col-md-4" }, [
                              _c(
                                "div",
                                { staticClass: "form-group u-zIndex-3" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "form-label",
                                      attrs: { for: "beginDate" },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("begin_day")))]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.day,
                                        expression: "day",
                                      },
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    class: {
                                      "is-danger": _vm.errors.has("assign.day"),
                                    },
                                    attrs: {
                                      type: "number",
                                      id: "dayinput",
                                      min: "1",
                                      "data-vv-scope": "assign",
                                      "data-vv-name": "day",
                                      "data-vv-as": _vm.$t("begin_day"),
                                    },
                                    domProps: { value: _vm.day },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.day = $event.target.value
                                      },
                                    },
                                  }),
                                  _c("span", { staticClass: "daytext" }, [
                                    _vm._v(_vm._s(_vm.$t("day"))),
                                  ]),
                                  _vm.errors.has("assign.day")
                                    ? _c(
                                        "span",
                                        { staticClass: "help is-danger" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first("assign.day")
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "col-12 col-md-4" }, [
                              _c(
                                "div",
                                { staticClass: "form-group u-zIndex-3" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "form-label",
                                      attrs: { for: "beginTime" },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("begin_time")))]
                                  ),
                                  _c(
                                    "date-picker",
                                    {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      class: {
                                        "is-danger":
                                          _vm.errors.has("assign.beginTime"),
                                      },
                                      attrs: {
                                        format:
                                          _vm.userLang === "en"
                                            ? "hh:mm A"
                                            : "HH:mm",
                                        type: "time",
                                        "value-type": "date",
                                        "data-vv-scope": "assign",
                                        "data-vv-name": "beginTime",
                                        "data-vv-as": _vm.$t("begin_time"),
                                        lang: _vm.userLang,
                                        "time-picker-options": {
                                          start: "08:00",
                                          step: "00:15",
                                          end: "23:45",
                                          format:
                                            _vm.userLang === "en"
                                              ? "hh:mm A"
                                              : "HH:mm",
                                        },
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.adjustTime("time")
                                        },
                                      },
                                      model: {
                                        value: _vm.beginActivationDateTime,
                                        callback: function ($$v) {
                                          _vm.beginActivationDateTime = $$v
                                        },
                                        expression: "beginActivationDateTime",
                                      },
                                    },
                                    [
                                      _c(
                                        "template",
                                        { slot: "icon-calendar" },
                                        [
                                          _c(
                                            "icon-container",
                                            {
                                              attrs: {
                                                name: "time",
                                                "view-box": "0 0 512 512",
                                              },
                                            },
                                            [_c("icon-time")],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                  _vm.errors.has("assign.beginTime")
                                    ? _c(
                                        "span",
                                        { staticClass: "help is-danger" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first(
                                                "assign.beginTime"
                                              )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-12 col-md-4" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-label",
                                    attrs: { for: "roleType" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("roleType")))]
                                ),
                                _c("multi-select", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "",
                                      expression: "''",
                                    },
                                  ],
                                  staticClass: "selectbox selectbox--secondary",
                                  class: {
                                    "is-danger": _vm.errors.has("all.roleType"),
                                  },
                                  attrs: {
                                    "track-by": "value",
                                    name: "roleType",
                                    label: "label",
                                    "clear-on-select": "",
                                    "deselect-label": "",
                                    "select-label": "",
                                    "data-vv-scope": "all",
                                    "data-vv-name": "roleType",
                                    options: _vm.roleTypeOptions,
                                    "data-vv-as": _vm.$t("roleType"),
                                  },
                                  model: {
                                    value: _vm.roleType,
                                    callback: function ($$v) {
                                      _vm.roleType = $$v
                                    },
                                    expression: "roleType",
                                  },
                                }),
                                _vm.errors.has("all.roleType")
                                  ? _c(
                                      "span",
                                      { staticClass: "help is-danger" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.errors.first("all.roleType")
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    !_vm.editMode
                      ? _c(
                          "div",
                          { staticClass: "form-group form-group--cards" },
                          [
                            _c(
                              "vue-agile",
                              { attrs: { options: _vm.slideOptions } },
                              [
                                _c("div", { staticClass: "row slide" }, [
                                  _c("div", { staticClass: "col-6 col-sm-4" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "card card--media",
                                        class: {
                                          "has-active":
                                            _vm.contentCreateValue === "video",
                                        },
                                        attrs: {
                                          type: "button",
                                          disabled:
                                            _vm.contentCreateValue === "video",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.contentCreateSelector(
                                              "video"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "media-object media-object--row",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "media-object__media",
                                              },
                                              [
                                                _c(
                                                  "icon-container",
                                                  {
                                                    attrs: {
                                                      name: "video",
                                                      height: 21,
                                                      width: 26,
                                                      "view-box": "0 0 576 512",
                                                      "is-icon-class": true,
                                                    },
                                                  },
                                                  [_c("icon-video")],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "card__title media-object__text h4",
                                              },
                                              [_vm._v(_vm._s(_vm.$t("video")))]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "col-6 col-sm-4" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "card card--media",
                                        class: {
                                          "has-active":
                                            _vm.contentCreateValue ===
                                            "infographics",
                                        },
                                        attrs: {
                                          type: "button",
                                          disabled:
                                            _vm.contentCreateValue ===
                                            "infographics",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.contentCreateSelector(
                                              "infographics"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "media-object media-object--row",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "media-object__media",
                                              },
                                              [
                                                _c(
                                                  "icon-container",
                                                  {
                                                    attrs: {
                                                      name: "cogs",
                                                      height: 21,
                                                      width: 26,
                                                      "view-box": "0 0 640 512",
                                                      "is-icon-class": true,
                                                    },
                                                  },
                                                  [_c("icon-cogs")],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "card__title media-object__text h4",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("infographics"))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "col-6 col-sm-4" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "card card--media",
                                        class: {
                                          "has-active":
                                            _vm.contentCreateValue ===
                                              "external-link" ||
                                            _vm.contentCreateValue === "link",
                                        },
                                        attrs: {
                                          type: "button",
                                          disabled:
                                            _vm.contentCreateValue ===
                                              "external-link" ||
                                            _vm.contentCreateValue === "link",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.contentCreateSelector(
                                              "external-link"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "media-object media-object--row",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "media-object__media",
                                              },
                                              [
                                                _c(
                                                  "icon-container",
                                                  {
                                                    attrs: {
                                                      name: "share",
                                                      height: 21,
                                                      width: 19,
                                                      "view-box": "0 0 448 512",
                                                      "is-icon-class": true,
                                                    },
                                                  },
                                                  [_c("icon-share")],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "card__title media-object__text h4",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("external_link")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "col-6 col-sm-4" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "card card--media",
                                        class: {
                                          "has-active":
                                            _vm.contentCreateValue ===
                                            "flipbook",
                                        },
                                        attrs: {
                                          type: "button",
                                          disabled:
                                            _vm.contentCreateValue ===
                                            "flipbook",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.contentCreateSelector(
                                              "flipbook"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "media-object media-object--row",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "media-object__media",
                                              },
                                              [
                                                _c(
                                                  "icon-container",
                                                  {
                                                    attrs: {
                                                      name: "copy",
                                                      height: 21,
                                                      width: 19,
                                                      "view-box": "0 0 448 512",
                                                      "is-icon-class": true,
                                                    },
                                                  },
                                                  [_c("icon-copy")],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "card__title media-object__text h4",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("flipbook_"))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "col-6 col-sm-4" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "card card--media",
                                        class: {
                                          "has-active":
                                            _vm.contentCreateValue === "quiz",
                                        },
                                        attrs: {
                                          type: "button",
                                          disabled:
                                            _vm.contentCreateValue === "quiz",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.contentCreateSelector(
                                              "quiz"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "media-object media-object--row",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "media-object__media",
                                              },
                                              [
                                                _c(
                                                  "icon-container",
                                                  {
                                                    attrs: {
                                                      name: "file",
                                                      height: 21,
                                                      width: 19,
                                                      "view-box": "0 0 384 512",
                                                      "is-icon-class": true,
                                                    },
                                                  },
                                                  [_c("icon-file")],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "card__title media-object__text h4",
                                              },
                                              [_vm._v(_vm._s(_vm.$t("quiz_")))]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "col-6 col-sm-4" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "card card--media js-content-tab",
                                        class: {
                                          "has-active":
                                            _vm.contentCreateValue === "survey",
                                        },
                                        attrs: {
                                          type: "button",
                                          disabled:
                                            _vm.contentCreateValue === "survey",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.contentCreateSelector(
                                              "survey"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "media-object media-object--row",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "media-object__media",
                                              },
                                              [
                                                _c(
                                                  "icon-container",
                                                  {
                                                    attrs: {
                                                      name: "questioncircle",
                                                      height: 21,
                                                      width: 21,
                                                      "view-box": "0 0 512 512",
                                                      "is-icon-class": true,
                                                    },
                                                  },
                                                  [_c("icon-questioncircle")],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "card__title media-object__text h4",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("survey_"))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "row slide" }, [
                                  _c("div", { staticClass: "col-6 col-sm-4" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "card card--media",
                                        class: {
                                          "has-active":
                                            _vm.contentCreateValue ===
                                            "checklist",
                                        },
                                        attrs: {
                                          type: "button",
                                          disabled:
                                            _vm.contentCreateValue ===
                                            ".mobile-content__checklistt",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.contentCreateSelector(
                                              "checklist"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "media-object media-object--row",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "media-object__media",
                                              },
                                              [
                                                _c(
                                                  "icon-container",
                                                  {
                                                    attrs: {
                                                      name: "tasks",
                                                      height: 21,
                                                      width: 19,
                                                      "view-box": "0 0 512 512",
                                                      "is-icon-class": true,
                                                    },
                                                  },
                                                  [_c("icon-tasks")],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "card__title media-object__text h4",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("checklist_"))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "col-6 col-sm-4" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "card card--media js-content-tab",
                                        class: {
                                          "has-active":
                                            _vm.contentCreateValue === "scorm",
                                        },
                                        attrs: {
                                          type: "button",
                                          disabled:
                                            _vm.contentCreateValue === "scorm",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.contentCreateSelector(
                                              "scorm"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "media-object media-object--row",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "media-object__media",
                                              },
                                              [
                                                _c(
                                                  "icon-container",
                                                  {
                                                    attrs: {
                                                      name: "scorm",
                                                      height: 24,
                                                      width: 24,
                                                      "view-box": "0 0 32 32",
                                                      "is-icon-class": true,
                                                    },
                                                  },
                                                  [_c("icon-scorm")],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "card__title media-object__text h4",
                                              },
                                              [_vm._v(_vm._s(_vm.$t("scorm")))]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "col-6 col-sm-4" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "card card--media js-content-tab",
                                        class: {
                                          "has-active":
                                            _vm.contentCreateValue ===
                                            "podcast",
                                        },
                                        attrs: {
                                          type: "button",
                                          disabled:
                                            _vm.contentCreateValue ===
                                            "podcast",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.contentCreateSelector(
                                              "podcast"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "media-object media-object--row",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "media-object__media",
                                              },
                                              [
                                                _c(
                                                  "icon-container",
                                                  {
                                                    attrs: {
                                                      name: "podcast",
                                                      height: 30,
                                                      width: 26,
                                                      "view-box": "0 0 19 22",
                                                      "is-icon-class": true,
                                                    },
                                                  },
                                                  [_c("icon-podcast")],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "card__title media-object__text h4",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm
                                                      .$t("podcast")
                                                      .toUpperCase()
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "col-6 col-sm-4" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "card card--media js-content-tab",
                                        class: {
                                          "has-active":
                                            _vm.contentCreateValue ===
                                            "request",
                                        },
                                        attrs: {
                                          type: "button",
                                          disabled:
                                            _vm.contentCreateValue ===
                                            "request",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.contentCreateSelector(
                                              "request"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "media-object media-object--row",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "media-object__media",
                                              },
                                              [
                                                _c(
                                                  "icon-container",
                                                  {
                                                    attrs: {
                                                      name: "request",
                                                      height: 36,
                                                      width: 36,
                                                      "view-box": "0 0 64 64",
                                                      "is-icon-class": true,
                                                    },
                                                  },
                                                  [_c("icon-request")],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "card__title media-object__text h4",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("request_"))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "col-6 col-sm-4" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "card card--media",
                                        class: {
                                          "has-active":
                                            _vm.contentCreateValue ===
                                            "flipbookeditable",
                                        },
                                        attrs: {
                                          type: "button",
                                          disabled:
                                            _vm.contentCreateValue ===
                                            "flipbookeditable",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.contentCreateSelector(
                                              "flipbookeditable"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "media-object media-object--row",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "media-object__media",
                                              },
                                              [
                                                _c(
                                                  "icon-container",
                                                  {
                                                    attrs: {
                                                      name: "copy",
                                                      height: 21,
                                                      width: 19,
                                                      "view-box": "0 0 448 512",
                                                      "is-icon-class": true,
                                                    },
                                                  },
                                                  [_c("icon-copy")],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "card__title media-object__text h4",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("flipbook_editor")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "col-6 col-sm-4" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "card card--media js-content-tab",
                                        class: {
                                          "has-active":
                                            _vm.contentCreateValue ===
                                            "file_download",
                                        },
                                        attrs: {
                                          type: "button",
                                          disabled:
                                            _vm.contentCreateValue ===
                                            "file_download",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.contentCreateSelector(
                                              "file_download"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "media-object media-object--row",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "media-object__media",
                                              },
                                              [
                                                _c(
                                                  "icon-container",
                                                  {
                                                    attrs: {
                                                      name: "file_download",
                                                      height: 23,
                                                      width: 23,
                                                      "view-box": "0 0 14 14",
                                                      "is-icon-class": true,
                                                    },
                                                  },
                                                  [_c("icon-download")],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "card__title media-object__text h4",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("file_download")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "row slide" }, [
                                  _c("div", { staticClass: "col-6 col-sm-4" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "card card--media js-content-tab",
                                        class: {
                                          "has-active":
                                            _vm.contentCreateValue ===
                                            "pdf_upload",
                                        },
                                        attrs: {
                                          type: "button",
                                          disabled:
                                            _vm.contentCreateValue ===
                                            "pdf_upload",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.contentCreateSelector(
                                              "pdf_upload"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "media-object media-object--row",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "media-object__media",
                                              },
                                              [
                                                _c(
                                                  "icon-container",
                                                  {
                                                    attrs: {
                                                      name: "pdf",
                                                      height: 24,
                                                      width: 24,
                                                      "view-box": "0 0 24 24",
                                                      "is-icon-class": true,
                                                    },
                                                  },
                                                  [_c("icon-pdf")],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "card__title media-object__text h4",
                                              },
                                              [_vm._v(_vm._s(_vm.$t("pdf")))]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm.$store.state.modal.launchedFrom ===
                                  "journeyitemsv2"
                                    ? _c(
                                        "div",
                                        { staticClass: "col-6 col-sm-4" },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "card card--media js-content-tab",
                                              class: {
                                                "has-active":
                                                  _vm.contentCreateValue ===
                                                  "chapter",
                                              },
                                              attrs: {
                                                type: "button",
                                                disabled:
                                                  _vm.contentCreateValue ===
                                                  "chapter",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.contentCreateSelector(
                                                    "chapter"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "media-object media-object--row",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "media-object__media",
                                                    },
                                                    [
                                                      _c(
                                                        "icon-container",
                                                        {
                                                          attrs: {
                                                            name: "chapter",
                                                            height: 21,
                                                            width: 28,
                                                            "view-box":
                                                              "0 0 21 28",
                                                            "is-icon-class": true,
                                                          },
                                                        },
                                                        [_c("icon-chapter")],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "card__title media-object__text h4",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("chapter")
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.isVibonsAdmin
                                    ? _c(
                                        "div",
                                        { staticClass: "col-6 col-sm-4" },
                                        [
                                          _vm.$store.state.modal
                                            .launchedFrom === "Contents"
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "card card--media js-content-tab",
                                                  class: {
                                                    "has-active":
                                                      _vm.contentCreateValue ===
                                                      "external-youtbe-link",
                                                  },
                                                  attrs: {
                                                    type: "button",
                                                    disabled:
                                                      _vm.contentCreateValue ===
                                                      "external-youtbe-link",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.contentCreateSelector(
                                                        "external-youtbe-link"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "media-object media-object--row",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "media-object__media",
                                                        },
                                                        [
                                                          _c("svg-icon", {
                                                            attrs: {
                                                              type: "mdi",
                                                              width: "21",
                                                              height: "23",
                                                              path: _vm.getImgUrl(
                                                                "mdiYoutubeTv"
                                                              ),
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "card__title media-object__text h4",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "youtube_channel_videos"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                                _c(
                                  "template",
                                  { slot: "prevButton" },
                                  [
                                    _c(
                                      "icon-container",
                                      {
                                        attrs: {
                                          name: "contenttoggle",
                                          "view-box": "0 0 32 32",
                                          width: 32,
                                          height: 32,
                                          color: "#727D92",
                                        },
                                      },
                                      [_c("icon-contenttoggle")],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "template",
                                  { slot: "nextButton" },
                                  [
                                    _c(
                                      "icon-container",
                                      {
                                        staticClass: "slide-navbutton",
                                        attrs: {
                                          name: "contenttoggle",
                                          "view-box": "0 0 32 32",
                                          width: 32,
                                          height: 32,
                                          color: "#727D92",
                                        },
                                      },
                                      [_c("icon-contenttoggle")],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "tab-content" },
                      [
                        _c(
                          "keep-alive",
                          [
                            _vm.contentCreateValue === "video" &&
                            _vm.tabPane === "mainTab"
                              ? _c("video-content", {
                                  attrs: {
                                    "edit-mode": _vm.editMode,
                                    disabled: _vm.canNotEdit,
                                    "can-edit-only-item": _vm.canEditOnlyItem,
                                    name: _vm.name,
                                    "video-url": _vm.videoPreview,
                                    "video-type": _vm.videoType,
                                  },
                                  on: {
                                    setTitle: _vm.setTitle,
                                    setVideoType: (type) =>
                                      (_vm.videoType = type),
                                    setVideoFileUrl: (file) =>
                                      (_vm.videoPreview = file),
                                    videoDuration: _vm.setVideoDuration,
                                    file: _vm.fileEvent,
                                    video: _vm.videoEvent,
                                    updateContent: function ($event) {
                                      return _vm.createContent(
                                        "UPDATE_VIDEO_CONTENT"
                                      )
                                    },
                                    createContent: function ($event) {
                                      return _vm.createContent(
                                        "CREATE_VIDEO_CONTENT"
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.contentCreateValue === "podcast" &&
                            _vm.tabPane === "mainTab"
                              ? _c("podcast-content", {
                                  attrs: {
                                    "edit-mode": _vm.editMode,
                                    disabled: _vm.canNotEdit,
                                    "can-edit-only-item": _vm.canEditOnlyItem,
                                    name: _vm.name,
                                    "podcast-url": _vm.podcastPreview,
                                  },
                                  on: {
                                    setTitle: _vm.setTitle,
                                    file: _vm.fileEvent,
                                    podcast: _vm.podcastEvent,
                                    updateContent: function ($event) {
                                      return _vm.createContent(
                                        "UPDATE_PODCAST_CONTENT"
                                      )
                                    },
                                    createContent: function ($event) {
                                      return _vm.createContent(
                                        "CREATE_PODCAST_CONTENT"
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.contentCreateValue === "infographics" &&
                            _vm.tabPane === "mainTab"
                              ? _c("infographics-content", {
                                  attrs: {
                                    "edit-mode": _vm.editMode,
                                    disabled: _vm.canNotEdit,
                                    "can-edit-only-item": _vm.canEditOnlyItem,
                                    name: _vm.name,
                                    "info-graph-url": _vm.infographPreview,
                                  },
                                  on: {
                                    setTitle: _vm.setTitle,
                                    infograph: _vm.infographEvent,
                                    updateContent: function ($event) {
                                      return _vm.createContent(
                                        "UPDATE_INFOGRAPHICS_CONTENT"
                                      )
                                    },
                                    createContent: function ($event) {
                                      return _vm.createContent(
                                        "CREATE_INFOGRAPHICS_CONTENT"
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.contentCreateValue === "scorm" &&
                            _vm.tabPane === "mainTab"
                              ? _c("scorm-content", {
                                  attrs: {
                                    "edit-mode": _vm.editMode,
                                    disabled: _vm.canNotEdit,
                                    "can-edit-only-item": _vm.canEditOnlyItem,
                                    name: _vm.name,
                                    "external-url": _vm.externalUrl,
                                    "index-file-path": _vm.indexFilePath,
                                  },
                                  on: {
                                    setTitle: _vm.setTitle,
                                    setPackage: (file) =>
                                      (_vm.package_zip = file),
                                    setIndexFilePath: (val) =>
                                      (_vm.indexFilePath = val),
                                    scorm: (value) => (_vm.externalUrl = value),
                                    updateContent: function ($event) {
                                      return _vm.createContent(
                                        "UPDATE_SCORM_CONTENT"
                                      )
                                    },
                                    createContent: function ($event) {
                                      return _vm.createContent(
                                        "CREATE_SCORM_CONTENT"
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.contentCreateValue === "article" &&
                            _vm.tabPane === "mainTab"
                              ? _c("article-content", {
                                  attrs: {
                                    "edit-mode": _vm.editMode,
                                    disabled: _vm.canNotEdit,
                                    "can-edit-only-item": _vm.canEditOnlyItem,
                                    name: _vm.name,
                                    "article-html": _vm.injectedArticleHtml,
                                  },
                                  on: {
                                    setTitle: _vm.setTitle,
                                    file: _vm.fileEvent,
                                    article: _vm.articleEvent,
                                    updateContent: function ($event) {
                                      return _vm.createContent(
                                        "UPDATE_ARTICLE_CONTENT"
                                      )
                                    },
                                    createContent: function ($event) {
                                      return _vm.createContent(
                                        "CREATE_ARTICLE_CONTENT"
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.contentCreateValue === "flipbook" &&
                            _vm.tabPane === "mainTab"
                              ? _c("flipbook-content", {
                                  attrs: {
                                    "edit-mode": _vm.editMode,
                                    disabled: _vm.canNotEdit,
                                    "can-edit-only-item": _vm.canEditOnlyItem,
                                    "thumbnail-urls": _vm.flipImages,
                                    name: _vm.name,
                                  },
                                  on: {
                                    setTitle: _vm.setTitle,
                                    setSpinner: _vm.setSpinner,
                                    flipEvent: _vm.flipEvent,
                                    flipSort: _vm.flipSort,
                                    updateContent: function ($event) {
                                      return _vm.createContent(
                                        "UPDATE_FLIPBOOK_CONTENT"
                                      )
                                    },
                                    createContent: function ($event) {
                                      return _vm.createContent(
                                        "CREATE_FLIPBOOK_CONTENT"
                                      )
                                    },
                                    removePreview: _vm.removePreviewItem,
                                  },
                                })
                              : _vm._e(),
                            _vm.contentCreateValue === "quiz" &&
                            _vm.tabPane === "mainTab"
                              ? _c(
                                  "quiz-content",
                                  {
                                    attrs: {
                                      "edit-mode": _vm.editMode,
                                      disabled: _vm.canNotEdit,
                                      "can-edit-only-item": _vm.canEditOnlyItem,
                                      name: _vm.name,
                                      "root-questions": _vm.questions,
                                    },
                                    on: {
                                      setTitle: _vm.setTitle,
                                      updateContent: function ($event) {
                                        return _vm.createContent(
                                          "UPDATE_QUIZ_CONTENT"
                                        )
                                      },
                                      createContent: function ($event) {
                                        return _vm.createContent(
                                          "CREATE_QUIZ_CONTENT"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("quiz-component", {
                                      attrs: {
                                        "edit-mode": _vm.editMode,
                                        disabled: _vm.canNotEdit,
                                        "can-edit-only-item":
                                          _vm.canEditOnlyItem,
                                        "quiz-type": _vm.quizType,
                                        "as-scope": "quizquiz",
                                        repetitive_max_question_per_day:
                                          _vm.repetitiveMaxQuestionPerDay,
                                        repetitive_correct_answer_count:
                                          _vm.repetitiveCorrectAnswerCount,
                                      },
                                      on: {
                                        setQuestions: _vm.setQuestions,
                                        triggerPreviewNextQuestion:
                                          _vm.previewQuestionNext,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.contentCreateValue === "survey" &&
                            _vm.tabPane === "mainTab"
                              ? _c("survey-content", {
                                  attrs: {
                                    "edit-mode": _vm.editMode,
                                    disabled: _vm.canNotEdit,
                                    "can-edit-only-item": _vm.canEditOnlyItem,
                                    name: _vm.name,
                                    "survey-content-type":
                                      _vm.surveyContentType,
                                  },
                                  on: {
                                    setTitle: _vm.setTitle,
                                    setSurveyContentType: (type) =>
                                      (_vm.surveyContentType = type),
                                    setQuestions: _vm.setSurveyQuestions,
                                    updateContent: function ($event) {
                                      return _vm.createContent(
                                        "UPDATE_SURVEY_CONTENT"
                                      )
                                    },
                                    createContent: function ($event) {
                                      return _vm.createContent(
                                        "CREATE_SURVEY_CONTENT"
                                      )
                                    },
                                    triggerPreviewNextQuestion:
                                      _vm.previewQuestionNext,
                                  },
                                })
                              : _vm._e(),
                            _vm.contentCreateValue === "request" &&
                            _vm.tabPane === "mainTab"
                              ? _c("request-content", {
                                  attrs: {
                                    "edit-mode": _vm.editMode,
                                    disabled: _vm.canNotEdit,
                                    "can-edit-only-item": _vm.canEditOnlyItem,
                                    name: _vm.name,
                                  },
                                  on: {
                                    setTitle: _vm.setTitle,
                                    setQuestions: _vm.setRequestQuestions,
                                    updateContent: function ($event) {
                                      return _vm.createContent(
                                        "UPDATE_REQUEST_CONTENT"
                                      )
                                    },
                                    createContent: function ($event) {
                                      return _vm.createContent(
                                        "CREATE_REQUEST_CONTENT"
                                      )
                                    },
                                    triggerPreviewNextQuestion:
                                      _vm.previewQuestionNext,
                                  },
                                })
                              : _vm._e(),
                            (_vm.contentCreateValue === "external-link" ||
                              _vm.contentCreateValue === "link") &&
                            _vm.tabPane === "mainTab"
                              ? _c("external-link-content", {
                                  attrs: {
                                    "edit-mode": _vm.editMode,
                                    disabled: _vm.canNotEdit,
                                    "can-edit-only-item": _vm.canEditOnlyItem,
                                    name: _vm.name,
                                    "external-url": _vm.externalUrl,
                                  },
                                  on: {
                                    setTitle: _vm.setTitle,
                                    setDesc: _vm.setDesc,
                                    setDuration: _vm.setDuration,
                                    setProvider: (provider) =>
                                      (_vm.content_provider = provider),
                                    setTags: _vm.setTags,
                                    setInfo: _vm.setInfo,
                                    setThumbnail: _vm.setThumbnail,
                                    externalUrl: _vm.handlerExternalUrl,
                                    updateContent: function ($event) {
                                      return _vm.createContent(
                                        "UPDATE_EXTERNAL_LINK_CONTENT"
                                      )
                                    },
                                    createContent: function ($event) {
                                      return _vm.createContent(
                                        "CREATE_EXTERNAL_LINK_CONTENT"
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.contentCreateValue === "external-youtbe-link" &&
                            _vm.tabPane === "mainTab"
                              ? _c("external-youtube-link-content", {
                                  attrs: {
                                    "edit-mode": _vm.editMode,
                                    disabled: _vm.canNotEdit,
                                    "can-edit-only-item": _vm.canEditOnlyItem,
                                    name: _vm.name,
                                    "external-url": _vm.externalUrl,
                                    "content-language": _vm.contentLanguage,
                                    is_public: _vm.is_public,
                                    is_notifications_enabled:
                                      _vm.is_notifications_enabled,
                                    is_email_enabled: _vm.is_email_enabled,
                                    push_notification_title:
                                      _vm.push_notification_title,
                                    push_notification_body:
                                      _vm.push_notification_body,
                                    is_reminder_enabled:
                                      _vm.is_reminder_enabled,
                                  },
                                })
                              : _vm._e(),
                            _vm.contentCreateValue === "checklist" &&
                            _vm.tabPane === "mainTab"
                              ? _c("checklist-content", {
                                  attrs: {
                                    "edit-mode": _vm.editMode,
                                    disabled: _vm.canNotEdit,
                                    "can-edit-only-item": _vm.canEditOnlyItem,
                                    name: _vm.name,
                                  },
                                  on: {
                                    setTitle: _vm.setTitle,
                                    setChecklistItems: _vm.setChecklistItems,
                                    updateContent: function ($event) {
                                      return _vm.createContent(
                                        "UPDATE_CHECKLIST_CONTENT"
                                      )
                                    },
                                    createContent: function ($event) {
                                      return _vm.createContent(
                                        "CREATE_CHECKLIST_CONTENT"
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.contentCreateValue === "file_download" &&
                            _vm.tabPane === "mainTab"
                              ? _c("filedownload-content", {
                                  attrs: {
                                    "edit-mode": _vm.editMode,
                                    disabled: _vm.canNotEdit,
                                    "can-edit-only-item": _vm.canEditOnlyItem,
                                    name: _vm.name,
                                  },
                                  on: {
                                    setTitle: _vm.setTitle,
                                    setFiledownloadItems:
                                      _vm.setFiledownloadItems,
                                    updateContent: function ($event) {
                                      return _vm.createContent(
                                        "UPDATE_FILEDOWNLOAD_CONTENT"
                                      )
                                    },
                                    createContent: function ($event) {
                                      return _vm.createContent(
                                        "CREATE_FILEDOWNLOAD_CONTENT"
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.contentCreateValue === "pdf_upload" &&
                            _vm.tabPane === "mainTab"
                              ? _c("pdfupload-content", {
                                  attrs: {
                                    "edit-mode": _vm.editMode,
                                    disabled: _vm.canNotEdit,
                                    "can-edit-only-item": _vm.canEditOnlyItem,
                                    name: _vm.name,
                                    "external-url": _vm.externalUrl,
                                  },
                                  on: {
                                    setTitle: _vm.setTitle,
                                    setPdfFile: _vm.setPdfFile,
                                    updateContent: function ($event) {
                                      return _vm.createContent(
                                        "UPDATE_PDFUPLOAD_CONTENT"
                                      )
                                    },
                                    createContent: function ($event) {
                                      return _vm.createContent(
                                        "CREATE_PDFUPLOAD_CONTENT"
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.contentCreateValue === "notification" &&
                            _vm.tabPane === "mainTab"
                              ? _c("notification-content", {
                                  attrs: {
                                    "edit-mode": _vm.editMode,
                                    disabled: _vm.canNotEdit,
                                    "n-body": _vm.notification_body,
                                    "n-title": _vm.notification_title,
                                    "n-journey-item-id":
                                      _vm.notification_journey_item_id,
                                  },
                                  on: {
                                    setNBody: _vm.setNotificationBody,
                                    setNTitle: _vm.setNotificationTitle,
                                    setNJourneyItemId:
                                      _vm.setNotificationJourneyId,
                                    updateContent: function ($event) {
                                      return _vm.createContent(
                                        "UPDATE_SCHEDULED_NOTIFICATION_CONTENT"
                                      )
                                    },
                                    createContent: function ($event) {
                                      return _vm.createContent(
                                        "CREATE_SCHEDULED_NOTIFICATION_CONTENT"
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.contentCreateValue === "chapter" &&
                            _vm.tabPane === "mainTab"
                              ? _c("chapter-content", {
                                  attrs: {
                                    "edit-mode": _vm.editMode,
                                    disabled: _vm.canNotEdit,
                                    name: _vm.name,
                                    desc: _vm.desc,
                                  },
                                  on: {
                                    setTitle: _vm.setTitle,
                                    setDesc: _vm.setDesc,
                                    updateContent: function ($event) {
                                      return _vm.createContent(
                                        "UPDATE_CHAPTER_CONTENT"
                                      )
                                    },
                                    createContent: function ($event) {
                                      return _vm.createContent(
                                        "CREATE_CHAPTER_CONTENT"
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.isOnlyDatesEditable &&
                    !_vm.pageIsLoading &&
                    _vm.contentCreateValue !== "chapter" &&
                    _vm.contentCreateValue !== "file_download"
                      ? _c("div", { staticClass: "modal-footer" }, [
                          _c(
                            "button",
                            {
                              staticClass: "button button--primary",
                              attrs: {
                                type: "button",
                                disabled:
                                  _vm.canNotEdit && !_vm.canEditOnlyItem,
                              },
                              on: { click: _vm.updateOnlyDatesEditableItems },
                            },
                            [
                              !_vm.isPending
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("update_button")) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.isPending
                                ? _c("div", { staticClass: "lds-ellipsis" }, [
                                    _c("div"),
                                    _c("div"),
                                    _c("div"),
                                    _c("div"),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm.tabPane === "quizSettingsTab" &&
                _vm.contentCreateValue === "quiz"
                  ? _c("div", { staticClass: "tab-pane" }, [
                      _c("div", { staticClass: "form" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("div", { staticClass: "swicth-checkbox" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.quizType,
                                    expression: "quizType",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "quizType",
                                  disabled:
                                    _vm.canNotEdit && !_vm.canEditOnlyItem,
                                  "true-value": "repetitive",
                                  "false-value": "standard",
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.quizType)
                                    ? _vm._i(_vm.quizType, null) > -1
                                    : _vm._q(_vm.quizType, "repetitive"),
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.quizType,
                                      $$el = $event.target,
                                      $$c = $$el.checked
                                        ? "repetitive"
                                        : "standard"
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.quizType = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.quizType = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.quizType = $$c
                                    }
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "swicth-label",
                                  attrs: { for: "quizType" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("quiz_type")) + " "
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "collapse-transition",
                              { attrs: { duration: 500, dimension: "height" } },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.quizType === "repetitive",
                                        expression: "quizType === 'repetitive'",
                                      },
                                    ],
                                    staticClass: "row",
                                  },
                                  [
                                    _c("div", { staticClass: "col-6" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-group form-group--light u-mB-0",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "form-label",
                                              attrs: { for: "journeyLanguage" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "repetitive_max_question_per_day"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.repetitiveMaxQuestionPerDay,
                                                expression:
                                                  "repetitiveMaxQuestionPerDay",
                                              },
                                            ],
                                            staticClass: "mx-input",
                                            attrs: {
                                              id: "repetitive_max_question_per_day",
                                              type: "number",
                                              max: "50",
                                              min: "1",
                                              step: "1",
                                            },
                                            domProps: {
                                              value:
                                                _vm.repetitiveMaxQuestionPerDay,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.repetitiveMaxQuestionPerDay =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ]),
                                    _c("div", { staticClass: "col-6" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-group form-group--light u-mB-0",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "form-label",
                                              attrs: { for: "journeyLanguage" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "repetitive_correct_answer_count"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.repetitiveCorrectAnswerCount,
                                                expression:
                                                  "repetitiveCorrectAnswerCount",
                                              },
                                            ],
                                            staticClass: "mx-input",
                                            attrs: {
                                              type: "number",
                                              id: "repetitive_correct_answer_count",
                                              max: "50",
                                              min: "1",
                                              step: "1",
                                            },
                                            domProps: {
                                              value:
                                                _vm.repetitiveCorrectAnswerCount,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.repetitiveCorrectAnswerCount =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "form-group" }, [
                          _c("div", { staticClass: "swicth-checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.is_retake_enabled,
                                  expression: "is_retake_enabled",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "is_retake_enabled",
                                disabled:
                                  _vm.canNotEdit && !_vm.canEditOnlyItem,
                                "true-value": 1,
                                "false-value": 0,
                              },
                              domProps: {
                                checked: Array.isArray(_vm.is_retake_enabled)
                                  ? _vm._i(_vm.is_retake_enabled, null) > -1
                                  : _vm._q(_vm.is_retake_enabled, 1),
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.is_retake_enabled,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? 1 : 0
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.is_retake_enabled = $$a.concat([
                                          $$v,
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.is_retake_enabled = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.is_retake_enabled = $$c
                                  }
                                },
                              },
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "swicth-label",
                                attrs: { for: "is_retake_enabled" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("is_retake_enabled")) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _c("div", { staticClass: "swicth-checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.is_show_correct_answers_enabled,
                                  expression: "is_show_correct_answers_enabled",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "is_show_correct_answers_enabled",
                                disabled:
                                  _vm.canNotEdit && !_vm.canEditOnlyItem,
                                "true-value": 0,
                                "false-value": 1,
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.is_show_correct_answers_enabled
                                )
                                  ? _vm._i(
                                      _vm.is_show_correct_answers_enabled,
                                      null
                                    ) > -1
                                  : _vm._q(
                                      _vm.is_show_correct_answers_enabled,
                                      0
                                    ),
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.is_show_correct_answers_enabled,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? 0 : 1
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.is_show_correct_answers_enabled =
                                          $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.is_show_correct_answers_enabled =
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.is_show_correct_answers_enabled = $$c
                                  }
                                },
                              },
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "swicth-label",
                                attrs: {
                                  for: "is_show_correct_answers_enabled",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("is_show_correct_answers_enabled")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _c("div", { staticClass: "swicth-checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.is_show_score,
                                  expression: "is_show_score",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "is_show_score",
                                disabled:
                                  _vm.canNotEdit && !_vm.canEditOnlyItem,
                                "true-value": 1,
                                "false-value": 0,
                              },
                              domProps: {
                                checked: Array.isArray(_vm.is_show_score)
                                  ? _vm._i(_vm.is_show_score, null) > -1
                                  : _vm._q(_vm.is_show_score, 1),
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.is_show_score,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? 1 : 0
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.is_show_score = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.is_show_score = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.is_show_score = $$c
                                  }
                                },
                              },
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "swicth-label",
                                attrs: { for: "is_show_score" },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("is_show_score")) + " "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _c("div", { staticClass: "swicth-checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.is_randomize_questions_enabled,
                                  expression: "is_randomize_questions_enabled",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "is_randomize_questions_enabled",
                                disabled:
                                  _vm.canNotEdit && !_vm.canEditOnlyItem,
                                "true-value": 1,
                                "false-value": 0,
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.is_randomize_questions_enabled
                                )
                                  ? _vm._i(
                                      _vm.is_randomize_questions_enabled,
                                      null
                                    ) > -1
                                  : _vm._q(
                                      _vm.is_randomize_questions_enabled,
                                      1
                                    ),
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.is_randomize_questions_enabled,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? 1 : 0
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.is_randomize_questions_enabled =
                                          $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.is_randomize_questions_enabled =
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.is_randomize_questions_enabled = $$c
                                  }
                                },
                              },
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "swicth-label",
                                attrs: {
                                  for: "is_randomize_questions_enabled",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("is_randomize_questions_enabled")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _c("div", { staticClass: "swicth-checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.is_randomize_choices_enabled,
                                  expression: "is_randomize_choices_enabled",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "is_randomize_choices_enabled",
                                disabled:
                                  _vm.canNotEdit && !_vm.canEditOnlyItem,
                                "true-value": 1,
                                "false-value": 0,
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.is_randomize_choices_enabled
                                )
                                  ? _vm._i(
                                      _vm.is_randomize_choices_enabled,
                                      null
                                    ) > -1
                                  : _vm._q(_vm.is_randomize_choices_enabled, 1),
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.is_randomize_choices_enabled,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? 1 : 0
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.is_randomize_choices_enabled =
                                          $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.is_randomize_choices_enabled = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.is_randomize_choices_enabled = $$c
                                  }
                                },
                              },
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "swicth-label",
                                attrs: { for: "is_randomize_choices_enabled" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("is_randomize_choices_enabled")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-group col-4" }, [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "max_questions_count" },
                            },
                            [_vm._v(_vm._s(_vm.$t("max_questions_count")))]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.max_questions_count,
                                expression: "max_questions_count",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "number",
                              min: "0",
                              max: this.questions ? this.questions.length : 0,
                            },
                            domProps: { value: _vm.max_questions_count },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.max_questions_count = $event.target.value
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "modal-footer" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button button--primary",
                            attrs: {
                              type: "button",
                              disabled: _vm.canNotEdit && !_vm.canEditOnlyItem,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.tabNavigation("mainTab")
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.contentCreateValue !== "notification" &&
                _vm.contentCreateValue !== "chapter" &&
                !_vm.isOnlyDatesEditable
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.tabPane === "advancedSettingsTab",
                            expression: "tabPane === 'advancedSettingsTab'",
                          },
                        ],
                        staticClass: "tab-pane",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "form form--horizontal" },
                          [
                            _c(
                              "div",
                              { staticClass: "form-group form-group--cards" },
                              [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-6" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-label",
                                          attrs: { for: "uploadThumbnail" },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("thumbnail")))]
                                      ),
                                      _c("thumbnail-container", {
                                        staticClass: "card card--upload",
                                        class: {
                                          "is-danger":
                                            _vm.errors.has("all.thumbnail"),
                                        },
                                        attrs: {
                                          "thumbnail-image":
                                            _vm.thumbnailPreview,
                                          "vee-scoop-name": "all",
                                          "is-disable": _vm.canNotEdit,
                                        },
                                        on: { update: _vm.thumbnailAdded },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.errors.has("all.thumbnail"),
                                              expression:
                                                "errors.has('all.thumbnail')",
                                            },
                                          ],
                                          staticClass: "help is-danger",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first("all.thumbnail")
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "col-6" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-12 mb-2" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "form-label",
                                              attrs: { for: "contentLang" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("content_lang_label")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("multi-select", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required",
                                                expression: "'required'",
                                              },
                                            ],
                                            staticClass:
                                              "selectbox selectbox--secondary",
                                            class: {
                                              "is-danger":
                                                _vm.errors.has("all.language"),
                                            },
                                            attrs: {
                                              "track-by": "value",
                                              name: "contentLang",
                                              label: "value",
                                              disabled: _vm.canNotEdit,
                                              "clear-on-select": "",
                                              "deselect-label": "",
                                              "select-label": "",
                                              "data-vv-scope": "all",
                                              "data-vv-name": "language",
                                              options:
                                                _vm.contentLanguageOptions,
                                              "data-vv-as":
                                                _vm.$t("content_lang_label"),
                                            },
                                            model: {
                                              value: _vm.contentLanguage,
                                              callback: function ($$v) {
                                                _vm.contentLanguage = $$v
                                              },
                                              expression: "contentLanguage",
                                            },
                                          }),
                                          _vm.errors.has("all.language")
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "help is-danger",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errors.first(
                                                        "all.language"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-12 mb-2" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "form-label",
                                              attrs: { for: "cprovider" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm
                                                    .$t("content_provider")
                                                    .toUpperCase()
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "max:200",
                                                expression: "'max:200'",
                                              },
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.content_provider,
                                                expression: "content_provider",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-danger": _vm.errors.has(
                                                "all.content_provider"
                                              ),
                                            },
                                            attrs: {
                                              type: "text",
                                              id: "cprovider",
                                              disabled: _vm.canNotEdit,
                                              "data-vv-scope": "all",
                                              "data-vv-name":
                                                "content_provider",
                                            },
                                            domProps: {
                                              value: _vm.content_provider,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.content_provider =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          _vm.errors.has("all.content_provider")
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "help is-danger",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errors.first(
                                                        "all.content_provider"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-12 mb-2" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "form-label",
                                              attrs: { for: "info" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("info_label"))
                                              ),
                                            ]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "max:30",
                                                expression: "'max:30'",
                                              },
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.info,
                                                expression: "info",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-danger":
                                                _vm.errors.has("all.info"),
                                            },
                                            attrs: {
                                              type: "text",
                                              id: "info",
                                              disabled: _vm.canNotEdit,
                                              "data-vv-scope": "all",
                                              "data-vv-name": "info",
                                            },
                                            domProps: { value: _vm.info },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.info = $event.target.value
                                              },
                                            },
                                          }),
                                          _vm.errors.has("all.info")
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "help is-danger",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errors.first(
                                                        "all.info"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c("div", { staticClass: "col-12" }, [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "form-label",
                                            attrs: { for: "desc" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("description"))
                                            ),
                                          ]
                                        ),
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.desc,
                                              expression: "desc",
                                            },
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "max:1000",
                                              expression: "'max:1000'",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class: {
                                            "is-danger":
                                              _vm.errors.has("all.description"),
                                          },
                                          attrs: {
                                            disabled:
                                              _vm.canNotEdit &&
                                              !_vm.canEditOnlyItem,
                                            "data-vv-scope": "all",
                                            "data-vv-name": "description",
                                            "data-vv-as": _vm.$t("description"),
                                          },
                                          domProps: { value: _vm.desc },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.desc = $event.target.value
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.errors.has(
                                                    "all.description"
                                                  ),
                                                expression:
                                                  "errors.has('all.description')",
                                              },
                                            ],
                                            staticClass: "help is-danger",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.errors.first(
                                                  "all.description"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "col-12 mb-2" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "form-label",
                                              attrs: { for: "duration" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm
                                                    .$t("duration")
                                                    .toUpperCase()
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "max:10",
                                                expression: "'max:10'",
                                              },
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.duration,
                                                expression: "duration",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-danger":
                                                _vm.errors.has("all.duration"),
                                            },
                                            attrs: {
                                              type: "number",
                                              id: "duration",
                                              disabled: _vm.canNotEdit,
                                              "data-vv-scope": "all",
                                              "data-vv-name": "duration",
                                            },
                                            domProps: { value: _vm.duration },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.duration =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.errors.has(
                                                      "all.duration"
                                                    ),
                                                  expression:
                                                    "errors.has('all.duration')",
                                                },
                                              ],
                                              staticClass: "help is-danger",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.errors.first(
                                                    "all.duration"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-12" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "form-label",
                                              attrs: { for: "contentTags" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("tags_detail"))
                                              ),
                                            ]
                                          ),
                                          _c("vue-tags-input", {
                                            attrs: {
                                              disabled: _vm.canNotEdit,
                                              tags: _vm.tagsTemp,
                                              "add-on-key": [13, ":", ";", ","],
                                            },
                                            on: {
                                              "tags-changed": _vm.tagsEvent,
                                            },
                                            model: {
                                              value: _vm.tag,
                                              callback: function ($$v) {
                                                _vm.tag = $$v
                                              },
                                              expression: "tag",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-group d-flex justify-content-center noborder-bottom",
                              },
                              [
                                _c("div", { staticClass: "col-6" }, [
                                  _c(
                                    "div",
                                    { staticClass: "swicth-checkbox" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.is_notifications_enabled,
                                            expression:
                                              "is_notifications_enabled",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "sendPushNotification",
                                          disabled:
                                            _vm.canNotEdit &&
                                            !_vm.canEditOnlyItem,
                                          "true-value": 1,
                                          "false-value": 0,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.is_notifications_enabled
                                          )
                                            ? _vm._i(
                                                _vm.is_notifications_enabled,
                                                null
                                              ) > -1
                                            : _vm._q(
                                                _vm.is_notifications_enabled,
                                                1
                                              ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a =
                                                _vm.is_notifications_enabled,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? 1 : 0
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.is_notifications_enabled =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.is_notifications_enabled =
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                              }
                                            } else {
                                              _vm.is_notifications_enabled = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "swicth-label",
                                          attrs: {
                                            for: "sendPushNotification",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("send_push_notif")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "col-6" }, [
                                  _c(
                                    "div",
                                    { staticClass: "swicth-checkbox" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.is_email_enabled,
                                            expression: "is_email_enabled",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "sendEmail",
                                          disabled:
                                            _vm.canNotEdit &&
                                            !_vm.canEditOnlyItem,
                                          "true-value": 1,
                                          "false-value": 0,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.is_email_enabled
                                          )
                                            ? _vm._i(
                                                _vm.is_email_enabled,
                                                null
                                              ) > -1
                                            : _vm._q(_vm.is_email_enabled, 1),
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.is_email_enabled,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? 1 : 0
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.is_email_enabled =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.is_email_enabled = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.is_email_enabled = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "swicth-label",
                                          attrs: { for: "sendEmail" },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("send_email")))]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "collapse-transition",
                              { attrs: { duration: 500, dimension: "height" } },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.is_notifications_enabled,
                                        expression: "is_notifications_enabled",
                                      },
                                    ],
                                    staticClass: "row pl-2 bglight",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "form-group row" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "col-md-5 form-label",
                                            attrs: {
                                              for: "pushNotificationHeading",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("push_noti_head")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c("div", { staticClass: "col-md-7" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.push_notification_title,
                                                expression:
                                                  "push_notification_title",
                                              },
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "max:40",
                                                expression: "'max:40'",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-danger": _vm.errors.has(
                                                "all.push_notification_title"
                                              ),
                                            },
                                            attrs: {
                                              type: "text",
                                              disabled:
                                                _vm.canNotEdit &&
                                                !_vm.canEditOnlyItem,
                                              id: "pushNotificationHeading",
                                              "data-vv-name":
                                                "push_notification_title",
                                              "data-vv-scope": "all",
                                              "data-vv-as":
                                                _vm.$t("push_noti_head"),
                                            },
                                            domProps: {
                                              value:
                                                _vm.push_notification_title,
                                            },
                                            on: {
                                              keypress: function ($event) {
                                                return _vm.clearNotifMsg()
                                              },
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.push_notification_title =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          _c("button", {
                                            staticClass: "emoji-button",
                                            domProps: {
                                              innerHTML: _vm._s("☺"),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.dropdownToggle(
                                                  "title"
                                                )
                                              },
                                            },
                                          }),
                                          _vm.dropdownIndex === "title"
                                            ? _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "on-clickaway",
                                                      rawName: "v-on-clickaway",
                                                      value: _vm.hideDropdown,
                                                      expression:
                                                        "hideDropdown",
                                                    },
                                                  ],
                                                  staticClass: "dropdown",
                                                  staticStyle: {
                                                    "z-index": "1000001",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      ref: "dropdownBoxtitle",
                                                      staticClass:
                                                        "dropdown__menu emoji-dropdown-title",
                                                    },
                                                    [
                                                      _c("VEmojiPicker", {
                                                        staticClass: "emoji",
                                                        attrs: {
                                                          pack: _vm.emojiPack,
                                                          "label-search":
                                                            _vm.$t(
                                                              "search_placeholder"
                                                            ),
                                                        },
                                                        on: {
                                                          select:
                                                            _vm.SelectEmojiForTitle,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.errors.has(
                                            "all.push_notification_title"
                                          )
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "help is-danger",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errors.first(
                                                        "all.push_notification_title"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "form-group row" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "col-md-5 form-label",
                                            attrs: {
                                              for: "pushNotificationBody",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("push_noti_body")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c("div", { staticClass: "col-md-7" }, [
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.push_notification_body,
                                                expression:
                                                  "push_notification_body",
                                              },
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "max:110",
                                                expression: "'max:110'",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-danger": _vm.errors.has(
                                                "all.push_notification_body"
                                              ),
                                            },
                                            attrs: {
                                              id: "pushNotificationBody",
                                              disabled:
                                                _vm.canNotEdit &&
                                                !_vm.canEditOnlyItem,
                                              "data-vv-name":
                                                "push_notification_body",
                                              "data-vv-scope": "all",
                                              "data-vv-as":
                                                _vm.$t("push_noti_body"),
                                            },
                                            domProps: {
                                              value: _vm.push_notification_body,
                                            },
                                            on: {
                                              keypress: function ($event) {
                                                return _vm.clearNotifMsg()
                                              },
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.push_notification_body =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          _c("button", {
                                            staticClass: "emoji-button",
                                            domProps: {
                                              innerHTML: _vm._s("☺"),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.dropdownToggle(
                                                  "body"
                                                )
                                              },
                                            },
                                          }),
                                          _vm.dropdownIndex === "body"
                                            ? _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "on-clickaway",
                                                      rawName: "v-on-clickaway",
                                                      value: _vm.hideDropdown,
                                                      expression:
                                                        "hideDropdown",
                                                    },
                                                  ],
                                                  staticClass: "dropdown",
                                                  staticStyle: {
                                                    "z-index": "1000001",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      ref: "dropdownBoxbody",
                                                      staticClass:
                                                        "dropdown__menu emoji-dropdown-body",
                                                    },
                                                    [
                                                      _c("VEmojiPicker", {
                                                        staticClass: "emoji",
                                                        attrs: {
                                                          pack: _vm.emojiPack,
                                                          "label-search":
                                                            _vm.$t(
                                                              "search_placeholder"
                                                            ),
                                                        },
                                                        on: {
                                                          select:
                                                            _vm.SelectEmojiForBody,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.errors.has(
                                            "all.push_notification_body"
                                          )
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "help is-danger",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errors.first(
                                                        "all.push_notification_body"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-group d-flex justify-content-start noborder-bottom",
                              },
                              [
                                this.$store.state.modal.launchedFrom ===
                                "journeyitems"
                                  ? _c("div", { staticClass: "col-md-4" }, [
                                      _c(
                                        "div",
                                        { staticClass: "swicth-checkbox" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.is_info_labels_hidden,
                                                expression:
                                                  "is_info_labels_hidden",
                                              },
                                            ],
                                            attrs: {
                                              type: "checkbox",
                                              id: "hideLabel",
                                              disabled:
                                                _vm.canNotEdit &&
                                                !_vm.canEditOnlyItem,
                                              "true-value": 1,
                                              "false-value": 0,
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.is_info_labels_hidden
                                              )
                                                ? _vm._i(
                                                    _vm.is_info_labels_hidden,
                                                    null
                                                  ) > -1
                                                : _vm._q(
                                                    _vm.is_info_labels_hidden,
                                                    1
                                                  ),
                                            },
                                            on: {
                                              change: [
                                                function ($event) {
                                                  var $$a =
                                                      _vm.is_info_labels_hidden,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked ? 1 : 0
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.is_info_labels_hidden =
                                                          $$a.concat([$$v]))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.is_info_labels_hidden =
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            ))
                                                    }
                                                  } else {
                                                    _vm.is_info_labels_hidden =
                                                      $$c
                                                  }
                                                },
                                                function ($event) {
                                                  return _vm.hideTitle($event)
                                                },
                                              ],
                                            },
                                          }),
                                          _c(
                                            "label",
                                            {
                                              staticClass: "swicth-label",
                                              attrs: { for: "hideLabel" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("hide_title"))
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value: _vm.$t(
                                                    "hide_title_tooltip_msg"
                                                  ),
                                                  expression:
                                                    "$t('hide_title_tooltip_msg')",
                                                },
                                              ],
                                              staticClass: "vtooltip",
                                            },
                                            [
                                              _c("em", {
                                                staticClass:
                                                  "fa fa-question-circle",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                this.$store.state.modal.launchedFrom ===
                                "journeyitems"
                                  ? _c("div", { staticClass: "col-md-4" }, [
                                      _vm.isVibonsAdmin
                                        ? _c(
                                            "div",
                                            { staticClass: "swicth-checkbox" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.is_static,
                                                    expression: "is_static",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  id: "setStatic",
                                                  disabled:
                                                    _vm.canNotEdit &&
                                                    !_vm.canEditOnlyItem,
                                                  "true-value": 1,
                                                  "false-value": 0,
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.is_static
                                                  )
                                                    ? _vm._i(
                                                        _vm.is_static,
                                                        null
                                                      ) > -1
                                                    : _vm._q(_vm.is_static, 1),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a = _vm.is_static,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked ? 1 : 0
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          (_vm.is_static =
                                                            $$a.concat([$$v]))
                                                      } else {
                                                        $$i > -1 &&
                                                          (_vm.is_static = $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            ))
                                                      }
                                                    } else {
                                                      _vm.is_static = $$c
                                                    }
                                                  },
                                                },
                                              }),
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "swicth-label",
                                                  attrs: { for: "setStatic" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("static_content")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: _vm.$t(
                                                        "static_content_tooltip_msg"
                                                      ),
                                                      expression:
                                                        "$t('static_content_tooltip_msg')",
                                                    },
                                                  ],
                                                  staticClass: "vtooltip",
                                                },
                                                [
                                                  _c("li", {
                                                    staticClass:
                                                      "fa fa-question-circle",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                                _vm.showHideFromLxp
                                  ? _c("div", { staticClass: "col-6" }, [
                                      _c(
                                        "div",
                                        { staticClass: "swicth-checkbox" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.hide_from_lxp,
                                                expression: "hide_from_lxp",
                                              },
                                            ],
                                            attrs: {
                                              type: "checkbox",
                                              id: "hideFromLxp",
                                              "true-value": 1,
                                              "false-value": 0,
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.hide_from_lxp
                                              )
                                                ? _vm._i(
                                                    _vm.hide_from_lxp,
                                                    null
                                                  ) > -1
                                                : _vm._q(_vm.hide_from_lxp, 1),
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = _vm.hide_from_lxp,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked ? 1 : 0
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.hide_from_lxp =
                                                        $$a.concat([$$v]))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.hide_from_lxp = $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        ))
                                                  }
                                                } else {
                                                  _vm.hide_from_lxp = $$c
                                                }
                                              },
                                            },
                                          }),
                                          _c(
                                            "label",
                                            {
                                              staticClass: "swicth-label",
                                              attrs: { for: "hideFromLxp" },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("hidefromlxp")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _c("div", { staticClass: "col-6" }, [
                                  _c(
                                    "div",
                                    { staticClass: "swicth-checkbox" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.is_file_upload_required,
                                            expression:
                                              "is_file_upload_required",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "is_file_upload_required",
                                          "true-value": 1,
                                          "false-value": 0,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.is_file_upload_required
                                          )
                                            ? _vm._i(
                                                _vm.is_file_upload_required,
                                                null
                                              ) > -1
                                            : _vm._q(
                                                _vm.is_file_upload_required,
                                                1
                                              ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a =
                                                _vm.is_file_upload_required,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? 1 : 0
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.is_file_upload_required =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.is_file_upload_required =
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                              }
                                            } else {
                                              _vm.is_file_upload_required = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "swicth-label",
                                          attrs: {
                                            for: "is_file_upload_required",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("file_upload_required")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                staticStyle: { display: "none" },
                              },
                              [
                                _c("div", { staticClass: "col-md-12" }, [
                                  _c(
                                    "div",
                                    { staticClass: "swicth-checkbox" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.is_reminder_enabled,
                                            expression: "is_reminder_enabled",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "openedDays",
                                          disabled: _vm.canNotEdit,
                                          "true-value": 1,
                                          "false-value": 0,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.is_reminder_enabled
                                          )
                                            ? _vm._i(
                                                _vm.is_reminder_enabled,
                                                null
                                              ) > -1
                                            : _vm._q(
                                                _vm.is_reminder_enabled,
                                                1
                                              ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.is_reminder_enabled,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? 1 : 0
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.is_reminder_enabled =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.is_reminder_enabled = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.is_reminder_enabled = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "swicth-label",
                                          attrs: { for: "openedDays" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("send_reminder")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "modal-footer" }, [
                          _c(
                            "button",
                            {
                              staticClass: "button button--primary",
                              attrs: {
                                type: "button",
                                disabled:
                                  _vm.canNotEdit && !_vm.canEditOnlyItem,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.tabNavigation("mainTab")
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tabPane === "adminSettingsTab",
                        expression: "tabPane === 'adminSettingsTab'",
                      },
                    ],
                    staticClass: "tab-pane",
                  },
                  [
                    _c("div", { staticClass: "form form--horizontal" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-6" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "customer" },
                              },
                              [_vm._v(_vm._s(_vm.$t("customer").toUpperCase()))]
                            ),
                            _c("multi-select", {
                              staticClass: "selectbox selectbox--secondary",
                              class: {
                                "is-danger": _vm.errors.has("all.customer"),
                              },
                              attrs: {
                                "track-by": "id",
                                label: "name",
                                "clear-on-select": "",
                                "deselect-label": "",
                                "select-label": "",
                                options: _vm.customerOptions,
                                "data-vv-scope": "all",
                                "data-vv-name": "customer",
                              },
                              model: {
                                value: _vm.selectCustomer,
                                callback: function ($$v) {
                                  _vm.selectCustomer = $$v
                                },
                                expression: "selectCustomer",
                              },
                            }),
                            _vm.errors.has("all.customer")
                              ? _c("span", { staticClass: "help is-danger" }, [
                                  _vm._v(
                                    _vm._s(_vm.errors.first("all.customer"))
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "col-6" }, [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "thumbnail_filename" },
                            },
                            [_vm._v(_vm._s(_vm.$t("thumbnail_filename")))]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "max:400",
                                expression: "'max:400'",
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.thumbnail_filename,
                                expression: "thumbnail_filename",
                              },
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-danger": _vm.errors.has(
                                "all.thumbnail_filename"
                              ),
                            },
                            attrs: {
                              type: "text",
                              id: "thumbnail_filename",
                              disabled: _vm.canNotEdit,
                              "data-vv-scope": "all",
                              "data-vv-name": "thumbnail_filename",
                            },
                            domProps: { value: _vm.thumbnail_filename },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.thumbnail_filename = $event.target.value
                              },
                            },
                          }),
                          _vm.errors.has("all.thumbnail_filename")
                            ? _c("span", { staticClass: "help is-danger" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.errors.first("all.thumbnail_filename")
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "col-12 mt-4" }, [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "options" },
                            },
                            [_vm._v(_vm._s(_vm.$t("options")))]
                          ),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.aoptions,
                                expression: "aoptions",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "max:1000",
                                expression: "'max:1000'",
                              },
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-danger": _vm.errors.has("all.options"),
                            },
                            attrs: {
                              disabled: _vm.canNotEdit && !_vm.canEditOnlyItem,
                              "data-vv-scope": "all",
                              "data-vv-name": "options",
                              "data-vv-as": _vm.$t("options"),
                            },
                            domProps: { value: _vm.aoptions },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.aoptions = $event.target.value
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("all.options"),
                                  expression: "errors.has('all.options')",
                                },
                              ],
                              staticClass: "help is-danger",
                            },
                            [_vm._v(_vm._s(_vm.errors.first("all.options")))]
                          ),
                        ]),
                        _c("div", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "swicth-checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.is_public,
                                  expression: "is_public",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "setPublic",
                                disabled: _vm.canNotEdit,
                                "true-value": 1,
                                "false-value": 0,
                              },
                              domProps: {
                                checked: Array.isArray(_vm.is_public)
                                  ? _vm._i(_vm.is_public, null) > -1
                                  : _vm._q(_vm.is_public, 1),
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.is_public,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? 1 : 0
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.is_public = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.is_public = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.is_public = $$c
                                  }
                                },
                              },
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "swicth-label",
                                attrs: { for: "setPublic" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("is_public_content")) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "modal-footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button button--primary",
                          attrs: {
                            type: "button",
                            disabled: _vm.canNotEdit && !_vm.canEditOnlyItem,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.tabNavigation("mainTab")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tabPane === "journeyusedTab",
                        expression: "tabPane === 'journeyusedTab'",
                      },
                    ],
                    staticClass: "tab-pane",
                  },
                  [
                    _c("div", { staticClass: "form form--horizontal" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _vm.content_id && _vm.editMode
                            ? _c(
                                "div",
                                { staticClass: "table-responsive" },
                                [
                                  _c("div", { staticClass: "top-group" }, [
                                    _c(
                                      "div",
                                      [
                                        _c("filter-perpage", {
                                          on: {
                                            searchFor: _vm.setSearchFor,
                                            perPage: _vm.setPerPage,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm.tableIsLoading
                                    ? _c("div", {
                                        staticClass: "lds-dual-ring",
                                      })
                                    : _vm._e(),
                                  _c("vue-table", {
                                    ref: "myvuetable",
                                    class: { "table-fade": _vm.tableIsLoading },
                                    attrs: {
                                      "api-url": _vm.apiUrl,
                                      "http-fetch": _vm.myFetch,
                                      "api-mode": true,
                                      "pagination-path": "meta",
                                      "http-method": "get",
                                      "append-params": _vm.moreParams,
                                      "http-options": {
                                        headers: {
                                          Authorization: `Bearer ${_vm.$store.state.auth.token}`,
                                        },
                                      },
                                      fields: _vm.fields,
                                      "no-data-template":
                                        _vm.$t("no_data_info"),
                                      css: _vm.css.table,
                                      "per-page": _vm.perPage,
                                      "query-params": _vm.makeQueryParams,
                                    },
                                    on: {
                                      "vuetable:loading": function ($event) {
                                        _vm.tableIsLoading = true
                                      },
                                      "vuetable:loaded": function ($event) {
                                        _vm.tableIsLoading = false
                                      },
                                      "vuetable:load-error":
                                        _vm.vuetableLoadError,
                                      "vuetable:pagination-data":
                                        _vm.onPaginationData,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "id",
                                          fn: function (props) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-inline-block text-truncate",
                                                  staticStyle: {
                                                    "max-width": "180px",
                                                  },
                                                  attrs: {
                                                    title:
                                                      props.rowData.journey.id,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        props.rowData.journey.id
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "name",
                                          fn: function (props) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-inline-block text-truncate",
                                                  staticStyle: {
                                                    "max-width": "180px",
                                                  },
                                                  attrs: {
                                                    title:
                                                      props.rowData.journey
                                                        .name,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        props.rowData.journey
                                                          .name
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "activation_dt",
                                          fn: function (props) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.formatDate(
                                                        props.rowData
                                                          .activation_dt
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                        {
                                          key: "actions",
                                          fn: function (props) {
                                            return [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "ui button",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onAction(
                                                        "journey-detail",
                                                        props.rowData
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "icon-container",
                                                    {
                                                      attrs: {
                                                        name: "search",
                                                        width: 20,
                                                        height: 20,
                                                        color: "#727d92",
                                                        "view-box": "0 0 14 14",
                                                        "is-icon-class": false,
                                                      },
                                                    },
                                                    [_c("icon-search")],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1423054660
                                    ),
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "pagination__info" },
                                    [
                                      _c("vuetable-pagination-info", {
                                        ref: "paginationInfo",
                                        class: {
                                          "table-fade": _vm.tableIsLoading,
                                        },
                                        attrs: {
                                          "info-template":
                                            _vm.$t("pagination_info"),
                                          "no-data-template":
                                            _vm.$t("no_data_info"),
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "pagination__items" },
                                        [
                                          _c("vuetable-pagination", {
                                            ref: "pagination",
                                            attrs: { css: _vm.css.pagination },
                                            on: {
                                              "vuetable-pagination:change-page":
                                                _vm.onChangePage,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "modal-footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button button--primary",
                          attrs: {
                            type: "button",
                            disabled: _vm.canNotEdit && !_vm.canEditOnlyItem,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.tabNavigation("mainTab")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
        !_vm.isOnlyDatesEditable ||
        _vm.contentCreateValue === "file_download" ||
        _vm.contentCreateValue === "chapter"
          ? _c(
              "div",
              {
                staticClass: "modal__mobile-preview",
                class: { rotatepreview: _vm.showVideo },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "flag" },
                  [
                    _c(
                      "icon-container",
                      {
                        attrs: {
                          name: "flag",
                          width: 224,
                          height: 21,
                          "view-box": "0 0 224 21",
                          "is-icon-class": false,
                        },
                      },
                      [_c("icon-flag")],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "mobile-content",
                    class: {
                      "bluered-bg":
                        _vm.contentCreateValue === "podcast" ||
                        _vm.contentCreateValue === "file_download" ||
                        _vm.contentCreateValue === "checklist",
                      bgBlack:
                        _vm.contentCreateValue === "infographics" ||
                        _vm.contentCreateValue === "scorm" ||
                        _vm.contentCreateValue === "flipbook",
                    },
                    style: _vm.injectedArticleHtml ? "background:#ffffff;" : "",
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showVideo && _vm.showControl,
                            expression: "showVideo && showControl",
                          },
                        ],
                        staticClass: "videopausecontrol",
                      },
                      [
                        _c(
                          "button",
                          {
                            on: {
                              click: _vm.exitVideo,
                              mouseenter: function ($event) {
                                _vm.showControl = true
                              },
                            },
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticStyle: {
                                  fill: "#fff",
                                  "fill-opacity": "0.5",
                                },
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 24 24",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: { d: "M0 0h24v24H0z", fill: "none" },
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M12 10.6l5-4.9 1.4 1.4-4.9 5 5 5-1.4 1.4-4.9-4.9-4.9 5-1.4-1.4 5-4.9-4.9-4.9L7.1 5.6z",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm.contentCreateValue === "flipbook" &&
                    _vm.flipImages.length > 0
                      ? _c("flip-book", {
                          key: _vm.flipImages.length,
                          attrs: { images: _vm.flipImages },
                        })
                      : _vm._e(),
                    _vm.infographPreview &&
                    _vm.contentCreateValue === "infographics"
                      ? _c("div", { staticClass: "infograph-content" }, [
                          _c("img", {
                            attrs: {
                              src: _vm.infographPreview,
                              alt: "infographic",
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm.videoPreview &&
                    _vm.showVideo &&
                    _vm.contentCreateValue === "video"
                      ? _c("div", { staticClass: "videocontent" }, [
                          _c(
                            "video",
                            {
                              attrs: {
                                id: "videoElement",
                                controls: "",
                                playsinline: "",
                                "webkit-playsinline": "",
                                autoplay: "",
                                volume: "0.5",
                              },
                              on: {
                                mouseover: function ($event) {
                                  _vm.showControl = true
                                },
                                mouseleave: function ($event) {
                                  _vm.showControl = false
                                },
                                canplay: _vm.updatePaused,
                                playing: _vm.updatePaused,
                                pause: _vm.updatePaused,
                                ended: _vm.exitVideo,
                              },
                            },
                            [
                              _c("source", {
                                attrs: {
                                  src: _vm.videoPreview,
                                  type: "video/mp4",
                                },
                              }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("not_support_html5")) + " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.podcastPreview && _vm.contentCreateValue === "podcast"
                      ? _c("div", { staticClass: "podcastcontent" }, [
                          _c("div", { staticClass: "contentBox" }, [
                            _vm._m(0),
                            _c("div", { staticClass: "podcast-title" }, [
                              _vm._v(" " + _vm._s(_vm.name) + " "),
                            ]),
                            _vm.desc
                              ? _c("div", { staticClass: "podcast-desc" }, [
                                  _vm._v(" " + _vm._s(_vm.desc) + " "),
                                ])
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "controlBox" }, [
                            _c(
                              "div",
                              {
                                ref: "progressBar",
                                staticClass: "currentProgressBar",
                                on: { mousedown: _vm.podcastSeek },
                              },
                              [
                                _c("div", {
                                  staticClass: "currentProgress",
                                  style: {
                                    width: _vm.currentPodcastProgressBar + "%",
                                  },
                                }),
                              ]
                            ),
                            _c("div", { staticClass: "currentTimeContainer" }, [
                              _c("span", { staticClass: "currentTime" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("timeFormat")(_vm.currentPodcastTime)
                                  )
                                ),
                              ]),
                              _c("span", { staticClass: "totalTime" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("timeFormat")(_vm.podcastDuration)
                                    )
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "controlButtonsContainer" },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/podcast-backward.png"),
                                    alt: "Backward",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.backwardPodcast()
                                    },
                                  },
                                }),
                                _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: "slide-fade",
                                      mode: "out-in",
                                    },
                                  },
                                  [
                                    !_vm.podcastIsPlaying
                                      ? _c("img", {
                                          key: "play",
                                          attrs: {
                                            src: require("@/assets/img/podcast-play.png"),
                                            alt: "Play",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.togglePodcast()
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.podcastIsPlaying
                                      ? _c("img", {
                                          key: "pause",
                                          attrs: {
                                            src: require("@/assets/img/podcast-pause.png"),
                                            alt: "Pause",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.togglePodcast()
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/podcast-forward.png"),
                                    alt: "Forward",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.forwardPodcast()
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.contentCreateValue === "quiz" &&
                    _vm.displayquestions &&
                    _vm.displayquestions.length > 0
                      ? _c("div", { staticClass: "mobile-content__quiz" }, [
                          _c(
                            "div",
                            { staticClass: "quiz-preview--container" },
                            [
                              _vm._l(
                                _vm.displayquestions,
                                function (questionPreview, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "quiz-preview--question",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "quiz-preview--progress",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.displayquestions
                                                  ? parseInt(
                                                      (100 /
                                                        _vm.displayquestions
                                                          .length) *
                                                        (index + 1),
                                                      10
                                                    ) +
                                                      "% " +
                                                      _vm.$t("completed_")
                                                  : ""
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "quiz-progress-bar",
                                            },
                                            [
                                              _c("span", {
                                                style: {
                                                  width:
                                                    (100 /
                                                      _vm.displayquestions
                                                        .length) *
                                                      (index + 1) +
                                                    "%",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("h5", [
                                        _vm._v(
                                          _vm._s(questionPreview.question)
                                        ),
                                      ]),
                                      questionPreview.type === "standard" &&
                                      !(
                                        questionPreview.choices[0].image ||
                                        questionPreview.choices[0].imagePreview
                                      )
                                        ? _c("ul", [
                                            questionPreview.type ===
                                              "standard" &&
                                            !(
                                              questionPreview.choices[0]
                                                .image ||
                                              questionPreview.choices[0]
                                                .imagePreview
                                            )
                                              ? _c(
                                                  "ul",
                                                  _vm._l(
                                                    questionPreview.choices,
                                                    function (choice, scindex) {
                                                      return _c(
                                                        "li",
                                                        { key: scindex },
                                                        [
                                                          choice.choice_text
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "d-flex p-2",
                                                                  class: {
                                                                    "is-correct":
                                                                      choice.is_correct ==
                                                                      1,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "optiontick",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$helpers.choiceLetter(
                                                                              scindex
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        choice.choice_text
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                            questionPreview.type ===
                                              "standard" &&
                                            (questionPreview.choices[0].image ||
                                              questionPreview.choices[0]
                                                .imagePreview)
                                              ? _c(
                                                  "ul",
                                                  { staticClass: "row" },
                                                  _vm._l(
                                                    questionPreview.choices,
                                                    function (choice, ccindex) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key: ccindex,
                                                          staticClass:
                                                            "col-6 px-1",
                                                        },
                                                        [
                                                          choice.imagePreview ||
                                                          choice.image
                                                            ? _c(
                                                                "picture",
                                                                {
                                                                  staticClass:
                                                                    "d-flex flex-column",
                                                                  class: {
                                                                    "is-correct":
                                                                      choice.is_correct ==
                                                                      1,
                                                                  },
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src:
                                                                        choice.imagePreview ||
                                                                        choice.image,
                                                                      alt: "Choice Image",
                                                                    },
                                                                  }),
                                                                  choice.choice_text
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex p-2",
                                                                          staticStyle:
                                                                            {
                                                                              "mix-blend-mode":
                                                                                "difference",
                                                                              "font-size":
                                                                                "11px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                choice.choice_text
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                            questionPreview.type === "pairs"
                                              ? _c(
                                                  "ul",
                                                  {
                                                    staticClass: "row",
                                                    attrs: {
                                                      id: "pair-choices",
                                                    },
                                                  },
                                                  _vm._l(
                                                    questionPreview.choices,
                                                    function (choice, cindex) {
                                                      return _c(
                                                        "li",
                                                        { key: cindex },
                                                        [
                                                          choice.choice_text
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      choice.choice_text
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "quiz-preview--button",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "button button--secondary size-sm",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.previewQuestionNext(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.$t("next")) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                }
                              ),
                              _c(
                                "div",
                                { staticClass: "quiz-preview--question" },
                                [
                                  _c("div", { staticClass: "quiz-finalpage" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex final-score flex-column justify-content-center",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "score-point" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("score_point"))
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "your-score" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("your_score")) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "completed-text text-center mt-4",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("quiz_completed")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-center text-center mt-4",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("thanks_feedback")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "quiz-preview--button" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "button button--secondary size-sm",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.previewQuestionNext(-1)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("close")) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                    _vm.contentCreateValue === "checklist"
                      ? _c(
                          "div",
                          { staticClass: "mobile-content__checklist" },
                          [
                            _c("div", { staticClass: "title" }, [
                              _vm._v(" " + _vm._s(_vm.name) + " "),
                            ]),
                            _c(
                              "ul",
                              [
                                _vm._l(
                                  _vm.checklistItems,
                                  function (checklistItem, index) {
                                    return [
                                      checklistItem.item_text
                                        ? _c("li", { key: index }, [
                                            checklistItem.item_text
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      checklistItem.item_text
                                                    )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.contentCreateValue === "file_download"
                      ? _c(
                          "div",
                          { staticClass: "mobile-content__filedownload" },
                          [
                            _c("div", { staticClass: "title" }, [
                              _vm._v(" " + _vm._s(_vm.desc) + " "),
                            ]),
                            _c(
                              "ul",
                              { staticClass: "mt-4" },
                              [
                                _vm._l(
                                  _vm.fileDownloadItems,
                                  function (filedownloaditem, index) {
                                    return [
                                      filedownloaditem.title
                                        ? _c(
                                            "li",
                                            {
                                              key: index,
                                              staticClass: "filedownload-item",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openFileDownloadItem(
                                                    filedownloaditem
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "filedownload-item-content",
                                                },
                                                [
                                                  filedownloaditem.title
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "filedownload-item--title",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                filedownloaditem.title
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  filedownloaditem.desc
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "filedownload-item--desc",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                filedownloaditem.desc
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "filedownload-item--icon",
                                                },
                                                [
                                                  _c(
                                                    "icon-container",
                                                    {
                                                      staticClass: "icon-svg",
                                                      attrs: {
                                                        name: "downloadfull",
                                                        width: "13",
                                                        height: "14",
                                                        "is-icon-class": false,
                                                      },
                                                    },
                                                    [_c("icon-download")],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.contentCreateValue === "survey" &&
                    _vm.surveyQuestions &&
                    _vm.surveyQuestions.length > 0
                      ? _c("div", { staticClass: "mobile-content__survey" }, [
                          _c(
                            "div",
                            { staticClass: "survey-preview--container" },
                            [
                              _vm._l(
                                _vm.surveyQuestions,
                                function (surveyQuestion, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "survey-preview--question",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "question-content" },
                                        [
                                          _c("h5", [
                                            _vm._v(
                                              _vm._s(surveyQuestion.question)
                                            ),
                                          ]),
                                          _c("p", { staticClass: "subfont" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  surveyQuestion.sub_text
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          surveyQuestion.type ===
                                            "multiple-choice" ||
                                          surveyQuestion.type ===
                                            "single-choice"
                                            ? _c(
                                                "ul",
                                                {
                                                  class: {
                                                    haveImages:
                                                      _vm.$helpers.haveSurveyQuestionImages(
                                                        surveyQuestion.choices
                                                      ),
                                                  },
                                                },
                                                [
                                                  _vm._l(
                                                    surveyQuestion.choices,
                                                    function (choice, ssindex) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key: ssindex,
                                                          staticClass: "survey",
                                                        },
                                                        [
                                                          choice.imagePreview ||
                                                          choice.image
                                                            ? _c(
                                                                "picture",
                                                                {
                                                                  staticClass:
                                                                    "d-flex flex-column",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex flex-row",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "optiontick",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$helpers.choiceLetter(
                                                                                  ssindex
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              src:
                                                                                choice.imagePreview ||
                                                                                choice.image,
                                                                              alt: "Choice Image",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "d-flex p-2",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "optiontick",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$helpers.choiceLetter(
                                                                              ssindex
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        choice.choice_text
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  surveyQuestion &&
                                                  surveyQuestion.options &&
                                                  surveyQuestion.options
                                                    .has_other_option
                                                    ? _c(
                                                        "li",
                                                        {
                                                          key: `other${surveyQuestion.id}`,
                                                          staticClass: "survey",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "d-flex p-2",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "optiontick",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$helpers.choiceLetter(
                                                                        surveyQuestion
                                                                          .choices
                                                                          .length
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "other"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                          surveyQuestion.type === "text"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "survey-textarea",
                                                },
                                                [
                                                  _c("textarea", {
                                                    attrs: {
                                                      name: "",
                                                      id: "",
                                                      placeholder:
                                                        _vm.$t("type_here"),
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          surveyQuestion.type === "rating"
                                            ? _c(
                                                "div",
                                                [
                                                  surveyQuestion.options
                                                    .shape === "star"
                                                    ? [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "rating-box",
                                                          },
                                                          [
                                                            _vm._l(
                                                              Number(
                                                                surveyQuestion
                                                                  .options.step
                                                              ),
                                                              function (
                                                                sindex
                                                              ) {
                                                                return [
                                                                  true
                                                                    ? _c(
                                                                        "img",
                                                                        {
                                                                          key: `star${index}${sindex}`,
                                                                          attrs:
                                                                            {
                                                                              src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA5CAYAAABj2ui7AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAM3SURBVHgB3VqBdeIwDBX3bgA2uIzABucNSidoNigbwE1Ab4LABu0EoRPQDdIN6AaqUuQXxYTEEMsJ/Pf8BLZjWfa3ZSsBGACIOIVI+AXDYAH3Cpq9OaUDRMIQM/hAaUpGziAChjDQOPJ+QLNmsEIO9wYyKsM61HfT2BQ1LL+c/2qIZiBvKgmlT0r/OdvAvYAMfGFaZmItRnMX6iBj9myU4f8H/h/FXaiCjEjcGaPfG85TPdXEWoNzlq8ib8fyAW4dpc/j2ZqLvGlMd6EGQc8TQ5oMD40YFDUs3yaTyZdT9ubUuT2IWUobymY37S6cdZacqaPqLrQpatfWjuj5eabOlqUBBWgb+JfltqXOjuXtuQtBv6Slzm26C3He3HvUzbXcxW9WkJJYQljY2dh61C3dhaGUUV/WoIHSSEoFhkfn7oiVuwiJcnnMJ46ihEQZSkg4a0PpH/RAy+4JDbr7oDy0P/PvD0qPZ3WTspUYiQxHvPjxuEllor8vvg+mWO2CRYARDg6mdiEomV7yvD0oF2J0RhORpr48iwnY95oArMINg1O2iZJB+jMGyjqMupySVyiIRlmHknoDzBSRlF1rUpb1rYNT0kPxQntEh2TMuQ6kEAjU1hOOwU01UDaFnsDjO0SLDY7hoCFG20BPYHUTKWAMwIbAboA27YD9gR4IdaM3LN8hHGzE7RF6IJSBTyxfIRx2LIcPZYgNIYFAwCqUcRh0k8ELQhNXtJ333bhCUNTS02v9sQvIPGfbtqkW2u+EcPTGo648chVdRg7uLoR7KDzq5Y5xdn0tOp4N4i6uAh6vUCWyljoG60cuw/m1A3vL8xuuE/+yjS0vVrh8KYzI8fT12UKUN97KxSDG/65GdC5x8l1KLlrakAf2Us6c8mHcBVYbQN6QX7iU7GjLHZClU27LOtsKBqziIiuRt8Q6JRO4AHgmXCny/UKCISBmyTCNctG5FVwJ5M8tBQMSjO0uULiHayjp2b5t8+cijTHdBVY72wF7ULJDR8mKjWjf6tJ3F1ino+rawPq6/BlI0IYzqgaUgachej13IRZ8HpKSHnrLdVn73k1LUYYxt+tT/StV/TiCrwPxglfd33vRYRyPRxhZAAAAAElFTkSuQmCC",
                                                                              alt: "star",
                                                                            },
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                  surveyQuestion.options
                                                    .shape === "thumb" ||
                                                  surveyQuestion.options
                                                    .shape === "thumbsup"
                                                    ? [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "rating-box",
                                                          },
                                                          [
                                                            _vm._l(
                                                              Number(
                                                                surveyQuestion
                                                                  .options.step
                                                              ),
                                                              function (
                                                                tindex
                                                              ) {
                                                                return [
                                                                  true
                                                                    ? _c(
                                                                        "img",
                                                                        {
                                                                          key: `thumb${index}${tindex}`,
                                                                          attrs:
                                                                            {
                                                                              src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALtSURBVHgB1VrbddswDIV6+h+PwA3qDaJu4E5gd4K6E0SdoO4EdiaIO4G1gesJ7A2cDVBCAiMI1suJHuQ9hw5JUQouCQIgJICJgYhzWw6Y42rL1hYDIYEEZuE1zkGREStBODIBSWYGvoNXw2Ev+l9EfwK+wwq5FgIvRP9MqNsZfIdSq5m6thPX5l2e9wkmAAseczONouhVDUlF3V8iFgtR/1tx/Z+oG+iAqYg8ifoeesDoRKxaEQnDzZ1Vq0vFMLlnXsE3WBJLsYmvdU6vzqJ5AUWCsG4YK32JAV9A6qRIJA1jpaM8gi+4hwSP34qxK/ABVpDfisSqZbxcDT+8uloJ2tjzlvEUmpy9Wg28Dc9bvbNSqS34ACVU0mF8LFUKfbBUVojFvXquiMfwDkTQM1h4w81v1nO3hiD2nhco4q+0ZTh5eorP0pqo4ONQG7yzntPGxvtxxiEMApZNZ2340ULGHXebisYT9An1TxIYCDxhiSITQx/AskqN4siwrI4HeA94VlxZqtkxMBJQ5MM+i84Y8mOlTsGQldgrC1E3678GsyTVcGeVXGa8DeywaVPVXN/ByJArEmFuwrbM7hluT2TGliXXf9oZ39Cd3P5jy4YqI68EsAo7zUip48isVg03LeSmEquQwEQQcueyu1rLTTM5zhMiDldqd0o+VOSdfEDKf2mSV1Olg/rAd1FfBEuEjcuFm19CXhGC83mXYImwX3NETp2sFt/ojdXC/HWdhAl1RWT2MQuLQiUis/WZegVJhI/PzrcZ+gnZarmN/kA/QRJRceHJdQZltbCcbsosFvWHuCI/RP3tIBcikZOov+XMQiRS+aKUiGRmDBs+l8AioaDD+QcYH4+iXsiDxbn3wK7fqEJ97jS25XskzjhS5oRlqfwqImIh6AhrWp5zseUrba4aK5dCEVYPgTmUPx6gjZ64RpbEZjLU+VjzEEoab5yFwCKvG0PHF/o949nKsoK+gPlZfo3lRMBQIJU6YE2S5D/iNRDHqdhCtAAAAABJRU5ErkJggg==",
                                                                              alt: "thumb",
                                                                            },
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                  surveyQuestion.options
                                                    .shape === "number"
                                                    ? [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-block",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "rating-number-box",
                                                              },
                                                              _vm._l(
                                                                Number(
                                                                  surveyQuestion
                                                                    .options
                                                                    .step
                                                                ) + 1,
                                                                function (
                                                                  notusedindex,
                                                                  nindex
                                                                ) {
                                                                  return _c(
                                                                    "span",
                                                                    {
                                                                      key: `mum${nindex}`,
                                                                      staticClass:
                                                                        "number-cell",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            nindex
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "rating-number-info",
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      surveyQuestion
                                                                        .options
                                                                        .min_value
                                                                    )
                                                                  ),
                                                                ]),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      surveyQuestion
                                                                        .options
                                                                        .max_value
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "survey-preview--button",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "button button--secondary size-sm",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.previewQuestionNext(
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.$t("next")) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "survey-preview--progress",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.surveyQuestions
                                                  ? parseInt(
                                                      (100 /
                                                        _vm.surveyQuestions
                                                          .length) *
                                                        (index + 1),
                                                      10
                                                    ) +
                                                      "% " +
                                                      _vm.$t("completed_")
                                                  : ""
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "survey-progress-bar",
                                            },
                                            [
                                              _c("span", {
                                                style: {
                                                  width: _vm.surveyQuestions
                                                    ? parseInt(
                                                        (100 /
                                                          _vm.surveyQuestions
                                                            .length) *
                                                          (index + 1),
                                                        10
                                                      ) + "%"
                                                    : "100%",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _c(
                                "div",
                                { staticClass: "survey-preview--question" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "survey-finalpage" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-center text-center mt-4",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("thanks_feedback")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "survey-preview--button",
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "button button--secondary size-sm",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.previewQuestionNext(
                                                    -1
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("close")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                    _vm.contentCreateValue === "request" &&
                    _vm.requestQuestions &&
                    _vm.requestQuestions.length > 0
                      ? _c("div", { staticClass: "mobile-content__request" }, [
                          _c(
                            "div",
                            { staticClass: "request-preview--container" },
                            [
                              _vm._l(
                                _vm.requestQuestions,
                                function (requestQuestion, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "request-preview--question",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "question-content" },
                                        [
                                          _c("h5", [
                                            _vm._v(
                                              _vm._s(
                                                `${index + 1}. ` +
                                                  requestQuestion.question
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "p",
                                            { staticClass: "subfont ml-3" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    requestQuestion.sub_text
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          requestQuestion.type === "get_info"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "request-info-input ml-2",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.convertFormat(
                                                          requestQuestion
                                                            .options.format
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                          requestQuestion.type === "image" ||
                                          requestQuestion.type === "file"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "imagefile-box",
                                                },
                                                [
                                                  _c("span", {
                                                    domProps: {
                                                      innerHTML: _vm._s("+"),
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "request-preview--button",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "button button--secondary size-sm",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.previewQuestionNext(
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.$t("next")) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "request-preview--progress",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.requestQuestions
                                                  ? parseInt(
                                                      (100 /
                                                        _vm.requestQuestions
                                                          .length) *
                                                        (index + 1),
                                                      10
                                                    ) +
                                                      "% " +
                                                      _vm.$t("completed_")
                                                  : ""
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "request-progress-bar",
                                            },
                                            [
                                              _c("span", {
                                                style: {
                                                  width: _vm.requestQuestions
                                                    ? parseInt(
                                                        (100 /
                                                          _vm.requestQuestions
                                                            .length) *
                                                          (index + 1),
                                                        10
                                                      ) + "%"
                                                    : "100%",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _c(
                                "div",
                                { staticClass: "request-preview--question" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "request-finalpage" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-center text-center mt-4",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("thanks_feedback_info")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "request-preview--button",
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "button button--secondary size-sm",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.previewQuestionNext(
                                                    -1
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("close")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                    _vm.contentCreateValue !== "podcast" &&
                    _vm.contentCreateValue !== "file_download" &&
                    _vm.contentCreateValue !== "checklist" &&
                    _vm.contentCreateValue !== "chapter" &&
                    _vm.contentCreateValue !== "flipbook"
                      ? _c(
                          "div",
                          {
                            staticClass: "mobile-context",
                            style: _vm.showVideo ? "z-index: -2" : "",
                          },
                          [
                            _vm.thumbnailPreview &&
                            _vm.contentCreateValue !== "infographics" &&
                            _vm.contentCreateValue !== "podcast" &&
                            _vm.contentCreateValue !== "flipbook" &&
                            _vm.contentCreateValue !== "notification" &&
                            _vm.contentCreateValue !== "chapter"
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.showVideo,
                                        expression: "!showVideo",
                                      },
                                    ],
                                    staticClass: "mobile-content__media",
                                    style: {
                                      "background-image":
                                        "url(" + _vm.thumbnailPreview + ")",
                                    },
                                  },
                                  [
                                    _vm.videoPreview
                                      ? _c(
                                          "div",
                                          { staticClass: "videocontrols" },
                                          [
                                            _c(
                                              "button",
                                              { on: { click: _vm.play } },
                                              [
                                                _c(
                                                  "icon-container",
                                                  {
                                                    staticClass: "pt-3 pl-3",
                                                    attrs: {
                                                      name: "playfull",
                                                      "view-box": "0 0 538 615",
                                                      color:
                                                        "rgba(255, 255, 255, 0.5)",
                                                      "is-icon-class": false,
                                                    },
                                                  },
                                                  [_c("icon-playfull")],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.contentCreateValue !== "infographics" &&
                                    _vm.contentCreateValue !== "flipbook" &&
                                    !_vm.is_info_labels_hidden
                                      ? _c(
                                          "small",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.showVideo,
                                                expression: "!showVideo",
                                              },
                                            ],
                                            staticClass: "mobile-content__info",
                                          },
                                          [_vm._v(" " + _vm._s(_vm.info) + " ")]
                                        )
                                      : _vm._e(),
                                    _vm.contentCreateValue ===
                                      "external-link" ||
                                    _vm.contentCreateValue === "link" ||
                                    _vm.contentCreateValue === "pdf_upload"
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "mobile-content__externallink",
                                            on: {
                                              click: function ($event) {
                                                return _vm.openExternalLink()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("open_button")) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "small",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.flipImages.length === 0,
                                    expression: "flipImages.length === 0",
                                  },
                                ],
                                staticClass: "mobile-content__type",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$helpers.getSubTypeName(
                                        _vm.contentCreateValue
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _vm.contentCreateValue !== "infographics" &&
                            _vm.contentCreateValue !== "flipbook" &&
                            _vm.contentCreateValue !== "chapter" &&
                            !_vm.is_info_labels_hidden
                              ? _c(
                                  "h2",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.showVideo,
                                        expression: "!showVideo",
                                      },
                                    ],
                                    staticClass: "mobile-content__title",
                                  },
                                  [_vm._v(" " + _vm._s(_vm.name) + " ")]
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    _vm.contentCreateValue !== "infographics" &&
                    _vm.contentCreateValue !== "article" &&
                    _vm.contentCreateValue !== "podcast" &&
                    _vm.contentCreateValue !== "chapter" &&
                    _vm.contentCreateValue !== "checklist" &&
                    _vm.contentCreateValue !== "file_download" &&
                    _vm.contentCreateValue !== "flipbook" &&
                    _vm.contentCreateValue !== "scorm"
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.showVideo,
                                expression: "!showVideo",
                              },
                            ],
                            staticClass: "mobile-content__main",
                            style: _vm.showVideo ? "z-index: -2" : "",
                          },
                          [_c("p", [_vm._v(_vm._s(_vm.desc))])]
                        )
                      : _vm._e(),
                    _vm.contentCreateValue === "article"
                      ? _c("div", { staticClass: "mobile-content__main" }, [
                          _vm.injectedArticleHtml
                            ? _c("iframe", {
                                directives: [
                                  { name: "resize", rawName: "v-resize" },
                                ],
                                attrs: {
                                  title: "article",
                                  id: "articleIframe",
                                  width: "270",
                                  srcdoc: _vm.injectedArticleHtml,
                                },
                              })
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm.contentCreateValue === "chapter"
                      ? _c("div", { staticClass: "chapter-content-preview" }, [
                          _c(
                            "div",
                            { staticClass: "chapter-content-preview__title" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.name) +
                                  " " +
                                  _vm._s(_vm.chapterIndex === 1 ? "🏁" : "🚀") +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "chapter-content-preview__description",
                            },
                            [_vm._v(" " + _vm._s(_vm.desc) + " ")]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "podcast-icon" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/podcast-mic.png"), alt: "podcast" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }