<template>
  <div
    class="modal-container"
  >
    <div
      class="modal"
      :class="{'is-loading': formSending }"
      tabindex="-1"
    >
      <div :class="['modal-dialog modal-dialog-scrollable', tabPane === 'startTab' ? 'calender-tab' : '']">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">
              {{ journeyHaveUsers ? $t('assign_journey') : $t('start_journey') }}
            </h5>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>
          <nav class="navigation navigation--secondary">
            <div class="container">
              <ul
                class="navigation__list nav"
                id="startJourneyTab"
                role="tablist"
              >
                <li class="navigation__item">
                  <button
                    class="navigation__link"
                    :class="{ 'active': tabPane === 'startTab'}"
                    @click="tabNavigation('startTab')"
                  >
                    {{ $t('schedule') }}
                  </button>
                </li>
                <li class="navigation__item">
                  <button
                    class="navigation__link"
                    :class="{ 'active': tabPane === 'advancedSettingsTab'}"
                    @click="tabNavigation('advancedSettingsTab')"
                  >
                    {{ $t('assign_lowase') }}
                  </button>
                </li>
              </ul>
            </div>
          </nav>

          <div class="modal-body">
            <div class="tab-content">
              <div
                class="tab-pane"
                v-show="tabPane === 'startTab'"
              >
                <div class="row">
                  <div class="col-4 bglight">
                    <h5 class="modal-title schedule-title">
                      {{ $t('letsschedule') }}
                    </h5>
                    <div class="row mx-0">
                      <div class="col-6">
                        <div class="form-group form-group--light u-mB-0 u-zIndex-3">
                          <label
                            for="journeyLanguage"
                            class="form-label"
                          >{{ $t('begin_date') }}</label>
                          <date-picker
                            :class="{ 'is-danger': errors.has('assign.beginDate') }"
                            v-model="beginActivationDateTime"
                            :disabled-date.sync="notBeforeToday"
                            value-type="date"
                            data-vv-scope="assign"
                            data-vv-name="beginDate"
                            :data-vv-as="$t('begin_date')"
                            v-validate="'required'"
                            :first-day-of-week="userLang === 'en' ? 7 : 1"
                            :lang="userLang"
                            :format="userLang === 'en' ? 'MM.DD.YYYY' : 'DD.MM.YYYY'"
                            @change="adjustTime($event,'date')"
                          />
                          <span
                            v-if="errors.has('assign.beginDate')"
                            class="help is-danger"
                          >{{ errors.first('assign.beginDate') }}</span>
                        </div>
                      </div>
                      <div
                        class="col-6"
                        v-show="!isOnboarding && journeyNotificationSelect !== 'scheduled'"
                      >
                        <div class="form-group form-group--light u-mB-0 u-zIndex-3">
                          <label
                            for="journeyLanguage"
                            class="form-label"
                          >{{ $t('begin_time') }}</label>
                          <date-picker
                            :class="{ 'is-danger': errors.has('assign.beginTime') }"
                            v-model="beginActivationDateTime"
                            :disabled-time.sync="notBeforeTime"
                            data-vv-scope="assign"
                            data-vv-name="beginTime"
                            :data-vv-as="$t('begin_time')"
                            v-validate="'required'"
                            value-type="date"
                            type="time"
                            :lang="userLang"
                            :format="userLang === 'en' ? 'hh:mm A' : 'HH:mm'"
                            :time-picker-options="{ start: '08:00', step: '00:15', end: '23:45', format: userLang === 'en' ? 'hh:mm A' : 'HH:mm' }"
                            @change="adjustTime($event,'time')"
                          >
                            <template slot="icon-calendar">
                              <icon-container
                                name="time"
                                view-box="0 0 512 512"
                              >
                                <icon-time />
                              </icon-container>
                            </template>
                          </date-picker>
                          <span
                            v-if="errors.has('assign.beginTime')"
                            class="help is-danger"
                          >{{ errors.first('assign.beginTime') }}</span>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="swicth-checkbox">
                          <input
                            type="checkbox"
                            id="customizeDeliveryDate"
                            checked
                            v-model="customizeDeliveryDate"
                            :true-value="1"
                            :false-value="0"
                          >
                          <label
                            class="swicth-label"
                            for="customizeDeliveryDate"
                          >
                            {{ $t('customize_delivery_date') }}
                          </label>
                        </div>
                      </div>
                      <collapse-transition
                        :duration="500"
                        dimension="height"
                      >
                        <div
                          class="col-12"
                          v-show="customizeDeliveryDate"
                        >
                          <div class="form-group form-group--light">
                            <div class="row">
                              <div class="col-6 p-2">
                                <div class="checkbox">
                                  <input
                                    type="radio"
                                    name="journeyNotificationSelect"
                                    id="scheduled"
                                    v-model="journeyNotificationSelect"
                                    value="scheduled"
                                    @change="changeNotificationChange('scheduled')"
                                  >
                                  <label for="scheduled">{{ $t('scheduled') }}</label>
                                </div>
                              </div>
                              <div class="col-6 p-2">
                                <div class="checkbox">
                                  <input
                                    type="radio"
                                    name="journeyNotificationSelect"
                                    id="twiceAWeek"
                                    v-model="journeyNotificationSelect"
                                    value="twiceAWeek"
                                    @change="changeNotificationChange('twiceAWeek')"
                                  >
                                  <label for="twiceAWeek">{{ $t('twice_aweek') }}</label>
                                </div>
                              </div>
                              <div class="col-6 p-2">
                                <div class="checkbox">
                                  <input
                                    type="radio"
                                    name="journeyNotificationSelect"
                                    id="thriceAWeek"
                                    v-model="journeyNotificationSelect"
                                    value="thriceAWeek"
                                    @change="changeNotificationChange('thriceAWeek')"
                                  >
                                  <label for="thriceAWeek">{{ $t('thrice_aweek') }}</label>
                                </div>
                              </div>
                              <div class="col-6 p-2">
                                <div class="checkbox">
                                  <input
                                    type="radio"
                                    name="journeyNotificationSelect"
                                    id="spaced"
                                    v-model="journeyNotificationSelect"
                                    value="spaced"
                                    @change="changeNotificationChange('spaced')"
                                  >
                                  <label for="spaced">{{ $t('spaced') }}</label>
                                </div>
                              </div>
                              <div
                                v-show="journeyNotificationSelect === 'custom'"
                                class="col-6 p-2"
                              >
                                <div class="checkbox">
                                  <input
                                    type="radio"
                                    name="journeyNotificationSelect"
                                    id="custom"
                                    v-model="journeyNotificationSelect"
                                    value="custom"
                                  >
                                  <label for="custom">{{ $t('custom') }}</label>
                                </div>
                              </div>
                            </div>
                            <div
                              class="form form--horizontal mt-2"
                              v-show="journeyNotificationSelect === 'scheduled'"
                            >
                              <div
                                :class="['panel-title', showAdvanceSettings2 ? 'active': '']"
                              >
                                <label @click="showAdvanceSettings2 = !showAdvanceSettings2">{{ $t('advanced_tab') }}</label>
                              </div>
                              <collapse-transition
                                :duration="500"
                                dimension="height"
                              >
                                <div
                                  v-show="showAdvanceSettings2"
                                >
                                  <div class="col-12">
                                    <div class="swicth-checkbox">
                                      <input
                                        type="checkbox"
                                        id="sendOnSaturday"
                                        v-model="sendOnSaturday"
                                        :true-value="1"
                                        :false-value="0"
                                        @change="changeNotificationChange('scheduled')"
                                      >
                                      <label
                                        class="swicth-label"
                                        for="sendOnSaturday"
                                      >{{ $t('send_onsaturday') }}</label>
                                    </div>
                                  </div>
                                  <div class="col-12">
                                    <div class="swicth-checkbox">
                                      <input
                                        type="checkbox"
                                        id="sendOnSunday"
                                        v-model="sendOnSunday"
                                        @change="changeNotificationChange('scheduled')"
                                        :true-value="1"
                                        :false-value="0"
                                      >
                                      <label
                                        class="swicth-label"
                                        for="sendOnSunday"
                                      >{{ $t('send_onsunday') }}</label>
                                    </div>
                                  </div>
                                  <div class="col-12 mt-4">
                                    <div
                                      class="form-group"
                                    >
                                      <label
                                        for="excludedDays"
                                        class="form-label"
                                      >{{ $t('excluded_days') }}</label>
                                      <flat-pickr
                                        v-model="excludedDaysAsString"
                                        :config="excludedDaysConfig"
                                        class="form-control"
                                        :placeholder="$t('select_dates')"
                                        name="excludedDays"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </collapse-transition>
                            </div>
                            <div
                              class="row"
                              v-if="journeyNotificationSelect === 'spaced'"
                            >
                              <div class="col-5">
                                <div>
                                  <label class="form-label">{{ $t('send_every') }}</label>
                                </div>
                                <div class="d-flex justify-content-start mb-4">
                                  <input
                                    class="h-40"
                                    :class="{ 'is-danger': errors.has('assign.spacedValue') }"
                                    type="number"
                                    v-model="spacedValue"
                                    id="spacedValue"
                                    name="spacedValue"
                                    min="1"
                                    :max="maxSpacedValue"
                                    data-vv-scope="assign"
                                    data-vv-name="spacedValue"
                                    :data-vv-as="$t('spaced')"
                                    v-validate.immediate="`required|numeric|decimal:0|min_value: 1|max_value: ${maxSpacedValue}`"
                                  >
                                  <multi-select
                                    track-by="id"
                                    label="text"
                                    clear-on-select
                                    :allow-empty="false"
                                    :searchable="false"
                                    :show-labels="false"
                                    v-model="selectSpace"
                                    :options="spaceOptions"
                                    class="selectbox selectbox--secondary"
                                  />
                                </div>
                              </div>
                              <div class="col-7 d-flex align-items-center">
                                <div class="swicth-checkbox">
                                  <input
                                    type="checkbox"
                                    id="includeWeekend"
                                    v-model="is_weekend_included"
                                    :true-value="1"
                                    :false-value="0"
                                  >
                                  <label
                                    class="swicth-label right"
                                    for="includeWeekend"
                                  >
                                    {{ $t('include_weekend') }}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </collapse-transition>
                    </div>
                    <div class="row mx-0">
                      <div class="col-auto">
                        <div class="swicth-checkbox">
                          <input
                            type="checkbox"
                            name="hasReminder"
                            id="hasReminder"
                            checked
                            v-model="hasReminder"
                            :true-value="true"
                            :false-value="false"
                          >
                          <label
                            class="swicth-label"
                            for="hasReminder"
                          >
                            {{ $t('hasReminder') }}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <collapse-transition
                        :duration="500"
                        dimension="height"
                      >
                        <div
                          class="container mt-5"
                          v-show="hasReminder"
                        >
                          <div class="row">
                            <div class="form-group row">
                              <label
                                for="employee_title"
                                class="col-4 form-label"
                              >
                                {{ $t('employee_title') }}
                              </label>
                              <div class="col-8">
                                <input
                                  :class="{ 'is-danger': errors.has('journey.employee_title') }"
                                  type="text"
                                  class="form-control"
                                  id="employee_title"
                                  v-model="employee_title"
                                  data-vv-name="employee_title"
                                  data-vv-scope="journey"
                                  :data-vv-as="$t('employee_title')"
                                  v-validate="hasReminder ? 'required|max:40':''"
                                ><!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                                <span
                                  v-if="errors.has('journey.employee_title')"
                                  class="help is-danger"
                                >{{ errors.first('journey.employee_title') }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                for="employee_content"
                                class="col-4 form-label"
                              >
                                {{ $t('employee_content') }}
                              </label>
                              <div class="col-8">
                                <textarea
                                  :class="{ 'is-danger': errors.has('journey.employee_content') }"
                                  id="employee_content"
                                  class="form-control"
                                  v-model="employee_content"
                                  data-vv-name="employee_content"
                                  data-vv-scope="journey"
                                  :data-vv-as="$t('employee_content')"
                                  v-validate="hasReminder ? 'required|max:160':''"
                                /><!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                                <span
                                  v-if="errors.has('journey.employee_content')"
                                  class="help is-danger"
                                >{{ errors.first('journey.employee_content') }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                for="weeks_after_last_content"
                                class="col-4 form-label"
                              >
                                {{ $t('weeks_after_last_content') }}
                              </label>
                              <div class="col-8">
                                <select
                                  class="selectbox-o"
                                  id="weeks_after_last_content"
                                  v-model="weeks_after_last_content"
                                >
                                  <option
                                    v-for="n in [1,2,3,4,5,6,7,8,9]"
                                    :key="n"
                                    :value="n"
                                  >
                                    {{ n }}
                                  </option>
                                </select>
                                <span class="form-label mx-2">{{ $t('week') }}</span>
                                <svg-icon
                                  v-tooltip.top="$t('weeks_after_last_content_info')"
                                  type="mdi"
                                  class="form-label"
                                  :path="iconInformationOutline"
                                />
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                for="week_span"
                                class="col-4 form-label"
                              >
                                {{ $t('week_span') }}
                              </label>
                              <div class="col-8">
                                <select
                                  class="selectbox-o"
                                  id="week_span"
                                  v-model="week_span"
                                >
                                  <option
                                    v-for="n in [1,2,3,4]"
                                    :key="n"
                                    :value="n"
                                  >
                                    {{ n }}
                                  </option>
                                </select>
                                <span class="form-label mx-2">{{ $t('week') }}</span>
                                <svg-icon
                                  v-tooltip.top="$t('week_span_info')"
                                  type="mdi"
                                  class="form-label"
                                  :path="iconInformationOutline"
                                />
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                for="day_of_week"
                                class="col-4 form-label"
                              >
                                {{ $t('day_of_week') }}
                              </label>
                              <div class="col-8">
                                <select
                                  class="selectbox-o"
                                  id="day_of_week"
                                  v-model="day_of_week"
                                >
                                  <option
                                    v-for="n in [1,2,3,4,5,6,7]"
                                    :key="n"
                                    :value="n"
                                  >
                                    {{ $helpers.getWeekDay(n) }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </collapse-transition>
                    </div>
                    <div class="schedule-next">
                      <div class="row">
                        <div class="col pre-wrap">
                          <small v-if="previewLastDate !== 'Invalid date'">{{ $t('will_end') + '\n' }}<strong>{{ previewLastDate }}</strong></small>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="button button--primary"
                          @click="tabNavigation('advancedSettingsTab')"
                        >
                          {{ $t('next') }}
                        </button>
                        <transition name="waterfall">
                          <div
                            class="alert--error assignment-final-msg-box border card__title help is-danger p-4 waterfall-enter-active"
                            v-if="previewContentCount === 0"
                          >
                            {{ $t('no_journey_items_to_be_assigned') }}
                          </div>
                        </transition>
                      </div>
                    </div>
                  </div>
                  <div class="col-8">
                    <full-calendar
                      :user-lang="userLang"
                      :calender-events="calenderEvents"
                      :preview-first-date="previewFirstDate"
                      :total-event-days="totalEventDays"
                      @setDateFromDrop="setDateFromDrop"
                      @isDragging="setDragging"
                    />
                  </div>
                </div>
              </div>

              <div
                class="tab-pane"
                v-if="tabPane === 'advancedSettingsTab'"
              >
                <h5 class="modal-title schedule-title">
                  {{ $t('towhomassign') }}
                </h5>
                <div class="form-group px-4 pb-4">
                  <label
                    for="contentTitle"
                    class="form-label form-label--small"
                  >{{ $t('to') }}</label>
                  <vue-tags-input
                    :class="{ 'is-danger': errors.has('assign.assignTo') }"
                    :placeholder="$t('add_name_placeholder')"
                    v-model="tag"
                    :tags="tags"
                    :add-only-from-autocomplete="true"
                    :autocomplete-items="autocompleteItems"
                    @tags-changed="update"
                  />
                  <input
                    type="hidden"
                    data-vv-scope="assign"
                    data-vv-name="assignTo"
                    :data-vv-as="$t('to')"
                    :value="assignTo"
                    v-validate="'required'"
                  >
                  <span
                    v-if="errors.has('assign.assignTo')"
                    class="help is-danger"
                  >{{ errors.first('assign.assignTo') }}</span>
                </div>
                <div class="form form--horizontal">
                  <div
                    :class="['panel-title', showAdvanceSettings ? 'active': '']"
                  >
                    <label @click="showAdvanceSettings = !showAdvanceSettings">{{ $t('advanced_tab') }}</label>
                  </div>
                  <collapse-transition
                    :duration="500"
                    dimension="height"
                  >
                    <div
                      class="px-4 bglight"
                      v-show="showAdvanceSettings"
                    >
                      <div class="row mx-4 bglight">
                        <div class="col">
                          <div class="swicth-checkbox">
                            <input
                              type="checkbox"
                              id="openedDays"
                              checked
                              v-model="is_welcome_notifications_enabled"
                              :true-value="1"
                              :false-value="0"
                            >
                            <label
                              class="swicth-label"
                              for="openedDays"
                            >
                              {{ $t('send_welcome_email') }}
                            </label>
                          </div>
                        </div>
                      </div>
                      <collapse-transition
                        :duration="500"
                        dimension="height"
                      >
                        <div
                          v-show="is_welcome_notifications_enabled"
                          class="row mx-4"
                        >
                          <div class="form-group row">
                            <label
                              for="pushNotificationHeading"
                              class="col-md-5 form-label"
                            >
                              {{ $t('push_noti_head') }}
                            </label>
                            <div class="col-md-7">
                              <input
                                :class="{ 'is-danger': errors.has('assign.push_notification_title') }"
                                type="text"
                                class="form-control"
                                id="pushNotificationHeading"
                                v-model="push_notification_title"
                                data-vv-name="push_notification_title"
                                data-vv-scope="assign"
                                :data-vv-as="$t('push_noti_head')"
                                v-validate="'max:40'"
                              ><!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                              <button
                                class="emoji-button"
                                @click="dropdownToggle('title')"
                                v-html="'☺'"
                              />
                              <div
                                class="dropdown"
                                style="z-index: 1000001"
                                v-if="dropdownIndex === 'title'"
                                v-on-clickaway="hideDropdown"
                              >
                                <div
                                  class="dropdown__menu emoji-dropdown-title"
                                  ref="dropdownBoxtitle"
                                >
                                  <VEmojiPicker
                                    class="emoji"
                                    :pack="emojiPack"
                                    :label-search="$t('search_placeholder')"
                                    @select="SelectEmojiForTitle"
                                  />
                                </div>
                              </div>
                              <span
                                v-if="errors.has('assign.push_notification_title')"
                                class="help is-danger"
                              >{{ errors.first('assign.push_notification_title') }}</span>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label
                              for="pushNotificationBody"
                              class="col-md-5 form-label"
                            >
                              {{ $t('push_noti_body') }}
                            </label>
                            <div class="col-md-7">
                              <textarea
                                :class="{ 'is-danger': errors.has('assign.push_notification_body') }"
                                id="pushNotificationBody"
                                class="form-control"
                                v-model="push_notification_body"
                                data-vv-name="push_notification_body"
                                data-vv-scope="assign"
                                :data-vv-as="$t('push_noti_body')"
                                v-validate="'max:110'"
                              /><!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                              <button
                                class="emoji-button"
                                @click="dropdownToggle('body')"
                                v-html="'☺'"
                              />
                              <div
                                class="dropdown"
                                style="z-index: 1000001"
                                v-if="dropdownIndex === 'body'"
                                v-on-clickaway="hideDropdown"
                              >
                                <div
                                  class="dropdown__menu emoji-dropdown-body"
                                  ref="dropdownBoxbody"
                                >
                                  <VEmojiPicker
                                    class="emoji"
                                    :pack="emojiPack"
                                    :label-search="$t('search_placeholder')"
                                    @select="SelectEmojiForBody"
                                  />
                                </div>
                              </div>
                              <span
                                v-if="errors.has('assign.push_notification_body')"
                                class="help is-danger"
                              >{{ errors.first('assign.push_notification_body') }}</span>
                            </div>
                          </div>
                        </div>
                      </collapse-transition>

                      <div class="row mx-4">
                        <div class="col">
                          <div class="swicth-checkbox">
                            <input
                              type="checkbox"
                              id="sendEmail"
                              v-model="cancel_emails"
                              :true-value="1"
                              :false-value="0"
                            >
                            <label
                              class="swicth-label"
                              for="sendEmail"
                            >{{ $t('dont_send_email') }}</label>
                          </div>
                        </div>
                      </div>
                      <div class="row mx-4">
                        <div class="col">
                          <div class="swicth-checkbox">
                            <input
                              type="checkbox"
                              id="disableNotify"
                              v-model="disableNotification"
                              :true-value="1"
                              :false-value="0"
                            >
                            <label
                              class="swicth-label"
                              for="disableNotify"
                            >{{ $t('mobil_notify_disabled') }}</label>
                          </div>
                        </div>
                      </div>
                      <div class="row mx-4">
                        <div class="col">
                          <div class="swicth-checkbox">
                            <input
                              type="checkbox"
                              id="selfPaced"
                              v-model="is_self_paced"
                              :true-value="1"
                              :false-value="0"
                            >
                            <label
                              class="swicth-label"
                              for="selfPaced"
                            >{{ $t('self_paced') }}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </collapse-transition>
                </div>

                <div
                  v-show="errors.items.length > 0"
                  class="form-group"
                >
                  <div class="alert alert--card alert--error">
                    <p>{{ $t('error_msg_title') }}</p>
                    <ul>
                      <li
                        v-for="(error, index) in errors"
                        :key="index"
                      >
                        {{ error.msg }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="button button--primary"
                    :class="{'button--disabled' : previewContentCount === 0}"
                    :disabled="isPending || previewContentCount === 0"
                    @click="duplicateJourney"
                  >
                    <span
                      v-if="!isPending"
                    >
                      {{ $t('assign') }}
                    </span>
                    <div
                      class="lds-ellipsis"
                      v-if="isPending"
                    >
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <collapse-transition
      :duration="500"
      dimension="height"
    >
      <div
        v-show="isDragging"
        class="hint"
      >
        <div class="img">
          <img
            src="@/assets/img/arrowkeys.png"
            alt="keyboard"
          >
        </div>
        <div class="title">
          {{ $t('keyboard_hint') }}
        </div>
      </div>
    </collapse-transition>
  </div>
</template>

<script>
import VEmojiPicker from 'v-emoji-picker';
import packData from 'v-emoji-picker/data/emojis';
import { directive as onClickaway } from 'vue-clickaway';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/en';
import 'vue2-datepicker/locale/tr';
import 'vue2-datepicker/index.css';
import vueTagsInput from '@johmun/vue-tags-input';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import CollapseTransition from '@ivanv/vue-collapse-transition';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
// eslint-disable-next-line import/extensions
import { Turkish } from 'flatpickr/dist/l10n/tr.js';
// eslint-disable-next-line import/extensions
import { english } from 'flatpickr/dist/l10n/default.js';
import FullCalendarComponent from '@/components/FullCalendar.vue';
import { mdiInformationOutline } from '@mdi/js';
import SvgIcon from '@jamescoyle/vue-icon';
import IconContainer from '@/components/elements/Icon.vue';
import IconTime from '@/components/icons/Time.vue';
import {
  API_USER_LIST,
  API_USER_GROUP_DATATABLES,
  API_JOURNEY_USER,
  API_JOURNEY_USER_GROUPS,
  API_JOURNEY_ITEMS_ALL,
  API_JOURNEY_DUPLICATE,
  API_JOURNEY_UPDATE,
  API_SERVER_TIME_FORMAT,
  API_JOURNEY_SCHEDULED_NOTIFICATION_UPDATE,
  API_JOURNEY_ITEMS_ALL_UPDATE,
} from '@/helpers/config';
import API from '@/services/';

export default {
  name: 'JourneyAssign',
  inject: ['$validator'],

  directives: {
    'on-clickaway': onClickaway,
  },
  data() {
    return {
      emojiPack: packData,
      dropdownIndex: null,
      isPending: false,
      isDragging: false,
      isHintAlreadyShown: false,
      formSending: false,
      statusNewJourney: false,
      statusUpdateJourneyItem: false,
      customizeDeliveryDate: 0,
      cache: {},
      record: {},
      previewContentCount: null,
      previewContentList: [],
      tag: '',
      tags: [],
      userList: [],
      userGroups: [],
      debounce: null,
      assignTo: null,
      beginActivationDateTime: new Date(),
      beginActivationDateTimeOldValue: null,
      previewDate: null,
      journeyNotificationSelect: 'scheduled',
      tabPane: 'startTab',
      duplicateJourneyItem: [],
      duplicatejourneyItems: [],
      is_welcome_notifications_enabled: 1,
      cancel_emails: 0,
      is_self_paced: 0,
      push_notification_title: '',
      push_notification_body: '',
      spacedValue: 1,
      maxSpacedValue: 60,
      selectSpace: { id: 1, text: this.$t('minute') },
      spaceOptions: [
        { id: 1, text: this.$t('minute') },
        { id: 2, text: this.$t('hour') },
        { id: 3, text: this.$t('day') },
      ],
      addDay: 0,
      addHours: 0,
      addMinutes: 0,
      calenderEvents: [],
      previewLastDate: null,
      finalEventDay: null,
      showAdvanceSettings: false,
      showAdvanceSettings2: false,
      totalEventDays: 1,
      is_weekend_included: 0,
      firstItemDate: null,
      runOnce: true,
      disableNotification: 0,
      isBeginDateDirty: false,
      activeBeginDate: null,
      sendOnSaturday: 0,
      sendOnSunday: 0,
      excludedDaysAsString: null,
      dayPush: 0,
      hasReminder: false,
      employee_title: this.$t('employee_title_text'),
      employee_content: this.$t('employee_content_text'),
      weeks_after_last_content: 1,
      week_span: 1,
      day_of_week: 1,
    };
  },

  computed: {

    autocompleteItems() { // eslint-disable-next-line
      this.userList = this.userList.concat(this.userGroups);
      return this.userList;
    },
    iconInformationOutline() {
      return mdiInformationOutline;
    },
    multiProcessProgressBar: {
      get() {
        return this.$store.state.modal.multiProcessProgressBar;
      },
      set(newValue) {
        this.$store.state.modal.multiProcessProgressBar = newValue;
      },
    },

    excludedDays() {
      let arr = [];
      if (this.excludedDaysAsString) {
        arr = this.excludedDaysAsString.split(',').map(item => item.trim());
      }
      return arr;
    },

    excludedDaysConfig() {
      const config = {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: this.userLang === 'en' ? 'm.d.Y' : 'd.m.Y',
        altInput: true,
        mode: 'multiple',
        dateFormat: 'Y-m-d',
        minDate: 'today',
        locale: this.userLang === 'en' ? english : Turkish, // locale for this instance only
      };
      return config;
    },
    previewFirstDate() {
      return moment(this.beginActivationDateTime, API_SERVER_TIME_FORMAT).format(API_SERVER_TIME_FORMAT);
    },

    journeyId() {
      return this.$store.state.modal.journeyId;
    },

    modalData() {
      return this.$store.state.modal.modalData;
    },

    journeyDetail: {
      get() {
        return this.$store.state.modal.journeyDetail;
      },
      set(newValue) {
        this.$store.state.modal.journeyDetail = newValue;
      },
    },

    isOnboarding() {
      if (this.journeyDetail) {
        if (this.journeyDetail.journey_type && this.journeyDetail.journey_type === 'ON_BOARDING') {
          return true;
        }
        // if (this.journeyDetail.desc && this.journeyDetail.desc.toLowerCase().includes('onboarding')) {
        //   return true;
        // }
      }
      return false;
    },

    journeyLocale() {
      if (this.journeyDetail) {
        return this.journeyDetail.lang;
      }
      return null;
    },

    journeyHaveUsers() {
      if (this.$store.state.modal.journeyDetail) {
        const totalUsers = this.$store?.state?.modal?.journeyDetail?.mobile_device_penetration?.number_of_total_users;
        if (totalUsers && totalUsers > 0) {
          return true;
        }
        return false;
      }
      return false;
    },

    isVibonsAdmin() {
      if (this.$store.state && this.$store.state.auth) {
        return this.$store.state.auth.isVibonsAdmin;
      }
      return false;
    },

    launchedFrom: {
      get() {
        return this.$store.state.modal.launchedFrom;
      },
      set(newValue) {
        this.$store.state.modal.launchedFrom = newValue;
      },
    },

    currentCustomerId() {
      return this.$store.state.auth.customerId;
    },

    userLang() {
      if (this.$store.state.auth.userInfo) {
        return this.$store.state.auth.userInfo.lang;
      }
      return 'en';
    },
  },

  components: {
    'date-picker': DatePicker,
    'multi-select': Multiselect,
    'vue-tags-input': vueTagsInput,
    'full-calendar': FullCalendarComponent,
    VEmojiPicker,
    'collapse-transition': CollapseTransition,
    'icon-container': IconContainer,
    'icon-time': IconTime,
    flatPickr,
    SvgIcon,
  },

  watch: {
    modalData(newValue) {
      const $newValue = newValue;
      if ($newValue && $newValue.journeyDetail) {
        this.push_notification_title = this.$t('default_push_noti_head_msg', $newValue.journeyDetail.lang);
        this.push_notification_body = this.$t('default_push_noti_body_msg', $newValue.journeyDetail.lang);
      }
    },

    firstItemDate: {
      handler(val, old) {
        if (val !== old && this.runOnce) {
          const mydate = moment(val, API_SERVER_TIME_FORMAT);
          this.beginActivationDateTime = new Date(
            mydate.format('YYYY'),
            (mydate.format('M') - 1),
            mydate.format('DD'), mydate.format('HH'),
            mydate.format('mm'), mydate.format('ss'),
          );
          this.runOnce = false;
          setTimeout(() => {
            this.runOnce = true; // to prevent loop
          }, 50);
        }
      },
    },

    selectSpace(newValue, oldValue) {
      if (newValue) {
        if (newValue.id === 1) {
          this.maxSpacedValue = 60;
        }
        if (newValue.id === 2) {
          this.maxSpacedValue = 24;
        }
        if (newValue.id === 3) {
          this.maxSpacedValue = 365;
        }
        if (oldValue !== newValue) {
          this.triggerPreviewDate();
        }
      }
    },
    beginActivationDateTimeOldValue(newValue, oldValue) {
      console.log('beginActivationDateTimeOldValue', newValue, oldValue);
    },
    beginActivationDateTime(newValue, oldValue) {
      this.beginActivationDateTimeOldValue = oldValue;
      this.triggerPreviewDate();
    },
    journeyDetail(newValue) {
      if (newValue && newValue.lang) {
        this.push_notification_title = this.$t('default_push_noti_head_msg', newValue.lang);
        this.push_notification_body = this.$t('default_push_noti_body_msg', newValue.lang);
      }
    },

    tag: 'getUsers',
    sendOnSaturday: 'triggerPreviewDate',
    sendOnSunday: 'triggerPreviewDate',
    excludedDays: {
      handler() {
        this.triggerPreviewDate();
      },
      immediate: true,
      deep: true,
    },
    spacedValue: 'triggerPreviewDate',
    journeyNotificationSelect: 'triggerPreviewDate',
    previewContentList: 'getActivationDates',
    duplicateJourneyItem: 'updateJourney',
    statusUpdateJourneyItem: 'newJourneyItems',
    statusNewJourney: 'submitUsers',
    cache: 'getActivationDates',
    is_weekend_included: {
      handler() {
        const date = moment().format('YYYY-MM-DD');
        const time = moment(this.beginActivationDateTime, API_SERVER_TIME_FORMAT).format('HH:mm:ss');
        this.beginActivationDateTime = moment(`${date} ${time}`, API_SERVER_TIME_FORMAT).toDate();
        this.triggerPreviewDate();
      },
    },
    tags() {
      if (this.tags && this.tags.length > 0) {
        this.assignTo = this.tags[0].text;
      } else {
        this.assignTo = null;
      }
      this.$nextTick(() => {
        this.$validator.validate('assign.assignTo', this.assignTo);
      });
    },
  },

  created() {
    this.multiProcessProgressBar = true;
    this.getContentItems();
    this.beginActivationDateTime.setHours(8, 0, 0);

    if (this.launchedFrom === 'modalSelectJourney') {
      this.tags.push(this.$store.state.modal.selectedToAssign);
      this.launchedFrom = null;
    }
  },
  mounted() {
    console.log('isOnboarding:', this.isOnboarding);
    if (this.isOnboarding) {
      this.journeyNotificationSelect = 'scheduled';
    }
  },
  methods: {
    getDaysBetweenDates(startDate, endDate) {
      const current = startDate.clone();
      const first = startDate.clone();
      const dates = [];
      while (current.isBefore(endDate)) {
        dates.push(current.format('YYYY-MM-DD'));
        current.add(1, 'days');
      }
      dates.splice(dates.indexOf(first.format('YYYY-MM-DD')), 1);
      return dates;
    },

    setTotalEventDays() {
      const now = moment(); // we have to calculate it from now, even if the beginDate is diferent then now
      const finalday = moment(this.finalEventDay, API_SERVER_TIME_FORMAT);
      this.totalEventDays = finalday.diff(now, 'days') + 2; // we have to include the first and last day in calculation
    },

    notBeforeToday(date) {
      const now = new Date();
      now.setMonth(now.getMonth() - 3);
      return date < new Date(now.getFullYear(), now.getMonth(), now.getDate());
    },

    notBeforeTime(date) {
      const now = new Date();
      now.setMonth(now.getMonth() - 3);
      if (moment(new Date(), API_SERVER_TIME_FORMAT).isSame(moment(this.beginActivationDateTime, API_SERVER_TIME_FORMAT).format(API_SERVER_TIME_FORMAT), 'day')) {
        return date < new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds());
      }
      return false;
    },

    adjustTime(event, type) {
      console.log('evenntç.', event, 'type:', type);

      if (this.beginActivationDateTime !== null) {
        if (this.beginActivationDateTimeOldValue && type === 'date') {
          const date = moment(this.beginActivationDateTime, API_SERVER_TIME_FORMAT).format('YYYY-MM-DD');
          const time = moment(this.beginActivationDateTimeOldValue, API_SERVER_TIME_FORMAT).format('HH:mm:ss');
          this.beginActivationDateTime = moment(`${date} ${time}`, API_SERVER_TIME_FORMAT).toDate();
        } else if (this.beginActivationDateTimeOldValue && type === 'time') {
          if (this.is_weekend_included && this.journeyNotificationSelect === 'spaced') {
            this.beginActivationDateTime = event;
          } else {
            console.log('beginActivationDateTimeOldValue:', this.beginActivationDateTimeOldValue);
            const date = moment(this.beginActivationDateTimeOldValue, API_SERVER_TIME_FORMAT).format('YYYY-MM-DD');
            const time = moment(this.beginActivationDateTime, API_SERVER_TIME_FORMAT).format('HH:mm:ss');
            this.beginActivationDateTime = moment(`${date} ${time}`, API_SERVER_TIME_FORMAT).toDate();
          }
        } else {
          this.beginActivationDateTime.setHours(8, 0, 0);
        }
        this.isBeginDateDirty = true;
        this.activeBeginDate = moment(this.beginActivationDateTime, API_SERVER_TIME_FORMAT).format('YYYY-MM-DD HH:mm:ss');
      }
    },

    hideDropdown() {
      this.dropdownIndex = null;
    },

    SelectEmojiForTitle(dataEmoji) {
      this.push_notification_title += dataEmoji.emoji;
    },

    SelectEmojiForBody(dataEmoji) {
      this.push_notification_body += dataEmoji.emoji;
    },

    setDragging() {
      if (!this.isHintAlreadyShown) {
        this.isDragging = true;
        setTimeout(() => {
          this.isDragging = false;
          this.isHintAlreadyShown = true;
        }, 5000);
      }
    },

    dropdownToggle(index) {
      this.dropdownIndex = index;
      setTimeout(() => {
        if (this.dropdownIndex && this.$refs[`dropdownBox${index}`]) {
          const elem = this.$refs[`dropdownBox${index}`];

          if (elem) {
            const isOut = this.$helpers.isOutOfScreen(elem);
            if (isOut.right) {
              elem.classList.add('right');
            } else if (isOut.left) {
              elem.classList.add('left');
            } else if (isOut.bottom) {
              elem.classList.add('bottomjourney');
            } else if (isOut.top) {
              elem.classList.add('top');
            }
          }
        }
      }, 50);
    },

    showDatePicker(elem) {
      this.$refs[`${elem}`][0].openPopup();
    },

    getActivationDates() {
      const items = this.dash.cloneDeep(this.previewContentList);
      items.sort((a, b) => {
        if (moment(a.activation_date).format(API_SERVER_TIME_FORMAT) > moment(b.activation_date).format(API_SERVER_TIME_FORMAT)) {
          return 1;
        }
        if (moment(a.activation_date).format(API_SERVER_TIME_FORMAT) < moment(b.activation_date).format(API_SERVER_TIME_FORMAT)) {
          return -1;
        }
        return 0;
      });
      if (items && items[0]) {
        if (this.journeyNotificationSelect === 'scheduled') {
          if (this.isBeginDateDirty) {
            this.firstItemDate = this.activeBeginDate;
          } else {
            this.firstItemDate = moment(this.beginActivationDateTime, API_SERVER_TIME_FORMAT).format('YYYY-MM-DD HH:mm:ss');
          }
        } else {
          this.firstItemDate = items[0].activation_date;
        }

        const lastItem = [...items].pop();
        if (lastItem) {
          this.previewLastDate = this.userLang === 'en'
            ? moment(lastItem.activation_date, API_SERVER_TIME_FORMAT).locale('en').format('LL, hh:mm A')
            : moment(lastItem.activation_date, API_SERVER_TIME_FORMAT).locale('tr').format('LL, HH:mm');
          this.finalEventDay = moment(lastItem.activation_date, API_SERVER_TIME_FORMAT).format(API_SERVER_TIME_FORMAT);
          this.setTotalEventDays();
        }
      }
      this.updateCalenderEvents(this.previewContentList);
    },

    disableWeekends(date) {
      const day = new Date(date).getDay();
      return day === 0 || day === 6;
    },

    changeNotificationChange(item) {
      this.journeyNotificationSelect = item;
      if (item === 'scheduled') {
        this.beginActivationDateTime = new Date();
      }
    },

    addDays(originalDate, numDaysToAdd) {
      const Sunday = 0;
      const Saturday = 6;
      let daysRemaining = numDaysToAdd;
      console.log(`numDaysToAdd :${numDaysToAdd}`);
      const newDate = originalDate.clone();
      if (numDaysToAdd === 0 && this.excludedDays.includes(newDate.format('YYYY-MM-DD'))) {
        newDate.add(1, 'days');
      }
      if (this.sendOnSaturday === 0 && this.sendOnSunday === 0) {
        while (daysRemaining > 0) {
          newDate.add(1, 'days');
          // console.log(`addDays 1.cond :${newDate} - ${(newDate.day() !== Sunday && newDate.day() !== Saturday) ? '' : 'X'}`);
          if (newDate.day() !== Sunday && newDate.day() !== Saturday && !this.excludedDays.includes(newDate.format('YYYY-MM-DD'))) {
            daysRemaining -= 1;
          }
        }
      } else if (this.sendOnSunday === 0 && this.sendOnSaturday === 1) {
        while (daysRemaining > 0) {
          newDate.add(1, 'days');
          // console.log(`addDays 2.cond :${newDate} - ${(newDate.day() !== Sunday) ? '' : 'X'}`);
          if (newDate.day() !== Sunday && !this.excludedDays.includes(newDate.format('YYYY-MM-DD'))) {
            daysRemaining -= 1;
          }
        }
      } else if (this.sendOnSunday === 1 && this.sendOnSaturday === 0) {
        while (daysRemaining > 0) {
          newDate.add(1, 'days');
          // console.log(`addDays 3.cond :${newDate} - ${(newDate.day() !== Saturday) ? '' : 'X'}`);
          if (newDate.day() !== Saturday && !this.excludedDays.includes(newDate.format('YYYY-MM-DD'))) {
            daysRemaining -= 1;
          }
        }
      } else {
        while (daysRemaining > 0) {
          newDate.add(1, 'days');
          // console.log(`addDays 4.cond :${newDate} -`);
          if (!this.excludedDays.includes(newDate.format('YYYY-MM-DD'))) {
            daysRemaining -= 1;
          }
        }
      }
      return newDate;
    },

    checkConditions(filterDate) {
      if (filterDate) {
        const checkdate = filterDate.format('YYYY-MM-DD');
        if (this.excludedDays.includes(checkdate)) {
          console.log('Exc....');
          this.dayPush += 1;
          return true;
        }
        if ((filterDate.format('dddd') === 'Saturday' || filterDate.format('dddd') === 'Cumartesi')) {
          if (this.sendOnSaturday === 0) {
            console.log('SAT....');
            this.dayPush += 1;
            return true;
          }
        }
        if ((filterDate.format('dddd') === 'Sunday' || filterDate.format('dddd') === 'Pazar')) {
          if (this.sendOnSunday === 0) {
            console.log('PAZ....');
            this.dayPush += 1;
            return true;
          }
        }
      }
      return false;
    },

    triggerPreviewDate() {
      this.addMinutes = 0;
      this.addDay = 0;
      this.addHours = 0;
      const frequencyMode = this.journeyNotificationSelect;
      if (this.previewContentList && this.previewContentList.length) {
        if (this.isOnboarding && this.previewContentList[0] && frequencyMode !== 'scheduled') {
          const hours = moment(this.previewContentList[0].content.activation_date, API_SERVER_TIME_FORMAT).format('HH');
          const minutes = moment(this.previewContentList[0].content.activation_date, API_SERVER_TIME_FORMAT).format('mm');
          this.beginActivationDateTime.setHours(hours, minutes, 0);
        }
        this.dayPush = 0;
        this.previewContentList.map((item, index) => {
          const $item = item;
          let filterDate = null;
          let filterDateTemp = null;
          if (frequencyMode === 'onceAWeek') {
            this.addDay = index * 7;
            filterDate = moment(this.beginActivationDateTime, API_SERVER_TIME_FORMAT).add(this.addDay, 'days');
          } else if (frequencyMode === 'twiceAWeek') {
            let $addDay = 0;
            filterDate = moment(this.previewFirstDate, API_SERVER_TIME_FORMAT).add(this.addDay, 'days');
            switch (filterDate.format('dddd')) {
              case 'Pazartesi':
              case 'Monday':
                $addDay = 3;
                break;
              case 'Salı':
              case 'Tuesday':
                $addDay = 2;
                break;
              case 'Çarşamba':
              case 'Wednesday':
                $addDay = 1;
                break;
              case 'Perşembe':
              case 'Thursday':
                $addDay = 5;
                break;
              case 'Cuma':
              case 'Friday':
                $addDay = 4;
                break;
              case 'Cumartesi':
              case 'Saturday':
                $addDay = 3;
                break;
              case 'Pazar':
              case 'Sunday':
                $addDay = 2;
                break;
              default:
            }
            filterDate = moment(this.previewFirstDate, API_SERVER_TIME_FORMAT).add(this.addDay, 'days');
            this.addDay += $addDay;
          } else if (frequencyMode === 'thriceAWeek') {
            let $addDay = 0;
            filterDate = moment(this.previewFirstDate, API_SERVER_TIME_FORMAT).add(this.addDay, 'days');
            switch (filterDate.format('dddd')) {
              case 'Pazartesi':
              case 'Monday':
                $addDay = 2;
                break;
              case 'Salı':
              case 'Tuesday':
                $addDay = 1;
                break;
              case 'Çarşamba':
              case 'Wednesday':
                $addDay = 2;
                break;
              case 'Perşembe':
              case 'Thursday':
                $addDay = 1;
                break;
              case 'Cuma':
              case 'Friday':
                $addDay = 3;
                break;
              case 'Cumartesi':
              case 'Saturday':
                $addDay = 2;
                break;
              case 'Pazar':
              case 'Sunday':
                $addDay = 1;
                break;
              default:
            }
            filterDate = moment(this.previewFirstDate, API_SERVER_TIME_FORMAT).add(this.addDay, 'days');
            this.addDay += $addDay;
          } else if (frequencyMode === 'dailyOnWeekdays') {
            filterDate = moment(this.previewFirstDate, API_SERVER_TIME_FORMAT).add(this.addDay, 'days');
            if (filterDate.format('dddd') === 'Saturday' || filterDate.format('dddd') === 'Cumartesi') {
              this.addDay += 2;
            } else if (filterDate.format('dddd') === 'Sunday' || filterDate.format('dddd') === 'Pazar') {
              this.addDay += 1;
            }// eslint-disable-next-line
            filterDate = moment(this.previewFirstDate, API_SERVER_TIME_FORMAT).add(this.addDay, 'days');
            this.addDay += 1;
          } else if (frequencyMode === 'spaced' && this.spacedValue) {
            const validPattern = new RegExp('^[1-9]\\d*$');
            if (validPattern.test(this.spacedValue)) {
              if (this.selectSpace.id === 1) {
                const addSpacedTime = index * this.spacedValue;
                filterDate = moment(this.previewFirstDate, API_SERVER_TIME_FORMAT).add(addSpacedTime, 'minutes');
                if (!this.is_weekend_included) {
                  filterDate = moment(this.previewFirstDate, API_SERVER_TIME_FORMAT).add(addSpacedTime + this.addMinutes, 'minutes');
                  if (filterDate.format('dddd') === 'Saturday' || filterDate.format('dddd') === 'Cumartesi') {
                    this.addMinutes += 2880;
                    filterDate = filterDate.add(2880, 'minutes');
                  }
                  if (filterDate.format('dddd') === 'Sunday' || filterDate.format('dddd') === 'Pazar') {
                    this.addMinutes += 1440;
                    filterDate = filterDate.add(1440, 'minutes');
                  }
                }
                // console.log('spaced-' + index, filterDate.format('YYYY-MM-DD HH:mm:ss'));
              } else if (this.selectSpace.id === 2) {
                const addTime = index * this.spacedValue;
                filterDate = moment(this.previewFirstDate, API_SERVER_TIME_FORMAT).add(addTime, 'hours');
                if (!this.is_weekend_included) {
                  filterDate = moment(this.previewFirstDate, API_SERVER_TIME_FORMAT).add(addTime + this.addHours, 'hours');
                  if (filterDate.format('dddd') === 'Saturday' || filterDate.format('dddd') === 'Cumartesi') {
                    this.addHours += 48;
                    filterDate = filterDate.add(48, 'hours');
                  }
                  if (filterDate.format('dddd') === 'Sunday' || filterDate.format('dddd') === 'Pazar') {
                    this.addHours += 24;
                    filterDate = filterDate.add(24, 'hours');
                  }
                }
              } else if (this.selectSpace.id === 3) {
                const addTime = index * this.spacedValue;
                filterDate = moment(this.previewFirstDate, API_SERVER_TIME_FORMAT).add(addTime, 'days');
                if (!this.is_weekend_included) {
                  filterDate = moment(this.previewFirstDate, API_SERVER_TIME_FORMAT).add(addTime + this.addDay, 'days');
                  if (filterDate.format('dddd') === 'Saturday' || filterDate.format('dddd') === 'Cumartesi') {
                    this.addDay += 2;
                    filterDate = filterDate.add(2, 'days');
                  }
                  if (filterDate.format('dddd') === 'Sunday' || filterDate.format('dddd') === 'Pazar') {
                    this.addDay += 1;
                    filterDate = filterDate.add(1, 'days');
                  }
                }
              }
            }
          } else if (frequencyMode === 'custom') {
            filterDate = $item.activation_date;
          } else if (frequencyMode === 'scheduled') {
            const journeyDate = moment(this.journeyDetail.activation_date, API_SERVER_TIME_FORMAT);
            const setDate = moment(this.beginActivationDateTime, API_SERVER_TIME_FORMAT);
            const weekofSetDate = setDate.startOf('day').week();
            const journeyDateTemp = journeyDate.clone();
            const currentItem = moment($item.content.activation_date, API_SERVER_TIME_FORMAT);
            const currentItemTemp = currentItem.clone();
            const addTime = currentItemTemp.startOf('day').diff(journeyDateTemp.startOf('day'), 'days');
            // console.log('currentItem:', currentItem.format(API_SERVER_TIME_FORMAT), 'journeyDateTemp', journeyDateTemp.format(API_SERVER_TIME_FORMAT), 'Addtime', addTime, 'setDate:', setDate.format(API_SERVER_TIME_FORMAT));
            if (addTime < 0) { // check if the current item date is earlier then the journey start date
              filterDateTemp = moment(this.beginActivationDateTime, API_SERVER_TIME_FORMAT).add(addTime, 'days').set({ hour: currentItem.format('HH'), minute: currentItem.format('mm'), second: currentItem.format('ss') });
              const weekOfCurrent = filterDateTemp.week();
              const weekDiff = weekOfCurrent - weekofSetDate;
              let shiftDay = 0;
              if (weekDiff < 0) { // check if the current item is in different week from the journey start week
                if (this.sendOnSaturday === 0) {
                  shiftDay += 1;
                }
                if (this.sendOnSunday === 0) {
                  shiftDay += 1;
                }
              }
              if (shiftDay) shiftDay *= weekDiff;
              filterDate = filterDateTemp.add(shiftDay, 'days').set({ hour: currentItem.format('HH'), minute: currentItem.format('mm'), second: currentItem.format('ss') });
            } else { // if items are afterwards
              setDate.set({ hour: currentItem.format('HH'), minute: currentItem.format('mm'), second: currentItem.format('ss') });
              filterDate = this.addDays(setDate, addTime);
            }
            // if ($item?.content?.notify_dt) {
            //   console.log(currentItem.format(API_SERVER_TIME_FORMAT), filterDate.format(API_SERVER_TIME_FORMAT));
            // }
          }

          if (filterDate) {
            if (typeof filterDate === 'object') {
              filterDate = filterDate.format(API_SERVER_TIME_FORMAT);
            }
            $item.activation_date = filterDate;
          }
          return $item;
        });
        this.getActivationDates();
      }
      this.multiProcessProgressBar = false;
    },

    updateCalenderEvents(newVal) {
      this.calenderEvents = [];
      // eslint-disable-next-line no-unused-vars
      Object.entries(newVal).forEach(([k, v]) => {
        let theColor = '#1976d2';
        if (v.type !== 'journey_item') {
          theColor = '#2dcf85';
        } else if (v.content.sub_type === 'LIVE_EVENT' || v.content.sub_type === 'MEETING') {
          theColor = '#FF6347';
        }
        const ss = {
          id: v.content.id,
          content_id: (v.type === 'journey_item') ? v.content.as_content_id : v.content.id,
          isnotif: (v.type !== 'journey_item'),
          notif_preview: (v.type !== 'journey_item'),
          all_content: (v.type !== 'journey_item') ? v.content : '',
          color: theColor,
          subtype: this.titleCase(this.$helpers.getSubTypeName(v.content.sub_type, this.userLang)),
          title: (v.type === 'journey_item') ? v.content.name : v.content.title,
          allDay: false,
          start: v.activation_date,
          order: v.content.order,
        };
        this.calenderEvents.push(ss);
      });
    },

    titleCase(str) {
      if (str) {
        const mystr = str.toLowerCase().split(' ');
        for (let i = 0; i < mystr.length; i += 1) {
          mystr[i] = mystr[i].charAt(0).toUpperCase() + mystr[i].slice(1);
        }
        return mystr.join(' ');
      }
      return '';
    },

    getContentItems() {
      const that = this;
      if (this.$route.params.journeyId || this.journeyId) {
        const journeyId = this.journeyId || this.$route.params.journeyId;
        API.get(`${API_JOURNEY_ITEMS_ALL}/${journeyId}`)
          .then((response) => {
            that.previewContentList = Object.values(response.data.items).filter(item => item.content.sub_type !== 'CHAPTER');
            that.previewContentCount = response.data.total_content_count;
            this.journeyDetail = response.data.journey;
            this.$store.commit('modal/setJourneyDetail', this.journeyDetail);
            that.triggerPreviewDate();
          })
          .catch((error) => {
            this.$helpers.displayError(error);
          });
      }
    },

    doEdit(record) {
      const index = this.dash.indexOf(this.previewContentList, this.cache);
      this.previewContentList.splice(index, 1, record);
    },

    edit(record) {
      this.record = JSON.parse(JSON.stringify(record));
      this.cache = record;
    },

    tabNavigation(value) {
      const $value = value;
      this.tabPane = $value;
    },

    setDateFromDrop(dropitem) {
      this.journeyNotificationSelect = 'custom';
      this.customizeDeliveryDate = 1;
      let found = false;
      if (dropitem.isnotif) {
        found = this.previewContentList.find((item) => {
          if (item.content.id) {
            return item.content.id === dropitem.id;
          }
          return false;
        });
      } else {
        found = this.previewContentList.find((item) => {
          if (item.content && item.content.id) {
            return Number(item.content.id) === Number(dropitem.id);
          }
          return false;
        });
      }
      if (found) {
        found.activation_date = dropitem.newdatetime;
      }
      this.getActivationDates();
    },

    closeModal() {
      this.$validator.errors.clear();
      this.$store.commit('modal/toggleModal');
    },

    update(newTags) {
      this.tags = newTags;
    },

    getUsers() {
      this.userList = [];
      this.userGroups = [];

      const userData = { params: {} };
      userData.params.per_page = 10000;
      userData.params.search = this.tag;
      if (this.currentCustomerId) {
        userData.params.customer_id = this.currentCustomerId;
      }
      if (this.tag.length < 2) {
        return;
      }
      const searchInput = document.querySelector('.ti-new-tag-input');
      searchInput.classList.add('loading-icon');
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getUserGroups();
        API.get(API_USER_LIST, userData)
          .then((response) => { // eslint-disable-next-line
            this.userList = response.data.data.map((item) => {
              return { // eslint-disable-next-line
                text: `${item.first_name} ${item.last_name} [${item.email}]`,
                id: item.id,
              };
            });
            searchInput.classList.remove('loading-icon');
          })
          .catch((error) => {
            this.$helpers.displayError(error);
            searchInput.classList.remove('loading-icon');
          });
      }, 300);
    },

    getUserGroups() {
      const userData = { params: {} };
      userData.params.per_page = 10000;
      userData.params.search = this.tag;
      if (this.currentCustomerId) {
        userData.params.customer_id = this.currentCustomerId;
      }
      API.get(API_USER_GROUP_DATATABLES, userData)
        .then((response) => { // eslint-disable-next-line
          this.userGroups = response.data.data.map((item) => {
            return {
              text: `[User Group] ${item.name}`,
              id: item.id,
              userGroup: true,
            };
          });
        })
        .catch((error) => {
          this.$helpers.displayError(error);
        });
    },

    async checkValidation() {
      let allIsValid = false;
      let assignToIsValid = false;
      let beginDateIsValid = false;
      let beginTimeIsValid = false;

      await this.$validator.validate('assign.assignTo').then((res) => {
        if (res) {
          assignToIsValid = true;
        }
      });
      await this.$validator.validate('assign.beginDate').then((res) => {
        if (res) {
          beginDateIsValid = true;
        }
      });
      await this.$validator.validate('assign.beginTime').then((res) => {
        if (res) {
          beginTimeIsValid = true;
        }
      });
      await this.$validator.validateAll('assign').then((res) => {
        allIsValid = res;
      });
      if (allIsValid && assignToIsValid && beginDateIsValid && beginTimeIsValid) {
        return true;
      }
      return false;
    },

    duplicateJourney() {
      this.checkValidation().then((res) => {
        if (res) {
          this.formSending = true;
          this.isPending = true;
          const formData = new FormData();
          const activationDate = this.firstItemDate; // this.previewContentList[0].activation_date;
          const journeyId = this.journeyId || this.$route.params.journeyId;
          formData.append('activation_date', activationDate);
          formData.append('journey_id', journeyId);
          if (this.isVibonsAdmin) {
            formData.append('customer_id', this.currentCustomerId);
          }
          API.post(API_JOURNEY_DUPLICATE, formData)
            .then((response) => {
              this.duplicateJourneyItem = response.data;
            })
            .catch((error) => {
              this.formSending = false;
              this.isPending = false;
              this.$helpers.displayError(error);
            });
        }
      });
    },

    async updateJourney() {
      if (this.duplicateJourneyItem) {
        this.formSending = true;
        this.isPending = true;
        const item = this.duplicateJourneyItem.journey;
        const activationDate = this.firstItemDate; // this.previewContentList[0].activation_date;
        const formData = new FormData();
        formData.append('journey_id', item.id);
        formData.append('name', item.name);
        formData.append('activation_date', activationDate);
        formData.append('is_list_on_the_catalog_enabled', 0);
        formData.append('push_notification_title', this.push_notification_title.replace('{JOURNEY_NAME}', item.name));
        formData.append('push_notification_body', this.push_notification_body.replace('{JOURNEY_NAME}', item.name));
        formData.append('is_welcome_notifications_enabled', item.is_welcome_notifications_enabled);
        formData.append('is_self_paced', this.is_self_paced);
        formData.append('lang', item.lang);

        API.post(API_JOURNEY_UPDATE, formData)
          .then((response) => {
            if (response.status === 200) {
              this.statusUpdateJourneyItem = true;
            }
          })
          .catch((error) => {
            this.formSending = false;
            this.isPending = false;
            this.$helpers.displayError(error);
          });
      }
    },

    async newJourneyItems() {
      if (this.duplicateJourneyItem && this.statusUpdateJourneyItem) {
        const $items = this.duplicateJourneyItem.items;
        const arr = Object.values($items).filter(item => item.content.sub_type !== 'CHAPTER');
        const $journeyId = this.duplicateJourneyItem.journey.id;
        const responseChaine = [];
        const self = this;
        let notifIndex = 0;
        const notifFormData = new FormData();
        const formData = new FormData();
        formData.append('journey_id', $journeyId);
        if (this.cancel_emails) {
          formData.append('is_email_enabled[0]', 0);
        }
        if (this.disableNotification) {
          formData.append('is_notifications_enabled[0]', 0);
        }
        // eslint-disable-next-line no-restricted-syntax
        for await (const [index, item] of arr.entries()) {
          if (Object.prototype.hasOwnProperty.call(item.content, 'notify_dt')) {
            const $notificationId = item.content.id;
            const $date = this.previewContentList[index].activation_date;
            notifFormData.append(`notifies[${notifIndex}]`, $notificationId);
            notifFormData.append(`notify_dt[${notifIndex}]`, $date);
            notifIndex += 1;
          } else {
            const $contentId = item.content.id;
            const $date = this.previewContentList[index].activation_date;
            formData.append(`activation_dt[${$contentId}]`, $date);
          }
        }
        if (formData && !formData.entries().next().done) {
          API.post(API_JOURNEY_ITEMS_ALL_UPDATE, formData)
            .then((response) => {
              if (response.status === 200) {
                responseChaine.push(1);
                self.statusNewJourney = true;
              } else {
                responseChaine.push(0);
              }
            })
            .catch((error) => {
              self.formSending = false;
              self.isPending = false;
              self.$helpers.displayError(error);
            }).then(() => {
              if (!responseChaine.includes(0)) {
                self.$snotify.success(this.$t('assigned_success'));
              }
            });
        }

        if (notifFormData && !notifFormData.entries().next().done) {
          API.post(API_JOURNEY_SCHEDULED_NOTIFICATION_UPDATE, notifFormData)
            .then((response) => {
              if (response.status === 200) {
                responseChaine.push(1);
                self.statusNewJourney = true;
              } else {
                responseChaine.push(0);
              }
            })
            .catch((error) => {
              self.formSending = false;
              self.isPending = false;
              self.$helpers.displayError(error);
            }).then(() => {
              if (!responseChaine.includes(0)) {
                self.$snotify.success(this.$t('assigned_success'));
              }
            });
        }
        if ((formData && formData.entries().next().done) && (notifFormData && notifFormData.entries().next().done)) {
          self.formSending = false;
          self.isPending = false;
        }
      }
    },

    async submitUsers() {
      if (this.statusNewJourney) {
        const $journeyId = this.duplicateJourneyItem.journey.id;
        const users = [];
        const userGroups = [];
        this.tags.forEach((item) => {
          if (item.userGroup) {
            userGroups.push(item.id);
          } else {
            users.push(item.id);
          }
        });

        if (userGroups.length > 0) {
          await Promise.all(userGroups.map(async (item) => {
            const formData = new FormData();
            formData.append('journey_id', $journeyId);
            formData.append('group_id', item);

            await API.post(API_JOURNEY_USER_GROUPS, formData)
              .then((response) => {
                if (response.status === 200) {
                  this.$snotify.success(this.$t('added_success'));
                  this.closeModal();
                }
              })
              .catch((error) => {
                this.formSending = false;
                this.isPending = false;
                this.$helpers.displayError(error);
              });
          }));
        }

        if (users.length > 0) {
          const formData = new FormData();
          formData.append('journey_id', $journeyId);
          formData.append('user_id', users.join(','));
          await API.post(API_JOURNEY_USER, formData)
            .then((response) => {
              if (response.status === 200) {
                this.$snotify.success(this.$t('added_success'));
                this.closeModal();
              }
            })
            .catch((error) => {
              this.formSending = false;
              this.isPending = false;
              this.$helpers.displayError(error);
            });
        }
      }
      if (this.hasReminder) {
        const payload = {
          journey_id: this.duplicateJourneyItem.journey.id,
          employee_title: this.employee_title,
          employee_content: this.employee_content,
          weeks_after_last_content: this.weeks_after_last_content,
          week_span: this.week_span,
          day_of_week: this.day_of_week,
        };
        await this.$store.dispatch('modal/POST_JOURNEY_REMINDER', payload);
      }
    },
  },
};
</script>

<style lang="scss">
.is-danger {
  border-color: #f27299;
}

::v-deep #Emojis div.container-emoji div.grid-emojis span.emoji {
  display: inline-table!important;
}
.emoji-button {
  position: absolute;
  display: inline-flex;
  right: -20px;
  font-size: 1.2em;
  color: #727d92;
}
.emoji-dropdown-title {
  transform: scale(0.8);
  top: -70px;
}
.emoji-dropdown-body {
  transform: scale(0.8);
  top: -110px;
}
.bglight {
  background: #f8f9ff;
  padding-top:30px;
  padding-bottom: 30px;
}
.bglight .form-group {
  border-bottom-width:0!important;
}
.calender-tab {
  width: 75vw;
  max-width: 1440px;
  min-width: 1010px!important;
  .modal-body {
    max-width: 1430px!important;
    min-width: 1000px!important;
    .tab-content {
      max-width: 1410px;
      .schedule-next {
        display: block;
        text-align: center;
        margin-top: 20px;
      }
    }
  }
}
.schedule-title {
  text-align: center;
  margin-bottom: 30px!important;
}
.panel-title {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #727d92;
  text-align: center;

}
.panel-title label {
  cursor: pointer;
}
.panel-title label:after {
  content: "\032C";
  left:20px;
  position: relative;
  top: -8px;
  font-size:30px;
  transition: all 1s;
}
 .panel-title.active label:after {
   content: "\032D";
}
.multiselect {
    max-width: max-content;
}
.hint {
  position: absolute;
  text-align: center;
  background-color: #142b58;
  opacity: 0.8;
  bottom: 0;
  z-index: 100;
  width: 460px;
  left: calc(50% - 230px);
  img {
    width: 180px;
    height: auto;
  }
  .title {
    color: #fff;
    font-size: 18px;
    text-align: center;
  }
}
.selectbox-o {
  height: 36px;
  color: #666;
  border: 1px solid rgba(114, 125, 146, 0.25);
  border-radius: 4px;
  padding:2px;
}
</style>
