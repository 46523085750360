<!-- eslint-disable vue-i18n/no-missing-keys -->
<template>
  <div class="modal-container">
    <div
      class="modal"
      :class="{'is-loading': formSending }"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">
              {{ editMode ? $t('edit_user') : $t('add_new_user') }}
            </h5>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>
          <nav class="navigation navigation--secondary">
            <div class="container">
              <ul
                class="navigation__list nav"
                id="userAddTab"
                role="tablist"
              >
                <li class="navigation__item">
                  <button
                    class="navigation__link"
                    :class="{ 'active': tabPane === 'mainTab'}"
                    @click="tabNavigation('mainTab')"
                  >
                    {{ $t('main_tab') }}
                  </button>
                </li>
                <li class="navigation__item">
                  <button
                    class="navigation__link"
                    :class="{ 'active': tabPane === 'advancedSettingsTab'}"
                    @click="tabNavigation('advancedSettingsTab')"
                  >
                    {{ $t('advanced_tab') }}
                  </button>
                </li>
                <li class="navigation__item">
                  <button
                    class="navigation__link"
                    :class="{ 'active': tabPane === 'otherSettingsTab'}"
                    @click="tabNavigation('otherSettingsTab')"
                  >
                    {{ $t('other_tab') }}
                  </button>
                </li>
                <li class="navigation__item">
                  <button
                    class="navigation__link"
                    :class="{ 'active': tabPane === 'relatedUsersTab'}"
                    @click="tabNavigation('relatedUsersTab')"
                  >
                    {{ $t('related_users') }}
                  </button>
                </li>
                <li
                  class="navigation__item"
                  v-if="editMode"
                >
                  <button
                    class="navigation__link"
                    :class="{ 'active': tabPane === 'groupsJourneysTab'}"
                    @click="tabNavigation('groupsJourneysTab')"
                  >
                    {{ $t('groups_n_journeys') }}
                  </button>
                </li>
              </ul>
            </div>
          </nav>
          <div class="modal-body">
            <div class="tab-content">
              <div
                class="tab-pane"
                v-show="tabPane === 'mainTab'"
              >
                <div class="form-group">
                  <label
                    for="contentTitle"
                    class="form-label"
                  >{{ $t('name_uppercase') }}</label>
                  <input
                    :class="{ 'is-danger': errors.has('adduser.name') }"
                    type="text"
                    class="form-control"
                    id="userName"
                    v-model="userName"
                    data-vv-scope="adduser"
                    name="name"
                    :placeholder="$t('name_placeholder')"
                    v-validate="'required'"
                  >
                  <span
                    v-if="errors.has('adduser.name')"
                    class="help is-danger"
                  >{{ errors.first('adduser.name') }}</span>
                </div>
                <div class="form-group">
                  <label
                    for="contentTitle"
                    class="form-label"
                  >{{ $t('surname_uppercase') }}</label>
                  <input
                    :class="{ 'is-danger': errors.has('adduser.surname') }"
                    type="text"
                    class="form-control"
                    id="userSurname"

                    v-model="userSurname"
                    name="surname"
                    data-vv-scope="adduser"
                    :placeholder="$t('surname_placeholder')"
                    v-validate="'required'"
                  >
                  <span
                    v-if="errors.has('adduser.surname')"
                    class="help is-danger"
                  >{{ errors.first('adduser.surname') }}</span>
                </div>
                <div class="form-group">
                  <label
                    for="contentTitle"
                    class="form-label"
                  >{{ $t('email_uppercase') }}</label>
                  <input
                    :class="{ 'is-danger': errors.has('adduser.e-mail') }"
                    type="text"
                    class="form-control"
                    id="userEmail"
                    v-model="userEmail"

                    data-vv-scope="adduser"
                    name="e-mail"
                    :placeholder="$t('email_placeholder')"
                    v-validate="'required|email'"
                  >
                  <span
                    v-if="errors.has('adduser.e-mail')"
                    class="help is-danger"
                  >{{ errors.first('adduser.e-mail') }}</span>
                </div>
                <div
                  v-show="errors.items.length > 0"
                  class="form-group"
                >
                  <div class="alert alert--card alert--error">
                    <p>{{ $t('error_msg_title') }}</p>
                    <ul>
                      <li
                        v-for="(error, index) in errors"
                        :key="index"
                      >
                        {{ error.msg }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="button button--primary"
                    @click="editMode ? updateUser() : addUser()"
                    :disabled="isPending"
                  >
                    <span
                      v-if="!isPending"
                    >
                      {{ editMode ? $t('update_button') : $t('add') }}
                    </span>
                    <div
                      class="lds-ellipsis"
                      v-if="isPending"
                    >
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </button>
                </div>
              </div>
              <div
                class="tab-pane"
                v-show="tabPane === 'advancedSettingsTab'"
              >
                <div
                  class="form-group"
                  v-if="isVibonsAdmin"
                >
                  <div class="row">
                    <div class="col-12">
                      <label
                        for="customer"
                        class="form-label"
                      >{{ $t('customer').toUpperCase() }}</label>
                      <multi-select
                        :class="{ 'is-danger': errors.has('adduser.customer') }"
                        track-by="id"
                        label="name"
                        clear-on-select
                        deselect-label
                        select-label
                        v-model="selectCustomer"
                        :options="customerOptions"
                        class="selectbox selectbox--secondary"
                        data-vv-scope="adduser"
                        v-validate="'required'"
                        data-vv-name="customer"
                      />
                      <span
                        v-if="errors.has('adduser.customer')"
                        class="help is-danger"
                      >{{ errors.first('adduser.customer') }}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label
                    for="manager"
                    class="form-label"
                  >{{ $t('manager') }}</label>
                  <vue-tags-input
                    v-model="manager"
                    :tags="managers"
                    :max-tags="1"
                    @tags-changed="inputUpdateManagers"
                    :autocomplete-items="managersToAdd"
                    :add-only-from-autocomplete="true"
                    :delete-on-backspace="false"
                    @before-adding-tag="managerTagAdded"
                    @before-deleting-tag="managerTagDeleted"
                    @max-tags-reached="managerTagMax"
                    :placeholder="managerSelected ? '':$t('addmanager_placeholder')"
                    class="add-manager"
                  />
                  <input
                    type="hidden"
                    data-vv-scope="add"
                    data-vv-name="manager"
                    :data-vv-as="$t('manager')"
                    v-model="managerSelected"
                  >
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <label
                        for="contentTitle"
                        class="form-label"
                      >{{ $t('employee_no') }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="userName"

                        v-model="userEmployeeNo"
                        :placeholder="$t('employee_placeholder')"
                      >
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-6">
                      <label
                        for="contentTitle"
                        class="form-label"
                      >{{ $t('the_title') }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="title"

                        v-model="title"
                        :placeholder="$t('title_placeholder')"
                      >
                    </div>
                    <div class="col-6">
                      <label
                        for="contentTitle"
                        class="form-label"
                      >{{ $t('division_') }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="division"

                        v-model="division"
                        :placeholder="$t('division_placeholder')"
                      >
                    </div>
                    <div class="col-6 mt-3">
                      <label
                        for="contentTitle"
                        class="form-label"
                      >{{ $t('unit_') }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="unit"

                        v-model="unit"
                        :placeholder="$t('unit_placeholder_')"
                      >
                    </div>
                    <div class="col-6 mt-3">
                      <label
                        for="contentTitle"
                        class="form-label"
                      >{{ $t('phone_number') }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="phone_number"
                        data-vv-scope="adduser"

                        v-validate="'min:7'"
                        data-vv-name="phone_number"
                        v-model="phone_number"
                        :placeholder="$t('phone_number_placeholder')"
                      >
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <label
                        for="contentTitle"
                        class="form-label"
                      >{{ $t('timezone') }}</label>
                      <multi-select
                        :class="{ 'is-danger': errors.has('adduser.timezone') }"
                        clear-on-select
                        deselect-label
                        select-label
                        v-model="selectedTimezone"
                        :options="timezoneOptions"
                        class="selectbox selectbox--secondary"
                        data-vv-scope="adduser"
                        v-validate="'required'"
                        data-vv-name="timezone"
                      />
                      <span
                        v-if="errors.has('adduser.timezone')"
                        class="help is-danger"
                      >{{ errors.first('adduser.timezone') }}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <label
                        for="contentTitle"
                        class="form-label"
                      >{{ $t('language') }}</label>
                      <multi-select
                        :class="{ 'is-danger': errors.has('adduser.language') }"
                        track-by="value"
                        label="value"
                        clear-on-select
                        deselect-label
                        select-label
                        v-model="selectLanguages"
                        data-vv-scope="adduser"
                        :options="languageOptions"
                        class="selectbox selectbox--secondary"
                        v-validate="'required'"
                        data-vv-name="language"
                      />
                      <span
                        v-if="errors.has('adduser.language')"
                        class="help is-danger"
                      >{{ errors.first('adduser.language') }}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <label
                        for="contentTitle"
                        class="form-label"
                      >{{ $t('user_type') }}</label>
                      <multi-select
                        :class="{ 'is-danger': errors.has('adduser.usertype') }"
                        track-by="name"
                        label="name"
                        clear-on-select
                        deselect-label
                        select-label
                        v-model="selectRoles"
                        :options="roleOptions"
                        class="selectbox selectbox--secondary"
                        v-validate="'required'"
                        data-vv-name="usertype"
                        data-vv-scope="adduser"
                        data-vv-value-path="name"
                      />
                      <span
                        v-if="errors.has('adduser.usertype')"
                        class="help is-danger"
                      >{{ errors.first('adduser.usertype') }}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group mb-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="swicth-checkbox">
                        <input
                          class="form-check-input"
                          v-model="sendWelcomeEmail"
                          type="checkbox"
                          id="sendWelcomeEmail"
                          :true-value="1"
                          :false-value="0"
                        >
                        <label
                          class="swicth-label"
                          for="sendWelcomeEmail"
                        >{{ $t('send_welcome_email_') }}</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="swicth-checkbox">
                        <input
                          class="form-check-input"
                          v-model="activeUser"
                          type="checkbox"
                          id="activeUser"
                          :true-value="1"
                          :false-value="0"
                        >
                        <label
                          class="swicth-label"
                          for="activeUser"
                        >{{ $t('active_user') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="button button--primary"
                    @click="saveUser"
                  >
                    {{ $t('save') }}
                  </button>
                </div>
              </div>
              <div
                class="tab-pane"
                v-show="tabPane === 'otherSettingsTab'"
              >
                <div class="form-group">
                  <div class="row">
                    <div class="col-6 mt-3">
                      <label
                        for="contentTitle"
                        class="form-label"
                      >{{ $t('team') }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="team"

                        v-model="team"
                        :placeholder="$t('team_placeholder')"
                      >
                    </div>
                    <div class="col-6 mt-3">
                      <label
                        for="contentTitle"
                        class="form-label"
                      >{{ $t('personnel_group') }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="personnel_group"

                        v-model="personnel_group"
                        :placeholder="$t('personnel_group_placeholder')"
                      >
                    </div>
                    <div class="col-6 mt-3">
                      <label
                        for="contentTitle"
                        class="form-label"
                      >{{ $t('company_name') }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="company_name"

                        v-model="company_name"
                        :placeholder="$t('company_name_placeholder')"
                      >
                    </div>
                    <div class="col-6 mt-3">
                      <label
                        for="contentTitle"
                        class="form-label"
                      >{{ $t('business_name') }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="business_name"

                        v-model="business_name"
                        :placeholder="$t('business_name_placeholder')"
                      >
                    </div>
                    <div class="col-6 mt-3">
                      <label
                        for="contentTitle"
                        class="form-label"
                      >{{ $t('business_group') }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="business_group"

                        v-model="business_group"
                        :placeholder="$t('business_group_placeholder')"
                      >
                    </div>
                    <div class="col-6 mt-3">
                      <label
                        for="contentTitle"
                        class="form-label"
                      >{{ $t('position') }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="position"

                        v-model="position"
                        :placeholder="$t('position_placeholder')"
                      >
                    </div>
                    <div class="col-6 mt-3">
                      <label
                        for="contentTitle"
                        class="form-label"
                      >{{ $t('operation') }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="operation"

                        v-model="operation"
                        :placeholder="$t('operation_placeholder')"
                      >
                    </div>
                    <div class="col-6 mt-3">
                      <label
                        for="contentTitle"
                        class="form-label"
                      >{{ $t('work_station') }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="work_station"

                        v-model="work_station"
                        :placeholder="$t('work_station_placeholder')"
                      >
                    </div>
                    <div class="col-6 mt-3">
                      <label
                        for="contentTitle"
                        class="form-label"
                      >{{ $t('personal_email') }}</label>
                      <input
                        :class="{ 'is-danger': errors.has('adduser.e-mail') }"
                        type="text"
                        class="form-control"
                        id="personal_email"
                        v-model="personal_email"

                        data-vv-scope="adduser"
                        name="personal_email"
                        :placeholder="$t('personal_email_placeholder')"
                        v-validate="'email'"
                      >
                      <span
                        v-if="errors.has('adduser.e-mail')"
                        class="help is-danger"
                      >{{ errors.first('adduser.e-mail') }}</span>
                    </div>
                    <div class="col-6 mt-3">
                      <label
                        for="employment_start_date"
                        class="form-label"
                      >{{ $t('employment_start_date') }}</label>
                      <date-picker
                        :class="{ 'is-danger': errors.has('communication.employment_start_date') }"
                        v-model="employment_start_date"
                        type="date"
                        id="employment_start_date"
                        value-type="YYYY-MM-DD 00:00:00"
                        :first-day-of-week="userLang === 'en' ? 7 : 1"
                        :lang="userLang"
                        :format="userLang === 'en' ? 'MM.DD.YYYY' : 'DD.MM.YYYY'"
                        :placeholder="$t('employment_start_date_placeholder')"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-show="errors.items.length > 0"
                  class="form-group"
                >
                  <div class="alert alert--card alert--error">
                    <p>{{ $t('error_msg_title') }}</p>
                    <ul>
                      <li
                        v-for="(error, index) in errors"
                        :key="index"
                      >
                        {{ error.msg }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="button button--primary"
                    @click="saveUser"
                  >
                    {{ $t('save') }}
                  </button>
                </div>
              </div>
              <div
                class="tab-pane"
                v-show="tabPane === 'relatedUsersTab'"
              >
                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label
                          for="manager"
                          class="form-label"
                        >{{ $t('role_manager') }}</label>
                        <vue-tags-input
                          id="manager"
                          v-model="manageruser"
                          :tags="managerusers"
                          :max-tags="1"
                          :autocomplete-items="manageruserToAdd"
                          :add-only-from-autocomplete="true"
                          :delete-on-backspace="false"
                          @before-adding-tag="relatedUserTagAdded"
                          @before-deleting-tag="relatedUserTagDeleted"
                          @max-tags-reached="managerTagMax"
                          :placeholder="$t('select_user_placeholder')"
                          class="manager"
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label
                          for="role_buddy"
                          class="form-label"
                        >{{ $t('role_buddy') }}</label>
                        <vue-tags-input
                          id="role_buddy"
                          v-model="buddyuser"
                          :tags="buddyusers"
                          :max-tags="1"
                          :autocomplete-items="buddyuserToAdd"
                          :add-only-from-autocomplete="true"
                          :delete-on-backspace="false"
                          @max-tags-reached="managerTagMax"
                          @before-adding-tag="relatedUserTagAdded"
                          @before-deleting-tag="relatedUserTagDeleted"
                          :placeholder="$t('select_user_placeholder')"
                          class="buddy"
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label
                          for="role_hrbp"
                          class="form-label"
                        >{{ $t('role_hrbp') }}</label>
                        <vue-tags-input
                          id="role_hrbp"
                          v-model="hrbpuser"
                          :tags="hrbpusers"
                          :max-tags="1"
                          :autocomplete-items="hrbpuserToAdd"
                          :add-only-from-autocomplete="true"
                          :delete-on-backspace="false"
                          @max-tags-reached="managerTagMax"
                          @before-adding-tag="relatedUserTagAdded"
                          @before-deleting-tag="relatedUserTagDeleted"
                          :placeholder="$t('select_user_placeholder')"
                          class="hrbp"
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label
                          for="role_other"
                          class="form-label"
                        >{{ $t('role_other') }}</label>
                        <vue-tags-input
                          id="role_other"
                          v-model="otheruser"
                          :tags="otherusers"
                          :max-tags="1"
                          :autocomplete-items="otheruserToAdd"
                          :add-only-from-autocomplete="true"
                          :delete-on-backspace="false"
                          @max-tags-reached="managerTagMax"
                          @before-adding-tag="relatedUserTagAdded"
                          @before-deleting-tag="relatedUserTagDeleted"
                          :placeholder="$t('select_user_placeholder')"
                          class="other"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-show="errors.items.length > 0"
                  class="form-group"
                >
                  <div class="alert alert--card alert--error">
                    <p>{{ $t('error_msg_title') }}</p>
                    <ul>
                      <li
                        v-for="(error, index) in errors"
                        :key="index"
                      >
                        {{ error.msg }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="button button--primary"
                    @click="saveUser"
                  >
                    {{ $t('save') }}
                  </button>
                </div>
              </div>

              <div
                class="tab-pane"
                v-show="tabPane === 'groupsJourneysTab' && editMode"
              >
                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <div
                        class="table-responsive"
                        v-if="apiUrl"
                      >
                        <div
                          v-if="tableIsLoading"
                          class="lds-dual-ring"
                        />
                        <vue-table
                          :class="{ 'table-fade': tableIsLoading }"
                          ref="myvuetable"
                          :api-url="apiUrl"
                          :http-fetch="myFetch"
                          :api-mode="true"
                          @vuetable:loading="tableIsLoading = true"
                          @vuetable:loaded="tableIsLoading = false"
                          @vuetable:load-error="vuetableLoadError"
                          pagination-path="meta"
                          http-method="get"
                          :append-params="moreParams"
                          :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
                          :fields="fields"
                          :no-data-template="$t('no_data_info')"
                          :css="css.table"
                          :per-page="perPage"
                          :query-params="makeQueryParams"
                          @vuetable:pagination-data="onPaginationData"
                        >
                          <template
                            slot="name"
                            slot-scope="props"
                          >
                            <span>
                              {{ props.rowData.name }}
                            </span>
                          </template>
                          <template
                            slot="activation_date"
                            slot-scope="props"
                          >
                            <span>
                              {{ formatDate(props.rowData.activation_date) }}
                            </span>
                          </template>
                          <template
                            slot="actions"
                            slot-scope="props"
                          >
                            <div
                              class="d-flex"
                              style="max-width:90px"
                            >
                              <button
                                class="ui button delete"
                                @click="onAction('groups-delete-item', props.rowData, props.rowIndex)"
                              >
                                <icon-container
                                  :width="14"
                                  :height="14"
                                  view-box="0 0 20 20"
                                  name="trash"
                                  color="#727d92"
                                >
                                  <icon-trash />
                                </icon-container>
                              </button>
                              <button
                                class="ui button"
                                @click="onAction('goto-group', props.rowData, props.rowIndex)"
                              >
                                <icon-container
                                  name="search"
                                  :width="14"
                                  :height="14"
                                  color="#727d92"
                                  view-box="0 0 14 14"
                                >
                                  <icon-search />
                                </icon-container>
                              </button>
                            </div>
                          </template>
                        </vue-table>
                        <div class="pagination__info">
                          <vuetable-pagination-info
                            :class="{ 'table-fade': tableIsLoading }"
                            ref="paginationInfo"
                            :info-template="$t('pagination_info')"
                            :no-data-template="$t('no_data_info')"
                          />
                          <div class="pagination__items">
                            <vuetable-pagination
                              ref="pagination"
                              :css="css.pagination"
                              @vuetable-pagination:change-page="onChangePage"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <div
                        class="table-responsive"
                        v-if="secondApiUrl"
                      >
                        <div
                          v-if="secondTableIsLoading"
                          class="lds-dual-ring"
                        />
                        <vue-table
                          :class="{ 'table-fade': secondTableIsLoading }"
                          ref="mysecondvuetable"
                          :api-url="secondApiUrl"
                          :api-mode="true"
                          @vuetable:loading="secondTableIsLoading = true"
                          @vuetable:loaded="secondTableIsLoading = false"
                          pagination-path="meta"
                          http-method="get"
                          :append-params="moreParams"
                          :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
                          :fields="secondfields"
                          :no-data-template="$t('no_data_info')"
                          :css="css.table"
                          :per-page="perPage"
                          :query-params="makeQueryParams"
                          @vuetable:pagination-data="onSecondPaginationData"
                        >
                          <template
                            slot="name"
                            slot-scope="props"
                          >
                            <span>
                              {{ props.rowData.name }}
                            </span>
                          </template>
                          <template
                            slot="activation_date"
                            slot-scope="props"
                          >
                            <span>
                              {{ formatDate(props.rowData.activation_date) }}
                            </span>
                          </template>
                          <template
                            slot="actions"
                            slot-scope="props"
                          >
                            <div
                              class="d-flex"
                              style="max-width:90px"
                            >
                              <button
                                class="ui button delete"
                                @click="onAction('journey-delete-item', props.rowData, props.rowIndex)"
                              >
                                <icon-container
                                  :width="14"
                                  :height="14"
                                  name="trash"
                                  color="#727d92"
                                  view-box="0 0 20 20"
                                >
                                  <icon-trash />
                                </icon-container>
                              </button>
                              <button
                                class="ui button"
                                @click="onAction('goto-journey', props.rowData, props.rowIndex)"
                              >
                                <icon-container
                                  name="search"
                                  :width="14"
                                  :height="14"
                                  color="#727d92"
                                  view-box="0 0 14 14"
                                >
                                  <icon-search />
                                </icon-container>
                              </button>
                            </div>
                          </template>
                        </vue-table>
                        <div class="pagination__info">
                          <vuetable-pagination-info
                            :class="{ 'table-fade': secondTableIsLoading }"
                            ref="secondPaginationInfo"
                            :info-template="$t('pagination_info')"
                            :no-data-template="$t('no_data_info')"
                          />
                          <div class="pagination__items">
                            <vuetable-pagination
                              ref="secondPagination"
                              :css="css.pagination"
                              @vuetable-pagination:change-page="onSecondChangePage"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import vueTagsInput from '@johmun/vue-tags-input';
import API from '@/services/';
import {
  API_GET_ROLES,
  API_GET_TIMEZONES,
  API_GET_LANGUAGES,
  API_USER_USERGROUPS,
  API_USER_JOURNEY_DATATABLES,
  API_GET_CUSTOMER_LIST_LIGHT,
  API_USER_GROUP_USER,
  API_JOURNEY_USER,
  API_USER_DATATABLES,
  API_USER_MANAGER_UNLINK,
  API_SERVER_TIME_FORMAT,
  API_USER_RELATIONSHIP,
} from '@/helpers/config';

import IconContainer from '@/components/elements/Icon.vue';
import IconTrash from '@/components/icons/Trash.vue';
import IconSearch from '@/components/icons/Search.vue';
import myTableMixin from '@/helpers/myTableMixin.vue';
import VuetablePagination from '../../../components/CustomVuetable/VuetablePagination.vue';


export default {
  name: 'ModalAddUser',
  inject: ['$validator'],
  mixins: [myTableMixin],

  data() {
    return {
      manageruser: '',
      managerusers: [],
      manageruserToAdd: [],
      buddyuser: '',
      buddyusers: [],
      buddyuserToAdd: [],
      hrbpuser: '',
      hrbpusers: [],
      hrbpuserToAdd: [],
      otheruser: '',
      otherusers: [],
      otheruserToAdd: [],
      isPending: false,
      userCustomerId: null,
      selectCustomer: null,
      customerOptions: [],
      formSending: false,
      tabPane: 'mainTab',
      user_id: null,
      userName: '',
      userSurname: '',
      userEmail: '',
      userEmployeeNo: '',
      title: '',
      unit: '',
      phone_number: '',
      division: '',
      managersToAdd: [],
      manager: '',
      employment_start_date: '',
      team: '',
      personnel_group: '',
      company_name: '',
      business_name: '',
      business_group: '',
      position: '',
      operation: '',
      work_station: '',
      personal_email: '',
      managers: [],
      managerSelected: null,
      relatedUsersSelected: [],
      relatedUsersSelectedClone: [],
      relatedUserIsDirty: false,
      data: [],
      selectLanguages: {
        name: 'en',
        value: 'English',
      },
      languageOptions: [],
      selectedTimezone: 'Europe/Istanbul',
      timezoneOptions: [],
      selectRoles: {
        name: 'customer-user',
      },
      roleOptions: [],
      sendWelcomeEmail: 0,
      activeUser: 1,
      groupPerPage: 5,
      relatedUsersFields: [
        {
          name: 'name',
          title: this.$t('name'),
          width: '40%',
        },
        {
          name: 'email',
          title: this.$t('email'),
          width: '30%',
        },
        {
          name: 'type',
          title: this.$t('role_type'),
          width: '30%',
        },
        {
          name: 'actions',
          title: this.$t('actions'),
          width: '10%',
          dataClass: 'text-center',
          titleClass: 'text-center',
        },
      ],
      fields: [
        {
          name: 'name',
          title: this.$t('group_name'),
          width: '60%',
        },
        {
          name: 'activation_date',
          title: this.$t('activation_date'),
          width: '30%',
        },
        {
          name: 'actions',
          title: this.$t('actions'),
          width: '10%',
          dataClass: 'text-center',
          titleClass: 'text-center',
        },
      ],
      secondfields: [
        {
          name: 'name',
          title: this.$t('journey_name'),
          width: '60%',
        },
        {
          name: 'activation_date',
          title: this.$t('activation_date'),
          width: '30%',
        },
        {
          name: 'actions',
          title: this.$t('actions'),
          width: '10%',
          dataClass: 'text-center',
          titleClass: 'text-center',
        },
      ],
      launchedFromList: [
        'journeyitems', 'Contents', 'modalSelectJourney', 'Journey', 'JourneyActivated', 'JourneyCompleted', 'ContentLibrary', 'Dashboard', 'calendar',
      ],
    };
  },

  components: {
    'date-picker': DatePicker,
    'icon-container': IconContainer,
    'icon-trash': IconTrash,
    'icon-search': IconSearch,
    'multi-select': Multiselect,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    VueTagsInput: vueTagsInput,
  },

  computed: {
    userId() {
      return this.$store.state.modal.userId;
    },
    isVibonsAdmin() {
      return this.$store.state.auth.isVibonsAdmin;
    },
    currentCustomerId() {
      return this.$store.state.auth.customerId;
    },
    editMode() {
      return this.$store.state.modal.editMode;
    },
    modalData() {
      return this.$store.state.modal.modalData;
    },
    userLang() {
      if (this.$store.state.auth.userInfo) {
        return this.$store.state.auth.userInfo.lang;
      }
      return 'en';
    },
    formError: {
      get() {
        return this.$store.state.modal.formError;
      },
      set(newValue) {
        this.$store.state.modal.formError = newValue;
      },
    },
    customerListFromCache() {
      return this.$store.state.auth.customerList;
    },
    apiUrl() {
      if (API_USER_USERGROUPS && this.userId) {
        return `${API_USER_USERGROUPS}/${this.userId}`;
      }
      return null;
    },
    secondApiUrl() {
      if (API_USER_JOURNEY_DATATABLES && this.userId) {
        return `${API_USER_JOURNEY_DATATABLES}/${this.userId}`;
      }
      return null;
    },

    multiProcessProgressBar: {
      get() {
        return this.$store.state.modal.multiProcessProgressBar;
      },
      set(newValue) {
        this.$store.state.modal.multiProcessProgressBar = newValue;
      },
    },
  },

  watch: {
    modalData(newValue) {
      const $newValue = newValue;
      if (this.editMode && $newValue) {
        this.userName = $newValue.first_name;
        this.userSurname = $newValue.last_name;
        this.userEmail = $newValue.email;
        this.title = $newValue.title || '';
        this.unit = $newValue.unit || '';
        this.phone_number = $newValue.phone_number || '';
        this.division = $newValue.division || '';
        if ($newValue.manager_details) {
          this.managerSelected = { id: $newValue.manager_details.id, text: `${$newValue.manager_details.full_name}[${$newValue.manager_details.email}]` };
          this.managers.push(this.managerSelected);
        }
        this.userEmployeeNo = $newValue.employee_no;
        this.selectedTimezone = $newValue.timezone;
        this.userCustomerId = $newValue.customer_id;
        this.sendWelcomeEmail = $newValue.is_welcome_email_enabled;
        this.activeUser = $newValue.is_active;
        this.team = $newValue.team || '';
        this.personnel_group = $newValue.personnel_group || '';
        this.company_name = $newValue.company_name || '';
        this.business_name = $newValue.business_name || '';
        this.business_group = $newValue.business_group || '';
        this.position = $newValue.position || '';
        this.operation = $newValue.operation || '';
        this.work_station = $newValue.work_station || '';
        this.personal_email = $newValue.personal_email || '';
        if ($newValue.employment_start_date) {
          this.employment_start_date = `${$newValue.employment_start_date.split('T')[0]} 00:00:00`;
        }
        if (this.isVibonsAdmin) {
          if (this.customerOptions) {
            this.selectCustomer = this.customerOptions.find(x => x.id === this.userCustomerId);
          }
        } else {
          this.selectCustomer = this.userCustomerId;
        }
        this.selectRoles = {
          name: $newValue.role,
        };

        if ($newValue.lang === 'en') {
          this.selectLanguages = [{
            name: $newValue.lang,
            value: 'English',
          }];
        } else if (($newValue.lang === 'tr')) {
          this.selectLanguages = [{
            name: $newValue.lang,
            value: 'Türkçe',
          }];
        }
      }
    },
    relatedUsersSelected: {
      handler(val) {
        if (this.dash.isEqual(this.relatedUsersSelectedClone, val)) {
          this.relatedUserIsDirty = false;
        } else {
          this.relatedUserIsDirty = true;
        }
      },
      immediate: true,
      deep: true,
    },
    data() {
      this.$refs.mysecondvuetable.refresh();
      this.$refs.myvuetable.refresh();
    },
    customerOptions(newValue) {
      if (!this.editMode && newValue) {
        this.selectCustomer = newValue.find(x => x.id === this.currentCustomerId);
      }
    },
    manager: 'getUsers',
    // tabPane: {
    //   handler(val) {
    //     if (val === 'relatedUsersTab') {
    //       this.loadRelatedUsers();
    //     }
    //   },
    //   immediate: true,
    // },
    manageruser: {
      handler() {
        this.getRelatedUsers('manager');
      },
    },
    buddyuser: {
      handler() {
        this.getRelatedUsers('buddy');
      },
    },
    hrbpuser: {
      handler() {
        this.getRelatedUsers('hrbp');
      },
    },
    otheruser: {
      handler() {
        this.getRelatedUsers('other');
      },
    },
  },

  async created() {
    const self = this;
    API.get(API_GET_LANGUAGES).then((response) => {
      self.languageOptions = response.data;
    });

    API.get(API_GET_TIMEZONES).then((response) => {
      self.timezoneOptions = response.data;
    });

    if (this.isVibonsAdmin) {
      if (this.customerListFromCache && this.customerListFromCache.length === 0) {
        const formData = { params: {} };
        formData.params.per_page = 1000;
        await API.get(API_GET_CUSTOMER_LIST_LIGHT, formData).then((response) => {
          this.customerOptions = response.data;
          this.$store.commit('auth/set_customer_list', response.data);
        });
      } else {
        this.customerOptions = [...this.customerListFromCache];
      }
      if (this.userCustomerId) {
        this.selectCustomer = this.customerOptions.find(x => x.id === this.userCustomerId);
      }
    }

    API.get(API_GET_ROLES).then((response) => {
      self.roleOptions = response.data;
    });

    if (this.userId && this.editMode) {
      this.user_id = this.userId;
      this.putInEditMode();
      this.loadRelatedUsers();
    }
  },

  methods: {
    loadRelatedUsers() {
      API.get(`${API_USER_RELATIONSHIP}/${this.userId}`).then((response) => {
        let cloned = this.dash.cloneDeep(response.data);
        cloned = cloned.map((y) => {
          if (y.type === 'manager') {
            if (this.managerusers.length) {
              this.managerusers = [];
            }
            this.managerusers.push({ type: y.type, text: `${y.name} [${y.email}]`, id: `${y.id}` });
          } else if (y.type === 'buddy') {
            if (this.buddyusers.length) {
              this.buddyusers = [];
            }
            this.buddyusers.push({ type: y.type, text: `${y.name} [${y.email}]`, id: `${y.id}` });
          } else if (y.type === 'hrbp') {
            if (this.hrbpusers.length) {
              this.hrbpusers = [];
            }
            this.hrbpusers.push({ type: y.type, text: `${y.name} [${y.email}]`, id: `${y.id}` });
          } else if (y.type === 'other') {
            if (this.otherusers.length) {
              this.otherusers = [];
            }
            this.otherusers.push({ type: y.type, text: `${y.name} [${y.email}]`, id: `${y.id}` });
          }
          return { user_id: y.id, relationship_type: y.type };
        });
        this.relatedUsersSelected = this.dash.cloneDeep(cloned);
        if (this.relatedUsersSelectedClone && this.relatedUsersSelectedClone.length === 0 && cloned && cloned.length) {
          this.relatedUsersSelectedClone = this.dash.cloneDeep(cloned);
        }
      });
    },
    tabNavigation(value) {
      this.tabPane = value;
    },

    closeModal() {
      this.$store.commit('modal/toggleModal');
    },

    getUsers() {
      if (!this.managerSelected) {
        this.managersToAdd = [];
        const formData = { params: {} };
        let userCustomerId = null;
        formData.params.per_page = 10000;
        if (this.isVibonsAdmin) {
          formData.params.customer_id = this.selectCustomer.id;
          userCustomerId = this.selectCustomer.id;
        }

        formData.params.search = this.manager;
        if (this.manager.length < 2) {
          return;
        }
        const searchInput = document.querySelector('.add-manager .ti-new-tag-input');
        if (searchInput) searchInput.classList.add('loading-icon');
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          API.get(`${API_USER_DATATABLES}?customer_id=${userCustomerId}`, formData)
            .then((response) => {
              this.managersToAdd = response.data.data.map(a => (
                {
                  text: `${a.first_name} ${a.last_name} [ ${a.email} ]`,
                  id: `${a.id}`,
                }
              ));
              if (searchInput) searchInput.classList.remove('loading-icon');
            })
            .catch((error) => {
              this.$helpers.displayError(error);
              if (searchInput) searchInput.classList.remove('loading-icon');
            });
        }, 300);
      }
    },
    getRelatedUsers(relationType) {
      console.log('relation:', relationType);
      const formData = { params: {} };
      let userCustomerId = null;
      let searchInput = null;
      if (this.isVibonsAdmin) {
        formData.params.customer_id = this.selectCustomer.id;
        userCustomerId = this.selectCustomer.id;
      }
      formData.params.per_page = 10000;
      switch (relationType) {
        case 'manager':
          if (this.manageruser.length < 2) {
            return;
          }
          this.manageruserToAdd = [];
          formData.params.search = this.manageruser;
          searchInput = document.querySelector('.manager .ti-new-tag-input');
          break;
        case 'buddy':
          if (this.buddyuser.length < 2) {
            return;
          }
          this.buddyuserToAdd = [];
          formData.params.search = this.buddyuser;
          searchInput = document.querySelector('.buddy .ti-new-tag-input');
          break;
        case 'hrbp':
          if (this.hrbpuser.length < 2) {
            return;
          }
          this.hrbpuserToAdd = [];
          formData.params.search = this.hrbpuser;
          searchInput = document.querySelector('.hrbp .ti-new-tag-input');
          break;
        case 'other':
          if (this.otheruser.length < 2) {
            return;
          }
          this.otheruserToAdd = [];
          formData.params.search = this.otheruser;
          searchInput = document.querySelector('.other .ti-new-tag-input');
          break;
        default:
          break;
      }
      if (searchInput) searchInput.classList.add('loading-icon');
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        API.get(`${API_USER_DATATABLES}?customer_id=${userCustomerId}`, formData)
          .then((response) => {
            const tempuserlist = response.data.data.map(a => (
              {
                type: relationType,
                text: `${a.first_name} ${a.last_name} [${a.email}]`,
                id: `${a.id}`,
              }
            ));

            switch (relationType) {
              case 'manager':
                this.manageruserToAdd = tempuserlist;
                break;
              case 'buddy':
                this.buddyuserToAdd = tempuserlist;
                break;
              case 'hrbp':
                this.hrbpuserToAdd = tempuserlist;
                break;
              case 'other':
                this.otheruserToAdd = tempuserlist;
                break;
              default:
                break;
            }

            if (searchInput) searchInput.classList.remove('loading-icon');
          })
          .catch((error) => {
            this.$helpers.displayError(error);
            if (searchInput) searchInput.classList.remove('loading-icon');
          });
      }, 300);
    },
    inputUpdateManagers(newTags) {
      this.managers = newTags;
    },

    deletePreassignedManager(toast, event) {
      this.$snotify.remove(toast.id);
      const searchInput = document.querySelector('.add-manager .ti-new-tag-input');
      if (this.editMode) {
        API.delete(`${API_USER_MANAGER_UNLINK}/${this.userId}`)
          .then((response) => {
            console.log('response:', response);
            if (response.status === 200) {
              searchInput.classList.add('assign-check-success');
              this.managerSelected = null;
              event.deleteTag();
            }
          })
          .catch((error) => {
            this.$helpers.displayError(error);
          });
      } else {
        searchInput.classList.add('assign-check-success');
        this.managerSelected = null;
        event.deleteTag();
      }

      setTimeout(() => {
        searchInput.classList.remove('assign-check-success');
      }, 2000);
    },

    relatedUserTagAdded(event) {
      if (event.tag.id) {
        const foundindex = this.relatedUsersSelected.findIndex((x => x.user_id === event.tag.id || x.relationship_type === event.tag.type));
        if (foundindex === -1) {
          event.addTag();
          this.relatedUsersSelected.push({ user_id: event.tag.id, relationship_type: event.tag.type });
        } else {
          this.$snotify.warning(this.$t('related_user_exist'), this.$t('warning'));
        }
      }
    },

    relatedUserTagDeleted(event) {
      if (event.tag.id) {
        const foundindex = this.relatedUsersSelected.findIndex((x => Number(x.user_id) === Number(event.tag.id)));
        console.log('relatedUserTagDeleted', event.tag.id, foundindex, this.relatedUsersSelected);
        if (foundindex !== -1) {
          event.deleteTag();
          this.relatedUsersSelected.splice(foundindex, 1);
        }
      }
    },
    managerTagMax(event) {
      console.log('Event:', event);
    },
    managerTagAdded(event) {
      event.addTag();
      if (event.tag.id && !this.managerSelected) {
        this.managerSelected = { id: event.tag.id, text: event.tag.text };
        const searchInput = document.querySelector('.add-manager .ti-new-tag-input');
        if (searchInput) {
          setTimeout(() => {
            searchInput.classList.remove('manager-check-success');
          }, 2000);
        }
      }
    },

    managerTagDeleted(event) {
      if (event.tag.id) {
        this.$snotify.confirm(this.$t('manager_delete_msg_body'), this.$t('manager_delete_msg_title'), {
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          buttons: [
            {
              text: this.$t('yes'),
              action: (toast) => {
                this.deletePreassignedManager(toast, event);
              },
              bold: false,
            },
            {
              text: this.$t('no'),
              action: (toast) => {
                this.$snotify.remove(toast.id);
              },
            },
          ],
        }).on('shown', (toast) => {
          this.toastHolder = toast;
          document.addEventListener('keydown', this.eventHandler, false);
        }).on('hidden', (toast) => {
          this.toastHolder = toast;
          document.removeEventListener('keydown', this.eventHandler, false);
        });
      }
    },

    addUser() {
      this.$validator.validateAll('adduser').then((result) => {
        if (result) {
          this.formSending = true;
          this.isPending = true;
          if (this.$store.state.modal.launchedFrom && !this.launchedFromList.includes(this.$store.state.modal.launchedFrom)) {
            this.$store.commit('modal/setPageToRefresh', this.$store.state.modal.launchedFrom);
          } else {
            this.$store.commit('modal/setPageToRefresh', 'Users');
          }
          const self = this;
          const successMsg = this.$t('user_added_success');
          this.$store.dispatch('modal/CREATE_USER', self.$data).then(() => {
            if (!self.formError) {
              self.$snotify.success(successMsg);
              self.managerSelected = null;
            } else {
              self.isPending = false;
              self.formError = false;
              self.formSending = false;
            }
          });
        }
      });
    },

    updateUser() {
      this.$validator.validateAll('adduser').then((result) => {
        if (result) {
          this.formSending = true;
          this.isPending = true;
          if (this.$store.state.modal.launchedFrom && !this.launchedFromList.includes(this.$store.state.modal.launchedFrom)) {
            this.$store.commit('modal/setPageToRefresh', this.$store.state.modal.launchedFrom);
          } else {
            this.$store.commit('modal/setPageToRefresh', 'Users');
          }
          const self = this;
          const successMsg = this.$t('user_added_success');
          this.$store.dispatch('modal/UPDATE_USER', self.$data).then(() => {
            if (!self.formError) {
              self.$snotify.success(successMsg);
              self.managerSelected = null;
            } else {
              self.isPending = false;
              self.formError = false;
              self.formSending = false;
            }
          });
        }
      });
    },

    saveUser() {
      this.tabPane = 'mainTab';
    },

    putInEditMode() {
      this.multiProcessProgressBar = true;
      const self = this;
      this.$store.dispatch('modal/GET_USER').then(() => {
        self.multiProcessProgressBar = false;
      });
    },

    onAction(action, data) {
      const groupID = data.id;
      this.groupIDHolder = groupID;
      const groudIdTag = `g${data.id}`;
      this.groudIdTagHolder = groudIdTag;
      const journeyID = data.id;
      const journeyIdTag = `j${data.id}`;
      const userID = this.userId;
      this.userIDHolder = userID;
      this.journeyIdTagHolder = journeyIdTag;
      this.journeyIDHolder = journeyID;

      if (action === 'groups-delete-item') {
        if (!this.snotifyQueueList.includes(groudIdTag)) {
          this.snotifyQueueList.push(groudIdTag);
          this.$snotify.confirm(this.$t('group_delete_msg_body', { name: data.name }), this.$t('group_delete_msg_title'), {
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            buttons: [
              {
                text: this.$t('yes'),
                action: (toast) => {
                  this.deleteConfirm(toast, groudIdTag, groupID, userID);
                },
                bold: false,
              },
              {
                text: this.$t('no'),
                action: (toast) => {
                  this.deleteCancel(toast, groudIdTag);
                },
              },
            ],
          }).on('shown', (toast) => {
            this.toastHolder = toast;
            document.addEventListener('keydown', this.groupEventHandler, false);
          }).on('hidden', (toast) => {
            this.toastHolder = toast;
            document.removeEventListener('keydown', this.groupEventHandler, false);
          });
        }
      }

      if (action === 'goto-group') {
        this.closeModal();
        this.$router.push({ path: `/group/users/${groupID}` });
      }

      if (action === 'goto-journey') {
        this.closeModal();
        this.$router.push({ path: `/journey/detail/${journeyID}` });
      }

      if (action === 'journey-delete-item') {
        if (!this.snotifyQueueList.includes(journeyIdTag)) {
          this.snotifyQueueList.push(journeyIdTag);
          this.$snotify.confirm(this.$t('journey_delete_msg_body', { name: data.name }), this.$t('journey_delete_msg_title'), {
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            buttons: [
              {
                text: this.$t('yes'),
                action: (toast) => {
                  this.deleteConfirmJourney(toast, journeyIdTag, journeyID, userID);
                },
                bold: false,
              },
              {
                text: this.$t('no'),
                action: (toast) => {
                  this.deleteCancel(toast, journeyIdTag);
                },
              },
            ],
          }).on('shown', (toast) => {
            this.toastHolder = toast;
            document.addEventListener('keydown', this.journeyEventHandler, false);
          }).on('hidden', (toast) => {
            this.toastHolder = toast;
            document.removeEventListener('keydown', this.journeyEventHandler, false);
          });
        }
      }
    },

    deleteConfirmJourney(toast, journeyIdTag, journeyID, userID) {
      this.$snotify.remove(toast.id);
      const snotifyIndex = this.snotifyQueueList.indexOf(journeyIdTag);
      if (snotifyIndex > -1) {
        this.snotifyQueueList.splice(snotifyIndex, 1);
      }
      this.secondTableIsLoading = true;

      const request = axios.create({
        headers: {
          Authorization: `Bearer ${this.$store.getters['auth/token']}`,
        },
      });

      request.delete(API_JOURNEY_USER,
        {
          data: {
            journey_id: journeyID,
            user_id: userID,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.$snotify.success(response.data.message);
            this.$refs.mysecondvuetable.refresh();
            setTimeout(() => {
              this.secondTableIsLoading = false;
            }, 200);
          }
        })
        .catch((error) => {
          this.$helpers.displayError(error);
        });
    },

    deleteConfirm(toast, groudIdTag, groupID, userID) {
      this.$snotify.remove(toast.id);
      const snotifyIndex = this.snotifyQueueList.indexOf(groudIdTag);
      if (snotifyIndex > -1) {
        this.snotifyQueueList.splice(snotifyIndex, 1);
      }
      this.tableIsLoading = true;

      const request = axios.create({
        headers: {
          Authorization: `Bearer ${this.$store.getters['auth/token']}`,
        },
      });

      request.delete(API_USER_GROUP_USER,
        {
          data: {
            group_id: groupID,
            user_id: userID,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.$snotify.success(response.data.message);
            this.$refs.myvuetable.refresh();
            setTimeout(() => {
              this.tableIsLoading = false;
            }, 200);
          }
        })
        .catch((error) => {
          this.$helpers.displayError(error);
        });
    },

    groupEventHandler(event) {
      event.preventDefault();
      const key = event.key || event.keyCode;
      if (key === 'Enter' || key === 13) {
        this.deleteConfirm(this.toastHolder, this.groudIdTagHolder, this.groupIDHolder, this.userIDHolder);
      } else if (key === 'Escape' || key === 'Esc' || key === 27) {
        this.deleteCancel(this.toastHolder, this.groudIdTagHolder);
      }
    },

    journeyEventHandler(event) {
      event.preventDefault();
      const key = event.key || event.keyCode;
      if (key === 'Enter' || key === 13) {
        this.deleteConfirmJourney(this.toastHolder, this.journeyIdTagHolder, this.journeyIDHolder, this.userIDHolder);
      } else if (key === 'Escape' || key === 'Esc' || key === 27) {
        this.deleteCancel(this.toastHolder, this.journeyIdTagHolder);
      }
    },
    formatDate(value) {
      let fmt = null;
      let date = null;
      if (value) {
        if (this.userLang === 'en') {
          fmt = 'MM.DD.YYYY HH:mm:ss';
        }
        if (this.userLang === 'tr') {
          fmt = 'DD.MM.YYYY HH:mm:ss';
        }
        date = moment(value, API_SERVER_TIME_FORMAT).format(fmt);
      }
      return date;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-check {
  .form-label {
    font-size: 0.9rem;
    margin-left: 1rem;
    margin-bottom: 0;
    color: #142b58;
  }
}
.form-control {
  &.is-danger {
    border-color: #f27299;
  }
}
.modal-body {
  h4 {
    color: #727d92;
  }
}
.blur {
  filter: blur(3px);
}
.pagination__items {
  width: 100%;
  text-align: center;
}
button.ui {
  padding-left: 10px;
  padding-right: 10px;
}
.form-label {
  text-transform: uppercase;
}
</style>
