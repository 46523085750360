<template>
  <div class="page mb-4 pb-4">
    <app-header
      child-header-class="container"
      :child-header-navigation="userSubNavigation"
      is-child-header-left-actions
    >
      <filter-perpage
        @searchFor="setSearchFor"
        @perPage="setPerPage"
      />
      <dropdown
        class-name="filter"
        :filter-on="the_filter.journey_type || the_filter.only_active_users"
      >
        <template slot="icon">
          <i
            class="fa fa-filter button button--shadow button--type-icon size-sm d-flex align-items-center"
            style="cursor: pointer;color: rgb(114, 125, 146);"
          />
        </template>
        <template slot="body">
          <div class="d-block p-2">
            <div
              class="d-block"
            >
              <label
                for="filter-only_active_users"
                class="checkbox"
              >
                <input
                  type="checkbox"
                  id="filter-only_active_users"
                  :true-value="true"
                  :false-value="false"
                  v-model="the_filter.only_active_users"
                >
                {{ $t('only_active_users') }}
              </label>
            </div>
          </div>
          <div class="d-block p-2">
            <div
              class="d-block"
            >
              <label
                for="filter-journey_type"
                class="checkbox"
              >
                <input
                  type="checkbox"
                  id="filter-journey_type"
                  :true-value="true"
                  :false-value="false"
                  v-model="the_filter.journey_type"
                >
                {{ $t('journey_type') }}
              </label>
            </div>
            <div
              class="d-block"
              v-if="the_filter.journey_type"
            >
              <div class="swicth-checkbox">
                <input
                  type="checkbox"
                  v-model="journey_type"
                  id="get_journey_type"
                  true-value="ON_BOARDING"
                  false-value="STANDARD"
                >
                <label
                  class="swicth-label"
                  for="get_journey_type"
                >{{ journey_type }}</label>
              </div>
            </div>
          </div>
        </template>
      </dropdown>
    </app-header>

    <div class="container">
      <div
        v-if="tableIsLoading"
        class="lds-dual-ring"
      />
      <div class="table-responsive">
        <vue-table
          :class="{ 'table-fade': tableIsLoading }"
          ref="myvuetable"
          :api-url="apiUrl"
          :http-fetch="myFetch"
          :api-mode="true"
          @vuetable:loading="tableIsLoading = true"
          @vuetable:loaded="tableIsLoading = false"
          @vuetable:load-error="vuetableLoadError"
          pagination-path="meta"
          http-method="get"
          :append-params="moreParams"
          :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
          :fields="fields"
          :no-data-template="$t('no_data_info')"
          :css="css.table"
          :per-page="perPage"
          :query-params="makeQueryParams"
          @vuetable:pagination-data="onPaginationData"
        >
          <template
            slot="name"
            slot-scope="props"
          >
            <router-link
              :to="{ path: `/dashboard/journeys_v2/users/${props.rowData.journey2_id}`, query: {journey: props.rowData.name}}"
            >
              {{ props.rowData.name }}
            </router-link>
          </template>
          <template
            slot="created_at-slot"
            slot-scope="props"
          >
            {{ getCurrentLocaleDate(props.rowData.created_at) }}
          </template>
          <template
            slot="created_by-slot"
            slot-scope="props"
          >
            <div class="">
              {{ props.rowData.created_user?.first_name }} {{ props.rowData.created_user?.last_name }}
            </div>
          </template>
          <template
            slot="actions"
            slot-scope="props"
          >
            <div
              class="dropdown"
              @mouseover="dropdownToggle(`three${props.rowIndex}`)"
              @mouseleave="hideDropdown"
            >
              <span class="card--actions dropdown__toggle">
                <icon-container
                  :width="20"
                  :height="20"
                  view-box="0 0 24 24"
                  name="threedots"
                  color="#727d92"
                >
                  <icon-threedots />
                </icon-container>
              </span>

              <div
                class="dropdown__menu"
                :ref="`three${props.rowIndex}`"
                v-if="dropdownIndex === 'three' + props.rowIndex"
                v-on-clickaway="hideDropdown"
              >
                <span
                  class="dropdown__button button"
                  @click="onAction('progress-report', props.rowData)"
                >
                  <icon-container
                    name="content"
                    view-box="0 0 13 14"
                    color="#727d92"
                    :is-icon-class="false"
                  >
                    <icon-download />
                  </icon-container>
                  {{ $t("download_journey_progress_report") }}
                </span>
              </div>
            </div>
          </template>
        </vue-table>
        <div class="pagination__info">
          <vuetable-pagination-info
            :class="{ 'table-fade': tableIsLoading }"
            ref="paginationInfo"
            :info-template="$t('pagination_info')"
            :no-data-template="$t('no_data_info')"
          />
          <div class="pagination__items">
            <vuetable-pagination
              ref="pagination"
              :css="css.pagination"
              @vuetable-pagination:change-page="onChangePage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';
import { directive as onClickaway } from 'vue-clickaway';
import { mapState } from 'vuex';
import IconContainer from '@/components/elements/Icon.vue';
import Dropdown from '@/components/elements/Dropdown.vue';
import IconThreedots from '@/components/icons/Threedots.vue';
import AppHeader from '@/components/AppHeader.vue';
import {
  API_REPORT_JOURNEYS_V2,
  API_REPORT_EXCEL_JOURNEYS_V2,
} from '@/helpers/config';
import FilterPerpage from '@/components/FilterPerpage.vue';
import myTableMixin from '@/helpers/myTableMixin.vue';
import IconDownload from '@/components/icons/Download.vue';
import VuetablePagination from '../../components/CustomVuetable/VuetablePagination.vue';

export default {
  name: 'ReportJourneysV2',
  mixins: [myTableMixin],
  data() {
    return {
      moreParams: {
        customer_id: this.$store.state.auth.customerId,
      },
      the_filter: {
        journey_type: false,
        only_active_users: false,
      },
      journey_type: 'STANDARD', // STANDARD, ON_BOARDING
    };
  },

  components: {
    'icon-container': IconContainer,
    'icon-download': IconDownload,
    'icon-threedots': IconThreedots,
    'app-header': AppHeader,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    Dropdown,
    'filter-perpage': FilterPerpage,
  },

  directives: {
    'on-clickaway': onClickaway,
  },

  watch: {
    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'ReportJourneys') {
        setTimeout(() => {
          this.$refs.myvuetable.refresh();
          this.refreshPage = false;
          this.pageToRefresh = null;
        }, 500);
      }
    },
    journey_type: {
      handler(val) {
        if (this.the_filter.journey_type) {
          this.$set(this.moreParams, 'journey_type', val);
        }
      },
      immediate: true,
    },
    the_filter: {
      handler(filter) {
        if (filter.journey_type) {
          this.$set(this.moreParams, 'journey_type', this.journey_type);
        }
        if (!filter.journey_type) {
          this.$delete(this.moreParams, 'journey_type');
        }
        if (filter.only_active_users) {
          this.$set(this.moreParams, 'only_active_users', 1);
        } else {
          this.$delete(this.moreParams, 'only_active_users');
        }
      },
      immediate: true,
      deep: true,
    },
  },

  computed: {
    ...mapState([
      'modal',
    ]),

    apiUrl() {
      if (API_REPORT_JOURNEYS_V2) {
        return `${API_REPORT_JOURNEYS_V2}`;
      }
      return null;
    },

    customerId() {
      return this.$store.state.auth.customerId;
    },

    isVibonsAdmin() {
      if (this.$store.state && this.$store.state.auth) {
        return this.$store.state.auth.isVibonsAdmin;
      }
      return false;
    },

    userSubNavigation() {
      if (this.isVibonsAdmin) {
        return [
          {
            text: this.$t('overview'),
            url: '/dashboard/overview',
          },
          {
            text: this.$t('journeys'),
            url: '/dashboard/journeys',
          },
          {
            text: this.$t('journeys_v2'),
            url: '/dashboard/journeys_v2',
          },
          {
            text: this.$t('users'),
            url: '/dashboard/users',
          },
          {
            text: this.$t('quiz_survey'),
            url: '/dashboard/quiz-survey',
          },
          {
            text: this.$t('customers_sub'),
            url: '/dashboard/customers',
          },
          {
            text: this.$t('report_demands'),
            url: '/dashboard/reportdemands',
          },
        ];
      }
      return [
        {
          text: this.$t('overview'),
          url: '/dashboard/overview',
        },
        {
          text: this.$t('journeys'),
          url: '/dashboard/journeys',
        },
        {
          text: this.$t('users'),
          url: '/dashboard/users',
        },
        {
          text: this.$t('quiz_survey'),
          url: '/dashboard/quiz-survey',
        },
        {
          text: this.$t('report_demands'),
          url: '/dashboard/reportdemands',
        },
      ];
    },

    fields() {
      return [
        {
          name: 'name',
          title: this.$t('journey_name'),
          sortField: 'name',
          width: '34%',
        },
        {
          name: 'user_count',
          title: this.$t('users_count'),
          sortField: 'user_count',
          width: '12%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'opened_users_count',
          title: this.$t('opened_user_count'),
          sortField: 'opened_users_count',
          width: '12%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'completed_users_count',
          title: this.$t('completed_users_count'),
          sortField: 'completed_users_count',
          width: '12%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'created_at-slot',
          title: this.$t('created_at'),
          sortField: 'created_at',
          width: '15%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'created_by-slot',
          title: this.$t('created_by'),
          width: '15%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'actions',
          title: this.$t('actions'),
          width: '10%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        }];
    },
  },

  methods: {
    calcRate(rowdata) {
      let rating = 0;
      if (rowdata) {
        rating = parseInt(Math.round((rowdata.completed_users_count * 100) / rowdata.users_count), 10);
      }
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(rating)) return 0;
      if (rating > 100) return 100;
      return rating;
    },
    makeQueryParams(sortOrder, currentPage, perPage) {
      // console.log('JSON:', JSON.stringify(sortOrder, null, 2));
      if (sortOrder.length > 0) {
        let sortString = sortOrder[0].sortField;
        if (sortString.endsWith('-slot')) {
          sortString = sortString.replace('-slot', '');
        }
        return {
          sort: sortString,
          order: sortOrder[0].direction,
          page: currentPage,
          per_page: perPage,
        };
      }
      return {
        sort: 'created_at',
        order: 'desc',
        page: currentPage ?? 1,
        per_page: this.perPage,
      };
    },

    onAction(action, data) {
      const journeyId = data.journey2_id;
      if (action === 'progress-report') {
        console.log('Not implemented yet!', data?.journey2_id);
        if (journeyId) {
          const obj = {
            modalType: 'modalReportDownloadV2',
            reportDownloadUrl: API_REPORT_EXCEL_JOURNEYS_V2,
            reportModalTitle: this.$t('journey_report_w_name', { name: data.name }),
            reportDownloadRequest: 'post',
            dateOnly: true,
            reportDownloadHasStartDate: true,
            reportDownloadHasEndDate: true,
            reportDownloadPayload: {
              journey2_id: journeyId,
            },
          };
          this.$helpers.toggleModal(obj);
        }
      }
    },
    getCurrentLocaleDate(dateVal) {
      if (this.$store.state.auth.userInfo && this.$store.state.auth.userInfo.lang) {
        return this.$helpers.getUserLangBasedDateTimeFormat(dateVal, this.$store.state.auth.userInfo.lang, 'datetime');
      }
      return dateVal;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .filter-bp__btn {
  border: none;
  padding: 0;
  cursor: pointer;
  }
::v-deep .swicth-checkbox {
  min-width: 200px;
}
::v-deep .swicth-checkbox label {
    color: #727d92;
}
</style>
