var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("app-header", { attrs: { "is-child-header-left-actions": "" } }, [
        _c("div", { staticClass: "button-search ml-4" }, [
          _c("div", { staticClass: "search-box" }, [
            _vm.filterText
              ? _c(
                  "span",
                  {
                    staticClass: "icon icon-reset",
                    on: { click: _vm.resetFilter },
                  },
                  [
                    _c(
                      "icon-container",
                      { attrs: { name: "reset", "view-box": "0 0 20 20" } },
                      [_c("icon-reset")],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "span",
              {
                staticClass: "icon-search",
                on: {
                  click: function ($event) {
                    return _vm.doFilter()
                  },
                },
              },
              [
                _c(
                  "icon-container",
                  {
                    attrs: {
                      name: "search",
                      width: 14,
                      height: 14,
                      color: "#727d92",
                      "view-box": "0 0 14 14",
                    },
                  },
                  [_c("icon-search")],
                  1
                ),
              ],
              1
            ),
            _c("input", {
              directives: [
                { name: "sanitize", rawName: "v-sanitize" },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filterText,
                  expression: "filterText",
                },
              ],
              ref: "searchBox",
              staticClass: "form-control",
              attrs: {
                type: "text",
                placeholder: _vm.$t("search_placeholder"),
              },
              domProps: { value: _vm.filterText },
              on: {
                keydown: function ($event) {
                  return _vm.doFilter()
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.filterText = $event.target.value
                },
              },
            }),
          ]),
        ]),
      ]),
      _c("nav", { staticClass: "navigation navigation--ternary" }, [
        _c("div", { staticClass: "container" }, [
          _c(
            "ul",
            {
              staticClass: "navigation__list nav",
              attrs: { id: "myJourneys", role: "tablist" },
            },
            [
              _c("li", { staticClass: "navigation__item" }, [
                _c(
                  "button",
                  {
                    staticClass: "navigation__link",
                    class: { active: _vm.activeJourneyTab === "private" },
                    on: {
                      click: function ($event) {
                        _vm.activeJourneyTab = "private"
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("private_journey")) + " ")]
                ),
              ]),
              _c("li", { staticClass: "navigation__item" }, [
                _c(
                  "button",
                  {
                    staticClass: "navigation__link",
                    class: { active: _vm.activeJourneyTab === "public" },
                    on: {
                      click: function ($event) {
                        _vm.activeJourneyTab = "public"
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("public_journey")) + " ")]
                ),
              ]),
            ]
          ),
        ]),
      ]),
      _c("main", { staticClass: "page__body" }, [
        _vm.isLoading ? _c("div", { staticClass: "lds-dual-ring" }) : _vm._e(),
        _c("div", { staticClass: "container-fluid container-cards mb-4" }, [
          _c("div", { staticClass: "tab-content" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeJourneyTab === "private",
                    expression: "activeJourneyTab === 'private'",
                  },
                ],
                staticClass: "tab-pane",
              },
              [
                _c(
                  "transition-group",
                  {
                    staticClass:
                      "d-flex flex-wrap justify-center items-center px-4",
                    style: { "--total": 20 },
                    attrs: { name: "slide-in", tag: "div" },
                  },
                  [
                    _c(
                      "div",
                      {
                        key: "createCard",
                        staticClass: "card card--create card-button",
                        on: { click: _vm.openModal },
                      },
                      [
                        _c(
                          "icon-container",
                          {
                            attrs: {
                              width: 82,
                              height: 82,
                              name: "plus",
                              color: "#E3E3E3",
                              "view-box": "0 0 448 448",
                            },
                          },
                          [_c("icon-plus")],
                          1
                        ),
                        _c("div", { staticClass: "card__footer" }, [
                          _c(
                            "span",
                            {
                              staticClass: "button button--border size-sm",
                              attrs: { role: "button" },
                            },
                            [_vm._v(_vm._s(_vm.$t("create_a_journey")))]
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._l(_vm.privateJourneyList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          staticClass: "card card--catalog",
                          class: { is_public: item.is_public },
                          style: { "--i": index % _vm.perPage },
                        },
                        [
                          _vm.isItemNew(item.activation_date)
                            ? _c("div", { staticClass: "ribbon" }, [
                                _c("span", [_vm._v(_vm._s(_vm.$t("new")))]),
                              ])
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass: "cardbody-height",
                              on: {
                                click: function ($event) {
                                  return _vm.goToJourney(item)
                                },
                              },
                            },
                            [
                              _c("figure", {
                                staticClass: "card__media_home",
                                style: {
                                  backgroundImage: `url('${_vm.getItemCover(
                                    item.cover
                                  )}')`,
                                },
                              }),
                              _c("div", { staticClass: "card__body" }, [
                                _c(
                                  "h3",
                                  {
                                    staticClass: "card__title",
                                    attrs: { title: item.name },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm._f("truncate")(item.name)) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "card__footer" }, [
                            item.assigned_users_count
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.top",
                                        value: _vm.$t("total_assigned_users", {
                                          count: item.assigned_users_count,
                                        }),
                                        expression:
                                          "$t('total_assigned_users', { count: item.assigned_users_count })",
                                        modifiers: { top: true },
                                      },
                                    ],
                                    staticClass: "total-assigned-users",
                                  },
                                  [
                                    _c(
                                      "icon-container",
                                      {
                                        attrs: {
                                          width: 22,
                                          height: 22,
                                          name: "assigned-users",
                                          color: "#fff",
                                          "view-box": "0 0 256 256",
                                        },
                                      },
                                      [_c("icon-users")],
                                      1
                                    ),
                                    _c("div", [
                                      _vm._v(_vm._s(item.assigned_users_count)),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "dropdown",
                                on: {
                                  mouseover: function ($event) {
                                    return _vm.dropdownToggle(index, "Private")
                                  },
                                  mouseleave: _vm.hideDropdown,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "card--actions dropdown__toggle",
                                  },
                                  [
                                    _c(
                                      "icon-container",
                                      {
                                        attrs: {
                                          name: "threedotsvertical",
                                          width: 5,
                                          height: 22,
                                          "view-box": "0 0 5 22",
                                        },
                                      },
                                      [_c("icon-threedotsvertical")],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.dropdownIndex === index + "Private"
                                  ? _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "on-clickaway",
                                            rawName: "v-on-clickaway",
                                            value: _vm.hideDropdown,
                                            expression: "hideDropdown",
                                          },
                                        ],
                                        ref: `dropdownBox${index}Private`,
                                        refInFor: true,
                                        staticClass: "dropdown__menu",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "dropdown__button button",
                                            on: {
                                              click: function ($event) {
                                                return _vm.duplicateJourney(
                                                  item.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "icon-container",
                                              {
                                                attrs: {
                                                  name: "duplicate",
                                                  "view-box": "0 0 15 15",
                                                },
                                              },
                                              [_c("icon-duplicate")],
                                              1
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("duplicate")) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "dropdown__button button",
                                            on: {
                                              click: function ($event) {
                                                return _vm.editJourney(
                                                  item,
                                                  "mainTab"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "icon-container",
                                              {
                                                attrs: {
                                                  name: "setting",
                                                  "view-box": "0 0 15 15",
                                                },
                                              },
                                              [_c("icon-setting")],
                                              1
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("settings")) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                        !_vm.canNotEdit(item.is_public)
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "dropdown__button button",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteJourney(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "icon-container",
                                                  {
                                                    attrs: {
                                                      name: "trash",
                                                      "view-box": "0 0 24 24",
                                                    },
                                                  },
                                                  [_c("icon-trash")],
                                                  1
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("delete")) +
                                                    " "
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                !_vm.isLoading
                  ? _c("Observer", {
                      on: { intersect: _vm.privateIntersected },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeJourneyTab === "public",
                    expression: "activeJourneyTab === 'public'",
                  },
                ],
                staticClass: "tab-pane",
              },
              [
                _vm.isPublicLoading
                  ? _c("div", { staticClass: "lds-dual-ring" })
                  : _vm._e(),
                _c(
                  "transition-group",
                  {
                    staticClass:
                      "d-flex flex-wrap justify-center items-center px-4",
                    style: {
                      "--total": _vm.publicJourneyList
                        ? _vm.publicJourneyList.length
                        : 0,
                    },
                    attrs: { name: "slide-in", tag: "div" },
                  },
                  [
                    _c(
                      "div",
                      {
                        key: "createCardPublic",
                        staticClass: "card card--create card-button",
                        on: { click: _vm.openModal },
                      },
                      [
                        _c(
                          "icon-container",
                          {
                            attrs: {
                              width: 82,
                              height: 82,
                              name: "plus",
                              color: "#E3E3E3",
                              "view-box": "0 0 448 448",
                            },
                          },
                          [_c("icon-plus")],
                          1
                        ),
                        _c("div", { staticClass: "card__footer" }, [
                          _c(
                            "span",
                            {
                              staticClass: "button button--border size-sm",
                              attrs: { role: "button" },
                            },
                            [_vm._v(_vm._s(_vm.$t("create_a_journey")))]
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._l(_vm.publicJourneyList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          staticClass: "card card--catalog is_public",
                          style: { "--i": index },
                        },
                        [
                          _vm.isItemNew(item.activation_date)
                            ? _c("div", { staticClass: "ribbon" }, [
                                _c("span", [_vm._v(_vm._s(_vm.$t("new")))]),
                              ])
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass: "cardbody-height",
                              on: {
                                click: function ($event) {
                                  return _vm.goToJourney(item)
                                },
                              },
                            },
                            [
                              _c("figure", {
                                staticClass: "card__media_home",
                                style: {
                                  backgroundImage: `url('${_vm.getItemCover(
                                    item.cover
                                  )}')`,
                                },
                              }),
                              _c("div", { staticClass: "card__body" }, [
                                _c("h3", { staticClass: "card__title" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm._f("truncate")(item.name)) +
                                      " "
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "card__footer" }, [
                            item.assigned_users_count
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.top",
                                        value: _vm.$t("total_assigned_users", {
                                          count: item.assigned_users_count,
                                        }),
                                        expression:
                                          "$t('total_assigned_users', { count: item.assigned_users_count })",
                                        modifiers: { top: true },
                                      },
                                    ],
                                    staticClass: "total-assigned-users",
                                  },
                                  [
                                    _c(
                                      "icon-container",
                                      {
                                        attrs: {
                                          width: 22,
                                          height: 22,
                                          name: "assigned-users",
                                          color: "#E3E3E3",
                                          "view-box": "0 0 256 256",
                                        },
                                      },
                                      [_c("icon-users")],
                                      1
                                    ),
                                    _c("div", [
                                      _vm._v(_vm._s(item.assigned_users_count)),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "dropdown",
                                on: {
                                  mouseover: function ($event) {
                                    return _vm.dropdownToggle(index, "Public")
                                  },
                                  mouseleave: _vm.hideDropdown,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "card--actions dropdown__toggle",
                                  },
                                  [
                                    _c(
                                      "icon-container",
                                      {
                                        attrs: {
                                          name: "threedotsvertical",
                                          width: 5,
                                          height: 22,
                                          "view-box": "0 0 5 22",
                                        },
                                      },
                                      [_c("icon-threedotsvertical")],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.dropdownIndex === index + "Public"
                                  ? _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "on-clickaway",
                                            rawName: "v-on-clickaway",
                                            value: _vm.hideDropdown,
                                            expression: "hideDropdown",
                                          },
                                        ],
                                        ref: `dropdownBox${index}Public`,
                                        refInFor: true,
                                        staticClass: "dropdown__menu",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "dropdown__button button",
                                            on: {
                                              click: function ($event) {
                                                return _vm.duplicateJourney(
                                                  item.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "icon-container",
                                              {
                                                attrs: {
                                                  name: "duplicate",
                                                  "view-box": "0 0 15 15",
                                                },
                                              },
                                              [_c("icon-duplicate")],
                                              1
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("duplicate")) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "dropdown__button button",
                                            on: {
                                              click: function ($event) {
                                                return _vm.editJourney(
                                                  item,
                                                  "mainTab"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "icon-container",
                                              {
                                                attrs: {
                                                  name: "setting",
                                                  "view-box": "0 0 15 15",
                                                },
                                              },
                                              [_c("icon-setting")],
                                              1
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("settings")) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                        !_vm.canNotEdit(item.is_public)
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "dropdown__button button",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteJourney(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "icon-container",
                                                  {
                                                    attrs: {
                                                      name: "trash",
                                                      "view-box": "0 0 24 24",
                                                    },
                                                  },
                                                  [_c("icon-trash")],
                                                  1
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("delete")) +
                                                    " "
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                !_vm.isPublicLoading
                  ? _c("Observer", { on: { intersect: _vm.publicIntersected } })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }