<template>
  <div
    class="tab-pane show active"
  >
    <div class="form-group">
      <label
        for="url"
        class="form-label"
      >{{ $t('external_link') }}</label>
      <input
        :class="{ 'is-danger': errors.has('externallink.url') }"
        type="text"
        :disabled="disabled"
        class="form-control"
        id="url"
        ref="urlInput"
        v-model="url"
        data-vv-scope="externallink"
        data-vv-name="url"
        :data-vv-as="$t('external_link')"
        v-validate="{required: true}"
        @input="handleUrl"
      >
      <span
        v-show="errors.has('externallink.url')"
        class="help is-danger"
      >{{ errors.first('externallink.url') }}</span>
    </div>
    <div
      v-show="urlValid"
      class="form-group"
      :class="{ 'is-danger': errors.has('externallink.title') }"
    >
      <label
        for="contentTitle"
        class="form-label"
      >{{ $t('title').toUpperCase() }}</label>
      <input
        type="text"
        class="form-control"
        id="contentTitle"
        v-model="title"
        :disabled="disabled && !canEditOnlyItem"
        data-vv-scope="externallink"
        data-vv-name="title"
        :data-vv-as="$t('title')"
        v-validate="'required|max:100'"
        @input="handleTitle"
      >
      <span
        v-show="errors.has('externallink.title')"
        class="help is-danger"
      >{{ errors.first('externallink.title') }}</span>
    </div>
    <div
      v-show="urlValid"
      class="quiz-assubcomponent"
    >
      <div class="title">
        <h5 class="modal-title">
          {{ $t('add_question') }}
        </h5>
        <div
          class="swicth-checkbox"
        >
          <input
            type="checkbox"
            id="isAddingQuestion"
            v-model="isAddingQuestion"
            :true-value="1"
            :false-value="0"
          >
          <label
            class="swicth-label"
            for="isAddingQuestion"
          />
        </div>
      </div>
      <div class="warning-box">
        <div class="desc-text">
          <icon-container
            width="27"
            height="30"
            view-box="0 0 27 30"
            name="lamp"
            color="#727d92"
          >
            <icon-lamp />
          </icon-container>
          <div class="description">
            {{ $t('quiz_remark') }}
          </div>
        </div>
      </div>
      <collapse-transition
        :duration="500"
        dimension="height"
        v-show="isAddingQuestion"
      >
        <quiz-component
          :disabled="disabledTemp"
          :can-edit-only-item="canEditOnlyItemTemp"
          :quiz-type="'content'"
          as-scope="externallinkquiz"
          :repetitive_max_question_per_day="repetitiveMaxQuestionPerDayTemp"
          :repetitive_correct_answer_count="repetitiveCorrectAnswerCountTemp"
        />
      </collapse-transition>
    </div>
    <div
      v-show="errors.items.length > 0"
      class="form-group"
    >
      <div class="alert alert--card alert--error">
        <p>{{ $t('error_msg_title') }}</p>
        <ul>
          <li
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error.msg }}
          </li>
        </ul>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="button button--primary"
        @click="editMode ? updateContent() : createContent()"
        :disabled="isPending || (disabled && !canEditOnlyItem)"
      >
        <span
          v-if="!isPending"
        >
          {{ editMode ? $t('update_button') : $t('create_button') }}
        </span>
        <div
          class="lds-ellipsis"
          v-if="isPending"
        >
          <div />
          <div />
          <div />
          <div />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import urlMetadata from 'url-metadata';
import { WHITELIST_URLS, CORS_PROXY_URL } from '@/helpers/config';
import subQuizMixin from '@/helpers/subQuizMixin.vue';

export default {
  name: 'ExternalLinkContent',
  inject: ['$validator'],
  mixins: [subQuizMixin],

  props: {
    name: {
      type: String,
      default: '',
    },
    thumbnailUrl: {
      type: String,
      default: '',
    },
    externalUrl: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    canEditOnlyItem: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isPending: false,
      thumbnail: null,
      thumbnailErrorField: false,
      title: '',
      url: '',
      urlValid: false,
      updateInfo: false,
      dataHolder: null,
      toastHolder: null,
    };
  },

  computed: {
    isVibonsAdmin() {
      if (this.$store.state && this.$store.state.auth) {
        return this.$store.state.auth.isVibonsAdmin;
      }
      return false;
    },
    modalData() {
      return this.$store.state.modal.modalData;
    },
    modalError() {
      return this.$store.state.modal.formError;
    },
  },

  watch: {
    modalError: {
      handler(newVal) {
        if (newVal) {
          this.isPending = false;
        }
      },
      immediate: true,
    },

    thumbnailUrl: {
      handler(newVal) {
        if (this.editMode) {
          this.thumbnail = [];
          this.thumbnail.push(newVal);
        }
      },
      immediate: true,
    },

    externalUrl: {
      handler(newVal) {
        if (this.editMode) {
          this.url = newVal;
          this.urlValid = true;
        }
      },
      immediate: true,
    },

    url: {
      handler(newVal) {
        if (!newVal && this.editMode === false) {
          this.urlValid = false;
          this.title = '';
        }
      },
      immediate: true,
    },

    name: {
      handler(newVal) {
        this.title = newVal;
      },
      immediate: true,
    },

    title: {
      handler(newVal) {
        this.$emit('setTitle', newVal);
      },
      immediate: true,
    },

    updateInfo: {
      handler(newVal) {
        this.$parent.updateInfo = newVal;
      },
      immediate: true,
    },
  },

  methods: {
    async handleUrl(value) {
      const url = value.target.value;
      await this.$validator.validate('externallink.url').then((res) => {
        if (res) {
          if (this.$helpers.isURLWhiteListed(url) || this.isVibonsAdmin) {
            if (this.$refs.urlInput) {
              this.$refs.urlInput.classList.add('loading-icon');
            }
            if (this.$helpers.isYouTubeUrl(url)) {
              this.$helpers.getYouTubeInfo(url).then((data) => {
                console.log('utube:dat', data);
                this.$emit('setProvider', 'youtube');
                if (data && data.items) {
                  this.handleMetaTags(data.items[0]);
                }
                this.urlValid = true;
                if (this.$refs.urlInput) {
                  this.$refs.urlInput.classList.remove('loading-icon');
                }
              }).catch((error) => {
                this.$helpers.displayError(error);
                this.urlValid = true;
                if (this.$refs.urlInput) {
                  this.$refs.urlInput.classList.remove('loading-icon');
                }
              });
            } else {
              try {
                urlMetadata(`${CORS_PROXY_URL}${url}`, { timeout: 5000 }).then((data) => {
                  this.handleMetaTags(data);
                  this.urlValid = true;
                  if (this.$refs.urlInput) {
                    this.$refs.urlInput.classList.remove('loading-icon');
                  }
                }).catch((error) => {
                  this.$helpers.displayError(error);
                  this.urlValid = true;
                  if (this.$refs.urlInput) {
                    this.$refs.urlInput.classList.remove('loading-icon');
                  }
                });
              } catch (error) {
                this.$helpers.displayError(error);
                this.urlValid = true;
              }
            }
            this.$emit('externalUrl', url);
          } else {
            // alert only allowed urls
            const allowedURLS = WHITELIST_URLS.join(', ');
            this.$validator.errors.add({
              field: 'url',
              // eslint-disable-next-line vue-i18n/no-missing-keys
              msg: this.$t('allowed_urls_new', { urls: allowedURLS }),
              scope: 'externallink',
            });
          }
        }
      });
    },

    async handleMetaTags(data) {
      if (data) {
        if (this.editMode) {
          this.dataHolder = data;
          this.$snotify.confirm(this.$t('external_url_changed_msg__body'), this.$t('external_url_changed_msg__title'), {
            timeout: 0,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            buttons: [
              {
                text: this.$t('yes'),
                action: (toast) => {
                  this.deleteConfirm(toast, data);
                },
                bold: false,
              },
              {
                text: this.$t('no'),
                action: (toast) => {
                  this.deleteCancel(toast);
                },
              },
            ],
          }).on('shown', (toast) => {
            this.toastHolder = toast;
            document.addEventListener('keydown', this.eventHandler, false);
          }).on('hidden', (toast) => {
            this.toastHolder = toast;
            document.removeEventListener('keydown', this.eventHandler, false);
          });
        } else {
          console.log('youtubedata:', data);
          this.title = data.snippet?.title || data['og:title'];
          const desc = data.snippet?.description || data['og:description'];
          const thumbnail = data.snippet?.thumbnails?.standard?.url || data.snippet?.thumbnails?.default.url;
          this.$emit('setThumbnail', thumbnail);
          console.log('thumbnail:', thumbnail);
          if (desc) {
            const trimmedDesc = desc.length > 900
              ? `${desc.substring(0, 900)}...`
              : desc;
            this.handleDesc(trimmedDesc);
          }
          // const duration = data.contentDetails.duration || null;
          // if (duration) {
          //   const durationFormatted = this.$helpers.youtubeDurationConvert(duration);
          //   console.log('duration:', durationFormatted);
          //   this.handleDuration(durationFormatted);
          // }

          let keywords = data.snippet?.tags || data.snippet?.keywords || data['article:tag'] || data['og:article:tag'];
          if (keywords) {
            if (typeof keywords === 'object') {
              keywords = Object.keys(keywords).map(key => ({ text: keywords[key] }));
            } else if (typeof keywords === 'string') {
              keywords = keywords.split(',').map(k => ({ text: k }));
            } else {
              keywords = keywords.map(k => ({ text: k }));
            }
            this.changeTag(keywords);
          }
          let info = null;
          if (data?.statistics?.viewCount) {
            info = this.$t('watches', { num: this.$helpers.abbreviateNumber(data.statistics.viewCount) });
          }
          this.changeInfo(info);
        }
      }
    },


    handleTitle() {
      this.$emit('setTitle', this.title);
    },

    handleDesc(newValue) {
      this.$emit('setDesc', newValue);
    },

    handleDuration(newValue) {
      this.$emit('setDuration', newValue);
    },

    changeTag(newTags) {
      this.$emit('setTags', newTags);
    },
    changeInfo(newInfo) {
      this.$emit('setInfo', newInfo);
    },
    async checkValidation() {
      let allIsValid = false;
      let externalIsValid = false;
      let quizIsValid = false;

      if (this.isAddingQuestion) {
        quizIsValid = await this.checkQuizValidation('externallink');
      } else {
        quizIsValid = true;
      }

      await this.$validator.validateAll('all').then((res) => {
        allIsValid = res;
      });
      await this.$validator.validateAll('externallink').then((res) => {
        externalIsValid = res;
      });

      if (allIsValid && externalIsValid && quizIsValid) {
        return true;
      }

      return false;
    },

    createContent() {
      this.checkValidation().then((res) => {
        if (res) {
          this.$emit('createContent');
          this.isPending = true;
        }
      });
    },

    async updateContent() {
      if (this.isAddingQuestion === 0 && this.modalData?.quiz && this.modalData?.quiz?.questions) {
        // eslint-disable-next-line array-callback-return
        await Promise.all(this.modalData?.quiz?.questions.map((question) => {
          this.$store.dispatch('modal/DELETE_QUIZ_QUESTION', question.id);
        }));
        await this.$store.dispatch('modal/DELETE_CONTENT_SUB_QUIZ', this.modalData.quiz.id);
      }
      this.checkValidation().then((res) => {
        if (res) {
          this.$emit('updateContent');
          this.isPending = true;
        }
      });
    },

    deleteConfirm(toast, data) {
      this.updateInfo = true;
      this.title = data.title || data['og:title'];
      const desc = data.description || data['og:description'];
      this.handleDesc(desc);
      let keywords = data.keywords || data['article:tag'] || data['og:article:tag'] || data.tags;
      if (keywords) {
        if (typeof keywords === 'object') {
          keywords = Object.keys(keywords).map(key => ({ text: keywords[key] }));
        } else if (typeof keywords === 'string') {
          keywords = keywords.split(',').map(k => ({ text: k }));
        }
        this.changeTag(keywords);
      }
      this.$snotify.remove(toast.id);
    },

    deleteCancel(toast) {
      this.$snotify.remove(toast.id);
    },

    eventHandler(event) {
      event.preventDefault();
      const key = event.key || event.keyCode;
      if (key === 'Enter' || key === 13) {
        this.deleteConfirm(this.toastHolder, this.dataHolder);
      } else if (key === 'Escape' || key === 'Esc' || key === 27) {
        this.deleteCancel(this.toastHolder);
      }
    },
  },
};
</script>
<style scoped>
.form-control.is-danger {
  border-color: #f27299;
}
</style>
